import React, { useEffect, useState } from 'react'
import "./Quotation.css"
import { checkAuthority } from '../../utils';
import SiteDetailCard from '../../components/sites/DetailCard';

const Quotations = () => {
    const segments = window.location?.pathname;
    console.log(segments, "----------maintainsegment");
    const [quotationList, setQuotationList] = useState([])


    useEffect(() => {
        GetQuotation()
    }, [segments])

    const GetQuotation = () => {
        var arr = []
        let url = segments?.split("/")[1] === "company" ? "/company" : ""

        if (checkAuthority('VIEW_QUOTATION')) {
            arr.push({
                id: 1,
                title: "View/Update Quotations",
                url: `${url}/maintainance-report/quotations/view-quotation`
            })
        }

        if (checkAuthority('SUBMIT_QUOTATION')) {
            arr.push({
                id: 2,
                title: "Submit Quotation",
                url: `${url}/maintainance-report/quotations/submit-quotation`
            })
        }

        setQuotationList(arr)
    }
    return (
        <div>
            <div>
                <h1>Quotation</h1>
            </div>
            <div className='datacard'>
                {
                    quotationList?.map((quotation) => {
                        return <SiteDetailCard title={quotation?.title} url={quotation?.url} />
                    })
                }
            </div>
        </div>
    )
}

export default Quotations