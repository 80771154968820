import React from 'react'
import { Box, Grid } from "@mui/material";
import PageTitle from '../../common/PageTitle';
import SiteDetailCard from '../../components/sites/DetailCard';
const TaskNav = () => {
  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="Tasks" />
      <Box display="flex">
        <Grid
          container
          rowSpacing={7}
          columnSpacing={{ xs: 1 }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          mt="1%"
        >

          <SiteDetailCard large title={'Create Task '} url={'/tasks/create'} />
          <SiteDetailCard large title={'View Task'} url={'/tasks/view'} />

        </Grid>
      </Box>
    </Box>
  )
}

export default TaskNav