import React from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./style.css";
import { Box, Input, InputBase, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "&:before": {
      border: "0 !important",
    },
    "&:after": {
      border: "0 !important",
    },
  },
}));

function LocalDateSelector(props) {
  const classes = useStyles();
  // const handlePrevDay = () => {
  //   const newDate = new Date(selectedDate);
  //   newDate.setDate(selectedDate.getDate() - 1);
  //   setSelectedDate(newDate);
  // };

  const handleNextDay = () => {
    if (props.value) {
      let date=props.value
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + 1);
      date = newDate;
    }
    return
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={props.label && props.label === "none" ? "" : "select date"}
        inputFormat="MM/dd/yyyy"
        InputAdornmentProps={{ position: "start" }}
        value={props.value}
        onChange={props.onChange || function () { }}
        style={{

        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(dateParams) => (
          <Paper
            component="form"
            sx={{
              p: "13px 4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Google Maps"
              inputProps={{ ...dateParams.inputProps }}
              inputRef={dateParams.inputRef}
              startAdornment={dateParams.InputProps.startAdornment}
              inputComponent={(inputProps) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ height: "inherit"}} >
                    {props.title}
                  </Typography>
                  {/* <ArrowBackIosNewIcon style={{ flex: 1 }} /> */}
                  <Input
                    {...inputProps}
                    style={{ flex: 2, marginLeft:"20px" }}
                    className={classes.inputRoot}
                  />
                  {/* <ArrowForwardIosIcon style={{ flex: 1 }} onClick={() => handleNextDay()} /> */}
                </Box>
              )}
              {...dateParams}
            />
          </Paper>
        )}
      />
    </LocalizationProvider>
  );
}

export default LocalDateSelector;
