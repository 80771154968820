import React, { useEffect, useState, useContext } from "react";

import { Box, Grid, TextareaAutosize, Typography, Button, InputLabel } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import TableHead from "@mui/material/TableHead";
import Loader from '../../common/Loader'
import { getAPI, postAPI, patchAPI, deleteAPI } from '../../network'
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers";
// import { duration } from "../../utils/data";
// import Header from "../../layout/Header";
// import { CompanyContext } from '../../../context';
import AccountMenu from '../../layout/AccountMenu'

import "./style.css";
import { formatDate, formatDatePost, getSetTime, timeFormat, getTimeFromTimestamp2, validation, fullName, checkAuthority, tableHeader, tableData, durationCalc } from "../../utils";
import { useNavigate } from "react-router-dom";

export default function NotificationPage() {
    const [companyF, setCompanyF] = useState("")
    const [siteF, setSiteF] = useState("")
    const [vendorF, setVendorF] = useState("")
    const [userF, setUserF] = useState("")

    // const [companyId] = useContext(CompanyContext)
    let companyId = localStorage.getItem('companyIdLS')
    if (companyId === null) {
        companyId = undefined
    }
    const [selectedStartTime, setSelectedStartTime] = useState("");
    const [id, setId] = useState('');
    const [loader, setLoader] = useState(false);
    const [notifications, setNotifications] = useState([])
    const [action, setAction] = useState('add');
    const [open, setOpen] = useState(false)
    const [endBy, setEndBy] = useState(false);
    const [endAfter, setendAfter] = useState(false);
    const [noend, setNoend] = useState(false);
    const [show, setShow] = useState(false)
    const [sites, setSites] = useState([])
    const [company, setCompany] = useState([])
    const [vendor, setVendor] = useState([])
    const [recurrences, setRecurrences] = useState([])
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [client, setClient] = useState("");
    const [cmpId, setCmpId] = useState("");
    const [selectedStatus, setSelectedStatus] = useState({
        shiftStatus: "",
    });
    const navigate=useNavigate()
    const [notification, setNotification] = useState({
        notes: '',
        startTime: null,
        endTime: null,
        startDate: null,
        endDate: null,
        shiftRecurrence: null,
        siteId: '',
        vendor: '',
        role: '',
        userId: '',
        company: '',
        duration: '',
        endType: '',
        recurrenceDay: [],
        durationError: false,
        notesError: false,
        startTimeError: false,
        endTimeError: false,
        startDateError: false,
        endDateError: false,
        shiftRecurrenceError: false,
        siteIdError: false,
        userIdError: false,
        companyIdError: false,
        vendorIdError: false,
    })
    const checkListWeek = [{
        "name": "Mon",
        val: 0
    },
    {
        "name": "Tue",
        val: 1
    },
    {
        "name": "Wed",
        val: 2
    },
    {
        "name": "Thus",
        val: 3
    },
    {
        "name": "Fri",
        val: 4
    },
    {
        "name": "Sat",
        val: 5
    },
    {
        "name": "Sun",
        value: 6
    }];
    const [checked, setChecked] = useState([]);


    useEffect(() => {
        // console.log(notification, 'notification')
        // getNotifications()
        getSites()
        getRecurrences()
        // getUsers()
        getCompany()
        // getVendor()
        getRoles()
    }, [])

    const clearAll = () => {
        setNotification({
            notes: '',
            startTime: null,
            endTime: null,
            startDate: null,
            endDate: null,
            shiftRecurrence: null,
            siteId: '',
            userId: '',
            company: '',
            duration: '',
            endType: '',
            recurrenceDay: [],
            checked: [],
            durationError: false,
            notesError: false,
            startTimeError: false,
            endTimeError: false,
            startDateError: false,
            endDateError: false,
            shiftRecurrenceError: false,
            siteIdError: false,
            userIdError: false,
            companyIdError: false,
            vendorIdError: false,

        })

    }

    const getNotifications = async () => {
        setLoader(true);
        if (companyId) {
            var url = `/notifications?company=${companyId}`
        } else {
            var url = `/notifications`
        }
        let data = await getAPI(url)
        if (data) {
            setNotifications(data)
        }
        setLoader(false);
    }
    const getNotificationsF = async (c, v, s, u) => {
        setLoader(true);
        let url

        if (companyF && !siteF && !vendorF && !userF) {
            url = `/notifications?company=${c ? c : companyF}`
        }
        if (!companyF && siteF && !vendorF && !userF) {
            url = `/notifications?siteId=${s ? s : siteF}`
        }
        if (!companyF && !siteF && vendorF && !userF) {
            url = `/notifications?vendor=${v ? v : vendorF}`
        }
        if (!companyF && !siteF && !vendorF && userF) {
            url = `/notifications?userId=${u ? u : userF}`
        }
        if (!companyF && !siteF && vendorF && userF) {
            url = `/notifications?vendor=${v ? v : vendorF}userId=${u ? u : userF}`
        }
        if (companyF && siteF && !vendorF && !userF) {
            url = `/notifications?company=${c ? c : companyF}&siteId=${s ? s : siteF}`
        }
        if (!companyF && siteF && !vendorF && userF) {
            url = `/notifications?siteId=${s ? s : siteF}&userId=${u ? u : userF}`
        }
        if (companyF && !siteF && !vendorF && userF) {
            url = `/notifications?company=${c ? c : companyF}&userId=${u ? u : userF}`
        }
        if (companyF && !siteF && vendorF && !userF) {
            url = `/notifications?vendor=${v ? v : vendorF}&company=${c ? c : companyF}`
        }
        if (companyF && siteF && vendorF) {
            url = `/notifications?vendor=${v ? v : vendorF}&company=${c ? c : companyF}&siteId=${s ? s : siteF}`
        }
        if (companyF && siteF) {
            url = `/notifications?company=${c ? c : companyF}&siteId=${s ? s : siteF}`
        }
        if (companyF && siteF && vendorF && userF) {
            url = `/notifications?vendor=${v ? v : vendorF}&company=${c ? c : companyF}&siteId=${s ? s : siteF}&userId=${u ? u : userF}`
        }
        // var url = `/notifications?vendor=${v?v:vendorF}&company=${c?c:companyF}&siteId=${s?s:siteF}&userId=${u?u:userF}`
        if (url) {
            let data = await getAPI(url)
            if (data) {
                setNotifications(data)
            }
        } else {
            if (c) {
                let data = await getAPI(`/notifications?company=${c}`)
                if (data) {
                    setNotifications(data)
                }
            }
            if (s) {
                let data = await getAPI(`/notifications?siteId=${s}`)
                if (data) {
                    setNotifications(data)
                }
            }
            if (v) {
                let data = await getAPI(`/notifications?vendor=${v}`)
                if (data) {
                    setNotifications(data)
                }
            }
            if (u) {
                let data = await getAPI(`/notifications?userId=${u}`)
                if (data) {
                    setNotifications(data)
                }
            }

        }
        setLoader(false);

    }


    const getCompany = async () => {
        setLoader(true);
        let data = await getAPI(`/companies?companyId=${companyId}`)
        if (data) {
            setCompany(data)
        }
        setLoader(false);
        getSites()
    }
    const getVendor = async (cId) => {
        setLoader(true);
        if (cId) {
            let data = await getAPI(`/vendors?companyId=${cId}`)
            if (data) {
                setVendor(data)
            }
        } else {
            let data = await getAPI(`/vendors`)
            if (data) {
                setVendor(data)
            }
        }


        setLoader(false);
    }

    const getSites = async (cIdd) => {
        if (cIdd) {
            setLoader(true)
            let data = await getAPI(`/sites?companyId=${cIdd}`);
            if (data) {
                setSites(data)
            }
            setLoader(false)
        } else {
            setLoader(true)
            let data = await getAPI(`/sites?companyId=${companyId}`);
            if (data) {
                setSites(data)
            }
            setLoader(false)
        }

    }
    const getRoles = async (cId, sId) => {
        if (cId && sId) {
            setLoader(true)
            let data = await getAPI(`/roles?siteId=${sId}&companyId=${cId}`);
            if (data) {
                setRoles(data)
            }
            setLoader(false)
        }
        if (!cId && sId) {
            setLoader(true)
            let data = await getAPI(`/roles?siteId=${sId}`);
            if (data) {
                setRoles(data)
            }
            setLoader(false)
        }
        if (cId && !sId) {
            setLoader(true)
            let data = await getAPI(`/roles?companyId=${cId}`);
            if (data) {
                setRoles(data)
            }
            setLoader(false)
        }
        if (!cId && !sId) {
            setLoader(true)
            let data = await getAPI(`/roles`);
            if (data) {
                setRoles(data)
            }
            setLoader(false)
        }

    }
    // const getUsers = async () => {
    //   setLoader(true);
    //   let data = await getAPI('/users')
    //   if (data) {
    //     setUsers(data)
    //   }
    //   setLoader(false);
    // }
    const getUsers = async (idd, cidd) => {
        if (cidd) {
            setLoader(true)
            let data = await getAPI(`/company/sites/team?companyId=${cidd}`);
            if (data) {
                let emails = data.map((item) => ({
                    id: item._id ? item._id : item.id,
                    label: fullName(item) + `(${item.email})`,
                    value: item.email
                }))
                setUsers(emails)

            }
            setLoader(false)
        }
        if (idd) {
            setLoader(true)
            let data = await getAPI(`/sites-user/${idd}`);
            if (data) {
                let emails = data.map((item) => ({
                    id: item._id ? item._id : item.id,
                    label: fullName(item) + `(${item.email})`,
                    value: item.email
                }))
                setUsers(emails)
            }
            setLoader(false)
        }

        if (!idd && !cidd) {
            setLoader(true);
            let data = await getAPI(`/users?companyId=${companyId}`);
            if (data) {
                let emails = data.map((item) => ({
                    id: item._id ? item._id : item.id,
                    label: fullName(item) + `(${item.email})`,
                    value: item.email
                }))
                setUsers(emails)
            }
            setLoader(false);
        }

    }

    // console.log(sites);

    const getRecurrences = async () => {
        setLoader(true)
        let data = await getAPI('/recurrence');
        if (data) {
            var arr = [];
            for (var i = 0; i < data.length; i++) {
                arr.push({ id: data[i].id, name: data[i].name, checked: false })
            }
            setRecurrences(arr)
        }
        setLoader(false)
    }

    const changeStartTime = (event) => {
        setSelectedStartTime(event.target.value);
    };

    const editClick = (id) => {
        setId(id);
        setAction('edit');
        let note = notifications.filter((item) => item?._id === id)[0]
        console.log("NOTE_______", note)
        setNotification(prevState => ({
            ...prevState,
            notes: note.notes,
            startTime: note?.startTime,
            endTime: note?.endTime,
            startDate: new Date(note?.startDate),
            endDate: new Date(note?.endDate),
            endType: note?.endType,
            shiftRecurrence: note?.shiftRecurrence,
            recurrenceDay: note?.recurrenceDay,
            siteId: note.siteId?._id,
            vendor: note?.vendor,
            userId: note.userId?._id,
            company: note.company._id,
        }))

        setOpen(true)
    }

    const deleteClick = (id) => {
        setId(id);
        setShow(true)
    }

    const handleShowClose = () => {
        setShow(false)
    }
    const onChangeDuration = (event) => {
        setNotification(prevState => ({
            ...prevState,
            duration: event.target.value,
        }))
    }


    const allClear = () => {
        setNotification(prevState => ({
            ...prevState,
            notes: '',
            startTime: null,
            endTime: null,
            startDate: null,
            endDate: null,
            shiftRecurrence: '',
            siteId: '',
            vendor: '',
            userId: '',
            company: '',
            endType: '',
            recurrenceDay: [],
            setChecked: [],
            duration: null,
            durationError: false,
            notesError: false,
            startTimeError: false,
            endTimeError: false,
            startDateError: false,
            endDateError: false,
            shiftRecurrenceError: false,
            siteIdError: false,
            userIdError: false,
            companyIdError: false,
            vendorIdError: false

        }))
    }

    const handleClose = () => {
        setOpen(false)
        setNotification(prevState => ({
            ...prevState,
            notes: '',
            startTime: null,
            endTime: null,
            startDate: null,
            endDate: null,
            shiftRecurrence: '',
            siteId: '',
            vendor: '',
            userId: '',
            company: '',
            endType: '',
            duration: null,
            durationError: false,
            notesError: false,
            startTimeError: false,
            endTimeError: false,
            startDateError: false,
            endDateError: false,
            shiftRecurrenceError: false,
            siteIdError: false,
            userIdError: false,
            companyIdError: false
        }))
    }

    const handleSubmit = async (action) => {
        
        setNotification(prevState => ({
            ...prevState,
            notesError: false,
            startTimeError: false,
            endTimeError: false,
            startDateError: false,
            endTimeError: false,
            shiftRecurrenceError: false,
            recurranceError: false,
            siteIdError: false,
            durationError: false,
            userIdError: false,
            endTypeError: false,
        }))



        // if (validation('empty', 'Site', notification.siteId)) {
        //   setNotification(prevState => ({
        //     ...prevState,
        //     siteIdError: true
        //   }))
        //   return;
        // }
        if (validation('empty', 'Company', notification.company)) {
            setNotification(prevState => ({
                ...prevState,
                CompanyIdError: true
            }))
            return;
        }

        // if (validation('empty', 'User', notification.userId)) {
        //   setNotification(prevState => ({
        //     ...prevState,
        //     userIdError: false
        //   }))
        //   return;
        // }
        if (validation('long', 'Notification', notification.notes)) {
            setNotification(prevState => ({
                ...prevState,
                notesError: true
            }))
            return;
        }

        else if (validation('time', 'Start Time', notification.startTime)) {
            setNotification(prevState => ({
                ...prevState,
                startTime: true
            }))
            return;
        }
        else if (validation('time', 'End Time', notification.endTime)) {
            setNotification(prevState => ({
                ...prevState,
                endTime: true
            }))
            return;
        }
        else if (validation('date', 'Start Date', notification.startDate)) {
            setNotification(prevState => ({
                ...prevState,
                startDate: true
            }))
            return;
        }
        else if (validation('date', 'End Date', notification.endDate)) {
            setNotification(prevState => ({
                ...prevState,
                endDate: true
            }))
            return;
        }
        else if (validation('empty', 'RecurranceDay', checked)) {
            setNotification(prevState => ({
                ...prevState,
                recurrenceDay: true
            }))
            return;
        }
        // else if(validation('time', 'Shift Recurrence',notification.shiftRecurrence) ){
        //   setNotification(prevState => ({
        //     ...prevState,
        //     shiftRecurrence : true
        //   }))
        //   return;
        // }

        let checkedval = []
        if (checked.length > 0) {
            for (let i = 0; i < checked.length; i++) {
                checkedval.push(parseInt(checked[i]))
            }
        } else {
            for (let i = 0; i <= 6; i++) {
                checkedval.push(parseInt(i))
            }
        }

        // console.log("checked value#####################",checkedval)


        let payload = {
            notification: notification.notes,
            notes: notification.notes,
            startTime: notification.startTime,
            endTime: notification.endTime,
            startDate: formatDatePost(notification.startDate),
            endDate: formatDatePost(notification.endDate),
            shiftRecurrence: "Weekly",
            siteId: notification.siteId ? notification.siteId : null,
            role: notification.role ? notification.role : null,
            vendor: notification.vendor ? notification.vendor : null,
            duration: notification.duration,
            userId: notification.userId ? notification.userId : null,
            company: notification.company,
            endType: notification.endType,
            recurrenceDay: checkedval
        }

        // console.log("------PAYLOAD-----", payload)
        if (action === 'add') {
            setLoader(true)
            let data = await postAPI('/notifications', payload)
            if (data) {
                setOpen(false)
                getNotifications()
                setNotification(prevState => ({
                    ...prevState,
                    notes: '',
                    startTime: null,
                    endTime: null,
                    startDate: null,
                    endDate: null,
                    shiftRecurrence: '',
                    siteId: '',
                    vendor: '',
                    userId: '',
                    company: '',
                    duration: '',
                    endType: '',
                    recurrenceDay: [],
                    checked: [],
                    durationError: false,
                    notesError: false,
                    startTimeError: false,
                    endTimeError: false,
                    startDateError: false,
                    endDateError: false,
                    shiftRecurrenceError: false,
                    siteIdError: false,
                    companyIdError: false,
                    vendorIdError: false,
                }))
            }
            setLoader(false)
            clearAll()
        }
        else {
            setLoader(true)
            let data = await patchAPI(`/notifications/${id}`, payload)
            if (data) {
                setOpen(false)
                getNotifications()
                setNotification(prevState => ({
                    ...prevState,
                    notes: '',
                    startTime: null,
                    endTime: null,
                    startDate: null,
                    endDate: null,
                    shiftRecurrence: '',
                    siteId: '',
                    vendor: '',
                    userId: '',
                    company: '',
                    duration: '',
                    endType: '',
                    recurrenceDay: [],
                    durationError: false,
                    notesError: false,
                    startTimeError: false,
                    endTimeError: false,
                    startDateError: false,
                    endDateError: false,
                    shiftRecurrenceError: false,
                    siteIdError: false,
                    companyIdError: false,
                    vendorIdError: false,
                }))
            }
            setLoader(false)
        }
        clearAll()

        navigate('/notifications/view')
    }

    const handleDelete = async () => {
        setLoader(true)

        let data = await deleteAPI(`/notifications/${id}`);
        if (data) {
            getNotifications()
            setShow(false)
            AccountMenu()
        }
        setLoader(false)

    }

    const handleRecurrenceChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            shiftRecurrence: event.target.value,
        }))
    }



    const handleChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            siteId: event.target.value,
        }))
        getUsers(event.target.value, null)
        getRoles(null,event.target.value)
    };
    const handleCompChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            company: event.target.value,
        }))
        setCmpId(event.target.value)
        // getVendor(event.target.value)
        getSites(event.target.value)
        // getUsers(null, event.target.value)
        getRoles(event.target.value,null)

    };
    const handleVendorChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            vendor: event.target.value,
        }))
    };
    const handleUserChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            userId: event.target.value,
        }))
    };
    const handleRoleChange = (event) => {
        setNotification(prevState => ({
            ...prevState,
            role: event.target.value,
        }))
    };
    const handleCompanyChangeF = (event) => {
        setCompanyF(event.target.value)
        getNotificationsF(event.target.value)
    };
    const handleVendorChangeF = (event) => {
        setVendorF(event.target.value)
        getNotificationsF(null, event.target.value, null, null)
    };
    const handleSiteChangeF = (event) => {
        setSiteF(event.target.value)
        getNotificationsF(null, null, event.target.value, null)
    };
    const handleUserChangeF = (event) => {
        setUserF(event.target.value)
        getNotificationsF(null, null, null, event.target.value)
    };

    const setChoice = (id) => {
        var data = recurrences.map((item) => {
            if (item.id === id) {
                return { id: item.id, name: item.name, checked: true }
            }
            else {
                return { id: item.id, name: item.name, checked: false }
            }
        })
        setNotification(prevState => ({
            ...prevState,
            shiftRecurrence: id,
        }))

        setRecurrences(data)
    }
    const endByset = (value) => {
        // console.log("ENDBYE selected", value)
        setNotification(prevState => ({
            ...prevState,
            endType: value,
        }))

    }
    const setEndByy = () => {
        setEndBy(current1 => !current1);
        setNoend(false)
        setendAfter(false)
    }
    const setNoendd = () => {
        setNoend(current3 => !current3);
        setEndBy(false)
        setendAfter(false)
    }
    const setendAfterr = () => {
        setendAfter(current2 => !current2);
        setEndBy(false)
        setNoend(false)

    }
    const findDuration = (fromDate = null, toDate = null) => {
        let newFromDate = fromDate !== null ? fromDate : notification.startTime;
        let newToDate = toDate !== null ? toDate : notification.endTime;

        if (newFromDate !== null && newToDate !== null) {
            return durationCalc(newFromDate, newToDate);
            // let duration = durationCalc(newFromDate, newToDate)
            // setNotification({
            //   ...notification,
            //   duration : duration
            // })
        }
        else {
            return null;
        }

    }


    const onStartDateChange = (data) => {

        setNotification({
            ...notification,
            startTime: data,
            duration: findDuration(data, null)
        })

        // findDuration(data, null)
    }



    const onEndDateChange = (data) => {

        setNotification({
            ...notification,
            endTime: data,
            duration: findDuration(data, null)
        })

        // findDuration(null,data)
    }

    // Return classes based on whether item is checked
    var isChecked = (item) =>
        checked.includes(item) ? "checked-item" : "not-checked-item";

    const onChange = (event) => {
        if (event.target.name === "client") {
            setClient(event.target.value);
        } else
            setSelectedStatus({
                [event.target.name]: event.target.value,
                ...selectedStatus,
            });
    };

    const handleCheck = (event) => {
        var updatedList = [...checked];
        // console.log(event.target.checked, "event.target.checked")
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };


    return (
        <Box sx={{ height: "inherit" }}>
            <Loader loader={loader} />
            <PageTitle title="Create Notification" />
            {
                checkAuthority('ADD_NOTIFICATION') &&
                <Box
                    display="flex"
                    className="box-container"
                    sx={{ mx: "0.5rem", p: "2rem" }}
                >

                    <Grid container className="">
                        <Grid item xs={2} sx={{ mb: 2 }}>
                            <Typography variant="h6" component="h6" className="site">
                                Company* :
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ mb: 2 }}>
                            <FormControl fullWidth >
                                <InputLabel id="company-label">Company</InputLabel>
                                <Select
                                    labelId="company-label"
                                    id="company-select"
                                    value={notification.company}
                                    label="Company"
                                    error={notification.companyIdError ? true : false}
                                    onChange={handleCompChange}
                                >
                                    {
                                        company.map((item, index) => (
                                            <MenuItem value={item.id} key={index}>{item?.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={7}>

            </Grid> */}

                        {/* <Grid item xs={2} sx={{ mb: 2 }}>
              <Typography variant="h6" component="h6" className="site">
                Vendors : 
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mb: 2 }}>
              <FormControl fullWidth >
                <InputLabel id="company-label">Vendors</InputLabel>
                <Select
                  labelId="company-label"
                  id="company-select"
                  value={notification.vendor}
                  label="Vendors"
                  error={notification.vendorIdError ? true : false}
                  onChange={handleVendorChange}
                >
                  {
                    vendor?.map((item, index) => (
                      <MenuItem value={item.id} key={index}>{item?.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid> */}
                        <Grid item xs={7}>

                        </Grid>
                        <Grid item xs={2} sx={{ mb: 2 }}>
                            <Typography variant="h6" component="h6" className="site">
                                Site :
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ mb: 2 }}>
                            <FormControl fullWidth >
                                <InputLabel id="site-label">Site</InputLabel>
                                <Select
                                    labelId="site-label"
                                    id="site-select"
                                    value={notification.siteId}
                                    label="Site"
                                    error={notification.siteIdError ? true : false}
                                    onChange={handleChange}
                                >
                                    {
                                        sites.map((item, index) => (
                                            <MenuItem value={item?._id} key={index}>{item?.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={7}>

                        </Grid>
                        <Grid item xs={2} sx={{ mb: 2 }}>
                            <Typography variant="h6" component="h6" className="site">
                                Role :
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ mb: 2 }}>
                            <FormControl fullWidth >
                                <InputLabel id="site-label">Role</InputLabel>
                                <Select
                                    labelId="site-label"
                                    id="site-select"
                                    value={notification.role}
                                    label="Role"
                                    error={notification.siteIdError ? true : false}
                                    onChange={handleRoleChange}
                                >
                                    {
                                        roles.map((item, index) => (
                                            <MenuItem value={item?.id ? item.id : item._id} key={index}>{item?.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={7}>

                        </Grid>

                        {/* <Grid item xs={2} sx={{ mb: 2 }}>
              <Typography variant="h6" component="h6" className="site">
                User :
              </Typography>
            </Grid> */}
                        {/* <Grid item xs={3} sx={{ mb: 2 }}>
              <FormControl fullWidth >
                <InputLabel id="user-label">User</InputLabel>
                <Select
                  labelId="user-label"
                  id="user-select"
                  value={notification.userId}
                  label="User"
                  error={notification.userIdError ? true : false}
                  onChange={handleUserChange}
                >
                  {
                    users.map((item, index) => (
                      <MenuItem value={item?.id ? item.id : item._id} key={index}>{item?.label}</MenuItem>

                    )) 
                  }
                </Select>
              </FormControl>
            </Grid> */}
                        {/* <Grid item xs={7}>

            </Grid> */}

                        <Grid item xs={2}>
                            <Typography variant="h6" component="h6" className="site">
                                Notification* :
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={5}
                                placeholder="Type notification here"
                                className={notification.notesError ? "txt-input-error" : "txt-input"}
                                onChange={(event) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        notes: event.target.value,
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid item xs={7}>

                        </Grid>

                        <Grid item xs={12} sx={{ mt: "2rem" }}>
                            <Typography variant="h6" component="h6" className="site">
                                Appointment Recurrence* :
                            </Typography>
                        </Grid>

                        <Grid item xs={3} sx={{mt: "16px"}}>
                            {/* <FormControl sx={{ m: 1, width: "90%", backgroundColor: "white" }}>
              <Select
                value={selectedStartTime}
                onChange={changeStartTime}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{
                  borderRadius: 10,
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderColor: "#707070",
                }}
              >
                <MenuItem value="">
                  <div className="select-item">Start Time</div>
                </MenuItem>
                <MenuItem value={1} key={1}>
                  Test
                </MenuItem>
              </Select>
            </FormControl> */}

                            <FormControl fullWidth >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        className={notification.startTimeError ? "error" : null}
                                        label="Start Time"
                                        value={notification.startTime}
                                        onChange={(data) => {
                                            onStartDateChange(data)
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3} sx={{ my: "1rem", mx: "1rem" }}>
                            {/* <FormControl sx={{ m: 1, width: "90%", backgroundColor: "white" }}>
              <Select
                value={selectedStartTime}
                onChange={changeStartTime}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{
                  borderRadius: 10,
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderColor: "#707070",
                }}
              >
                <MenuItem value="">
                  <div className="select-item">Finish Time</div>
                </MenuItem>
                <MenuItem value={1} key={1}>
                  Test
                </MenuItem>
              </Select>
            </FormControl> */}
                            <FormControl fullWidth >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        className={notification.endTimeError ? "error" : null}
                                        label="End Time"
                                        value={notification.endTime}
                                        onChange={(data) => {
                                            onEndDateChange(data)
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>


                        <Grid item xs={3} sx={{ my: "8px", mb: "8px" }}>
                            {/* <FormControl sx={{ my: 1, minWidth: "100%" }}>
                <InputLabel id="duration-label">Duration</InputLabel>
                <Select
                  value={notification.duration}
                  onChange={onChangeDuration}
                  displayEmpty
                  labelId="duration-label"
                  id="duration"
                  label="Duration"
                  error={notification.durationError}
                  sx={{
                    borderRadius: "10px",
                    borderColor: "#707070",
                    pl: 2,
                  }}
                >
                  {
                    duration.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        <div className="select_item">{item.name}</div>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl> */}

                            {/* <FormControl fullWidth >
                <LocalizationProvider dateAdapter={AdapterMoment}>

                    <DatePicker
                      // className={notification.durationError ? "error" : null}
                      label="Duration"
                      value={notification.duration}
                      error={false}
                      onChange={(data) => { 
                        setNotification(prevState => ({
                        ...prevState,
                        duration: data,
                      }))}}
                      renderInput={(params) => <TextField {...params} />}
                    />

                </LocalizationProvider>
            </FormControl>  */}

                        </Grid>

                        <Grid item xs={3} sx={{ my: "1rem" }}></Grid>

                        <Grid item xs={12} sx={{ my: "1rem" }}>
                            <Typography variant="h6" component="h6" className="site">
                                Recurrence Pattern* :
                            </Typography>
                        </Grid>

                        <Grid item xs={12} >

                            <Button variant="outlined" className={'row-btn-alt'}
                            >
                                Weekly
                            </Button>
                            {/* {isShow && */}
                            <Typography>
                                <h4>Please Select Recurrence Day</h4>
                                <Grid className="mt-2 mb-2">
                                    <div className="h-10" >
                                        {/* {console.log("checkList==========", checkListWeek)} */}
                                        {checkListWeek.map((item, index) => (
                                            <div key={index} style={{ display: "inline" }}>
                                                <input sx={{ fontSize: "12px" }} value={item.val} type="checkbox" onChange={handleCheck}
                                                />
                                                <span className={isChecked(item)} key={item} value={item.val}>{item.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </Grid>
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sx={{ my: "1rem", mt: "5rem" }}>
                            <Typography variant="h6" component="h6" className="site">
                                Range of Recurrence* :
                            </Typography>
                        </Grid>

                        <Grid item xs={3} sx={{ mb: "1rem" }}>
                            <FormControl fullWidth >
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        className={notification.startDateError ? "error" : null}
                                        label="Start date"
                                        value={notification.startDate}
                                        onChange={(data) => {
                                            setNotification(prevState => ({
                                                ...prevState,
                                                startDate: data,
                                            }))
                                        }}

                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                {/* <LocalDateSelector 
                  title="Start Date" 
                /> */}
                            </FormControl>

                        </Grid>

                        <Grid item xs={3} sx={{ mb: "1rem", px: 2 }}>
                            <FormControl fullWidth >
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        className={notification.endDateError ? "error" : null}
                                        label="End Date"
                                        value={notification.endDate}
                                        onChange={(data) => {
                                            setNotification(prevState => ({
                                                ...prevState,
                                                endDate: data,
                                            }))
                                        }}

                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>

                        </Grid>
                        {/* 
            <Grid item xs={5} className="btn-div-grid">
              <div className="btn-row">
                <Button variant="outlined" style={{
                  backgroundColor: endBy ? '#42505C' : '',
                  color: endBy ? 'white' : '',
                }} className="row-btn" value="endBy"
                  onClick={(e) => {
                    endByset(e.target.value)
                    setEndByy()
                  }}
                >
                  End By

                </Button>
                <Button variant="outlined" style={{
                  backgroundColor: endAfter ? '#42505C' : '',
                  color: endAfter ? 'white' : '',
                }} className="row-btn" value="endAfter"
                  onClick={(e) => {
                    endByset(e.target.value)
                    setendAfterr()

                  }}
                >
                  End After
                </Button>
                <Button variant="outlined" style={{
                  backgroundColor: noend ? '#42505C' : '',
                  color: noend ? 'white' : '',
                }} className="row-btn" value="endDate"
                  onClick={(e) => {
                    endByset(e.target.value)
                    setNoendd()
                  }} >
                  No End Date
                </Button>
              </div>f
            </Grid> */}
                        <Grid item xs={4} sx={{ my: "1rem", mt: "2rem" }}></Grid>

                        <Grid item xs={12} sx={{ my: "1rem", mt: "3rem" }}>
                            <div className="btn-row-center">
                                <Button
                                    variant="outlined"
                                    className="row-btn"
                                    sx={{ mx: "3rem" }}
                                    onClick={() => { handleSubmit('add') }}
                                >
                                    Ok
                                </Button>
                                <Button variant="outlined" className="row-btn" onClick={allClear}>
                                    Cancel
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            }






            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle sx={{ textAlign: 'center', mb: 8 }}>Edit Notification</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="site-label">Site</InputLabel>
                        <Select
                            labelId="site-label"
                            id="site-select"
                            value={notification.siteId}
                            label="Site"
                            onChange={handleChange}

                        >
                            {
                                sites.map((item, index) => (
                                    <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ my: 2 }}>
                        <InputLabel id="site-label" >Vendor</InputLabel>
                        <Select
                            labelId="site-label"
                            id="site-select"
                            value={notification.vendor}
                            label="Company"
                            onChange={handleVendorChange}
                        >
                            {
                                vendor.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ my: 2 }}>
                        <InputLabel id="user-label">User</InputLabel>
                        <Select
                            labelId="user-label"
                            id="user-select"
                            value={notification.userId}
                            label="User"
                            onChange={handleUserChange}
                        >
                            {
                                users.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>{item?.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="notification"
                            label="Notification"
                            type="text"
                            rows={2}
                            multiline
                            defaultValue={notification.notes}
                            onChange={(event) => {
                                setNotification(prevState => ({
                                    ...prevState,
                                    notes: event.target.value,
                                }))
                            }}
                            fullWidth
                            error={notification.notesError ? true : false}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                className={notification.startTimeError ? "error" : null}
                                label="Start Time"
                                value={notification.startTime}
                                onChange={(data) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        startTime: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>


                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                className={notification.endTimeError ? "error" : null}
                                label="End Time"
                                value={notification.endTime}
                                onChange={(data) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        endTime: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                className={notification.startDateError ? "error" : null}
                                label="Start Date"
                                value={notification.startDate}
                                onChange={(data) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        startDate: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                className={notification.endDateError ? "error" : null}
                                label="End Date"
                                value={notification.endDate}
                                onChange={(data) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        endDate: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <Grid item xs={12} >

                        <Button variant="outlined" className={'row-btn-alt'}
                        >
                            Weekly
                        </Button>
                        {/* {isShow && */}
                        <Typography>
                            <h4>Please Select Recurrence Day</h4>
                            <Grid className="mt-2 mb-2">
                                <div className="h-10" >
                                    {/* {console.log("checkList==========", checkListWeek)} */}
                                    {checkListWeek.map((item, index) => (
                                        <div key={index} style={{ display: "inline" }}>
                                            <input sx={{ fontSize: "12px" }} value={item.val} type="checkbox" onChange={handleCheck}
                                            />
                                            <span className={isChecked(item)} key={item} value={item.val}>{item.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        </Typography>

                    </Grid>


                    {/* <FormControl fullWidth>
                         <InputLabel id="shift-label">Shift Recurrence</InputLabel>
                          <Select
                            labelId="shift-label"
                            id="shift-select"
                            value={notification.shiftRecurrence}
                            label="Shift Recurrence"
                            onChange={(event) => {handleRecurrenceChange(event)}}
                          >
                            {
                              recurrences.map((item, index) => (
                                <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                              ))
                            }
                          </Select>
                    </FormControl>   */}
                </DialogContent>
                <DialogActions sx={{ mx: 2, mb: 4 }}>
                    <Button onClick={() => handleSubmit('edit')} variant="contained" color="primary">Update</Button>
                    <Button onClick={handleClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>


            <Dialog open={show} onClose={handleShowClose} fullWidth={true}>
                <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Notification</DialogTitle>

                <DialogContent>
                    <Box
                        component="form"
                        sx={{ '& .MuiTextField-root': { my: 2, width: '100%' }, }}
                        noValidate
                        autoComplete="off"
                    >
                        <h4 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this notification</h4>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ mb: 2, mx: 4 }}>
                    <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
                    <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
