import { useState, useEffect } from 'react';
import * as React from 'react';
// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import { postAPI, getAPI, deleteAPI, putAPI } from '../../network';
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Paper,
    Grid,
    FormControl,
    Skeleton,
    Button,
    Alert,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormLabel
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { tableData, tableHeader, warningMsg } from '../../utils';
const Distribution = () => {
    const [editId, setEditId] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [submittedData, setSubmittedData] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [distributionList, setDistributionList] = useState([]);
    const [report, setReport] = React.useState('');

    const handleChange = (event) => {
        setReport(event.target.value);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputSubmit = (e) => {
        e.preventDefault();

        if (inputValue.trim() !== '') {
            // Add the new data to the top of the list
            setSubmittedData([inputValue, ...submittedData]);
            setInputValue('');
        }
    };
    let siteId = window.location.pathname.split('/')[2];
    if (siteId === 'sites') {
        siteId = window.location.pathname.split('/')[3]
    }
    const payload = {
        siteId,
        reportType: report,
        email: submittedData
    }
    console.log(payload);
    const handleSubmit = async () => {
        const duplicate = distributionList.filter(item => item.reportType === report)
        if (!editId) {
            if (duplicate.length) {
                warningMsg(`${payload.reportType} already exists, Please Edit`)
                return null
            }
        }

        if (!payload.email.length) {
            warningMsg(`Please Enter Email`)
            return null
        }
        if (!payload.reportType) {
            warningMsg(`Please Select Report Type`)
            return null
        }

        if (editId) {
            let saved = await putAPI(`/distribution/${editId}`, payload);
            // setLoader(false)
            if (saved) {
                // console.log(saved);
                //   navigateTo("/tasks/list")
                getdistributionList()
            }
            setEditId('')

        } else {
            let saved = await postAPI('/distribution', payload);
            // setLoader(false)
            if (saved) {
                // console.log(saved);
                //   navigateTo("/tasks/list")
                getdistributionList()
            }
        }

        setReport([])
        setSubmittedData([])

    }
    const deleteDistribution = async (id) => {
        var saved = await deleteAPI(`/distribution/${id}`);
        // setLoader(false)
        if (saved) {
            getdistributionList()
        }
        setReport([])
        setSubmittedData([])
    }
    const editDistribution = async (id) => {
        let data = distributionList.filter((item) => item?.id === id)[0];
        setEditId(id)
        setReport(data?.reportType)
        setSubmittedData(data?.emails)

    }
    const editEmail = (index) => {
        let arr = submittedData
        arr.splice(index, 1)
        console.log(arr)
        setSubmittedData([...arr])

        console.log(submittedData)
    }
    const getReports = async () => {
        var data = await getAPI(`/report-types?siteId=${siteId}`, payload);
        // setLoader(false)  
        if (data) {
            setReportData(data)
            console.log(data)
            //   navigateTo("/tasks/list")
        }
    }
    const getdistributionList = async () => {
        var data = await getAPI(`/distribution?siteId=${siteId}`);
        // setLoader(false)
        if (data) {
            setDistributionList(data)
            console.log(data, 123)
            //   navigateTo("/tasks/list")
        }
    }
    const reset = () => {
        setEditId('')
        setReport('')
        setSubmittedData([])
    }
    useEffect(() => {
        getReports()
        getdistributionList()
    }, [])


    return (

        <>
            <div className="" style={{ display: "flex" }}>
                <div className="" style={{ width: "40%" }}>
                    <div style={{ fontSize: "50px", font: "bold", marginBottom: "20px" }}>Distribution</div>

                    <Box sx={{ minWidth: 120, marginBottom: "20px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Reports</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={report}
                                label="Reports"
                                onChange={handleChange}
                            >
                                {/* , tenancy check, shift log, inspection &report   */}
                                {/* <MenuItem value="Risk assessment">Risk assessment</MenuItem> */}
                                <MenuItem value="Tenancy check">Tenancy check</MenuItem>
                                <MenuItem value="Shift log">Shift log</MenuItem>
                                {/* <MenuItem value="Inspection">Inspection</MenuItem> */}
                                <MenuItem value="Panic Alarm">Panic Alarm</MenuItem>
                                {
                                    reportData.map((i, index) => (
                                        <MenuItem value={i?.name} key={index}>{i?.name}</MenuItem>

                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <div style={{ width: "100%", height: "100px" }} >
                        <form onSubmit={handleInputSubmit}>
                            <input
                                type="email"
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder="Enter Email and press Enter"
                                style={{ width: "100%", height: "50px" }}
                            />
                        </form>
                        <ul>
                            {submittedData?.map((data, index) => (
                                <li key={index}>{data}  <CloseIcon onClick={() => editEmail(index)} style={{ cursor: "pointer" }} /></li>
                            ))}
                        </ul>
                    </div>
                    <Stack spacing={2} direction="row" style={{ float: "right" }}>

                        <Button variant="contained" onClick={handleSubmit}>{editId ? "Edit" : "Submit"}</Button>
                        <RestartAltIcon onClick={reset} style={{ cursor: "pointer" }} />

                    </Stack>
                </div>
                <div className="" style={{ fontSize: "50px", font: "bold", margin: "5rem" }}>
                    <TableContainer component={Paper}>

                        <Table
                            sx={{ minWidth: "auto" }}
                            aria-label="custom pagination table"
                            className="responsive-table"
                        >
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Report Type
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Email
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>


                                {distributionList.map((i, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {i?.reportType}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {i?.emails.map((i, index) => (
                                                <div className="" key={index}>{i}</div>
                                            ))}
                                        </TableCell>
                                        <TableCell align="center" sx={tableData}>

                                            <Button className="btn-div" color="primary" variant="outlined"
                                                onClick={() => editDistribution(i?.id)}
                                            >
                                                <EditIcon className="" />
                                            </Button>

                                            <Button className="btn-div" color="error" variant="outlined"
                                                onClick={() => deleteDistribution(i?.id)}
                                            >
                                                <DeleteIcon className="" />
                                            </Button>

                                        </TableCell>
                                    </TableRow>

                                ))}


                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>
            </div>
        </>
    )
}

export default Distribution 