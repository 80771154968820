// import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import MaintainaceData from "./Maintain.json"
import SiteDetailCard from '../../components/sites/DetailCard';
import "./maintainance.css"
import { checkAuthority } from '../../utils';

const MaintainanceReport = () => {
    const segments = window.location?.pathname;
    // console.log(segments, "----------maintainsegment");

    const [maintainanceDetail, setMaintainanceDetail] = useState([])

    useEffect(() => {
        getManagementDetails()
    }, [segments])

    const getManagementDetails = () => {
        var arr = []
        let url = segments?.split("/")[1] === 'company' ? '/company' : ''

        if (checkAuthority('VIEW_PROPERTY_PORTFOLIO')) {
            arr.push({
                id: 1,
                title: "Property Portfolio",
                url: `${url}/maintainance-report/property`
            })
        }

        if (checkAuthority('VIEW_SITE_DOCUMENTATION')) {
            arr.push({
                id: 2,
                title: "Site Documentation",
                url: `${url}/sites/`
            })
        }
        if (checkAuthority('VIEW_CONTRACTORS')) {
            arr.push({
                id: 3,
                title: "Contractors",
                url: `${url}/maintainance-report/contractors/`
            })
        }
        if (checkAuthority('VIEW_FIELD_STAFF')) {
            arr.push({
                id: 4,
                title: "Fieldstaff",
                url: `${url}/maintainance-report/fieldstaff/`
            })
        }
        if (checkAuthority('VIEW_PROPERTY_PORTFOLIO')) {
            arr.push({
                id: 5,
                title: "Supplier Time & Attendance ",
                url: `${url}/maintainance-report/supplier&time-attendance/`
            })
        }

        if (checkAuthority('VIEW_MAINTAINANCE_MANAGEMENT')) {
            arr?.push({
                id: 7,
                title: "Maintainance Management",
                url: `${url}/maintainance-report/management/`
            })
        }
        if (checkAuthority('VIEW_QUOTATIONS')) {
            arr.push({
                id: 8,
                title: "Quotations",
                url: `${url}/maintainance-report/quotations/`
            })
        }
        if (checkAuthority('VIEW_WORK_PERMITS')) {
            arr.push({
                id: 9,
                title: "Work Permits",
                url: `${url}/sites/`
            })
        }
        setMaintainanceDetail(arr)
    }


    return (
        <div>
            <div>
                <h1>Facilities Settings</h1>
            </div>
            {/* <div>
                <Grid item xs={12} sm={6} md={4} px={1} py={1}>
                    <Grid container>
                        <Grid item xs={11} >
                            <Box
                                // py={large ? 8 : 5}
                                display="flex"
                                justifyContent="center"
                                sx={{
                                    paddingTop: "3rem",
                                    paddingBottom: " 3rem",
                                    backgroundColor: "white",
                                    // boxShadow: "0px 2px 4px 3px rgba(0, 0, 0, 0.08)",
                                    boxShadow: "0 3px 6px rgb(0 0 0 / 0%), 0 3px 6px rgb(0 0 0 / 16%) !important",
                                    borderRadius: "5px 0 0 5px",
                                    px: {
                                        xs: 4,
                                        md: 1,
                                    },
                                }}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontSize: "20px",
                                        m: "auto 0",
                                        textTransform: "capitalize",
                                        fontWeight: 400,
                                        // lineHeight: height,
                                    }}
                                >
                                    <Link
                                        style={{ textDecoration: "none", color: "inherit" }}
                                    // to={url !== null ? url : "#"}
                                    >
                                        HI THIS SITE
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={1}>
                            <Box
                                py={3}
                                // mr={isTablet ? 0 : 2}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                component={Link}
                                // to={url !== null ? url : "#"}
                                sx={{
                                    backgroundColor: "#42505C",
                                    height: "100%",
                                    boxShadow: "0px 2px 4px 3px rgba(0, 0, 0, 0.08)",
                                    borderRadius: "0 5px  5px 0",
                                    width: {
                                        xs: "50%",
                                        sm: "70%",
                                        md: "60%"
                                    },
                                }}
                            >
                                <ArrowForwardIcon
                                    fontSize="small"
                                    style={{
                                        stroke: "#ffffff",
                                        strokeWidth: "2px",
                                        fill: "#fff",
                                        fontSize: "0.8rem",
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div> */}
            <div className='datacard'>
                {
                    maintainanceDetail?.map((it, i) => {
                        return (
                            <SiteDetailCard title={it?.title} url={it?.url} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MaintainanceReport