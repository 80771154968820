import React, { useEffect, useState } from 'react'
import { checkAuthority } from '../../../utils';
import SiteDetailCard from '../../../components/sites/DetailCard';
import "../ContractProfile.css"

const Contract = () => {
    const segments = window.location?.pathname;
    console.log(segments, "----------maintainsegment");

    const [contract, setContract] = useState([])
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        getContractDetails()
    }, [segments])

    const getContractDetails = () => {
        var arr = []
        let url = segments?.split("/")[1] === 'company' ? '/company' : ''

        if (checkAuthority('ADD_CONTRACT')) {
            arr.push({
                id: 1,
                title: "Add Contract",
                open: () => setModalOpen(true)
                // url: `${url}/maintainance-report/contractors/contract`
            })
        }

        if (checkAuthority('VIEW_CONTRACT')) {
            arr.push({
                id: 2,
                title: "View Contract",
                url: `${url}/maintainance-report/contractors/view-contract`
            })
        }

        setContract(arr)
    }

    console.log(modalOpen);
    return (
        <div className='datacard'>
            {
                contract?.map((contract) => {
                    return <SiteDetailCard title={contract?.title} url={contract?.url} />
                })
            }
        </div>
    )
}

export default Contract