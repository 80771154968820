import React from 'react'
import { Box, Grid } from "@mui/material";
import PageTitle from '../../common/PageTitle';
import SiteDetailCard from '../../components/sites/DetailCard';
const NotificationPage = () => {
  return (
    <Box sx={{ height: "inherit" }}>
      <PageTitle title="Notification" />
      <Box display="flex">
        <Grid
          container
          rowSpacing={7}
          columnSpacing={{ xs: 1 }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          mt="1%"
        >

          <SiteDetailCard large title={'Create Notification '} url={'/notifications/create'} />
          <SiteDetailCard large title={'View Notification'} url={'/notifications/view'} />

        </Grid>
      </Box>
    </Box>
  )
}

export default NotificationPage