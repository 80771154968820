import React, { useEffect, useState } from 'react'
import PageTitle from '../../../common/PageTitle'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import { tableData, tableHeader } from '../../../utils';
import { deleteAPI, getAPI, getExportAPI, postAPI, putAPI } from '../../../network';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmptyTable from '../../../common/EmptyTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BASE_URL } from '../../../../utils/axiosSetup';
import DropdownJson from "./Fsrs.json"



const FSR = () => {

    const [loader, setLoader] = useState(false)
    const [fsrList, setFsrList] = useState([])
    const [buildingTypeList, setBuildingTypeList] = useState([])
    const [buildingNameList, setBuildingNameList] = useState([])
    const [buildingFloorList, setBuildingFloorList] = useState([])
    const [buildingSpaceList, setBuildingSpaceList] = useState([])
    const [buildingSpaceIdList, setBuildingSpaceIdList] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [urlF, setUrlF] = useState('')
    const [selectedIssueType, setSelectedIssueType] = useState("")

    const [inputs, setInputs] = useState({
        areaId: "",
        buildingTypes: "",
        buildingName: "",
        buildingFloor: "",
        buildingSpaceType: "",
        issueType: "",
        faultType: "",
        priorityLevel: "",
        issueDescription: ""
    })
    const [buildingInputs, setBuildingInputs] = useState({
        buildingSpaceId: "",
        buildingTypes: "",
        buildingName: "",
        buildingFloor: "",
        buildingSpaceType: "",
        issueType: "",
        faultType: "",
        priorityLevel: "",
        issueDescription: ""
    })
    const [editModal, setEditModal] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [filter, setFilter] = useState({
        bType: "",
        bName: "",
        bFloor: "",
        bSpace: "",
        bSpaceId: "",
        bIssueType: "",
        bStatus: ""

    })





    // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


    const [page, setPage] = useState(0)
    const [perPages, setPerPages] = useState([10, 25, 50]);
    const [perPage, setPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };

    const onChange = async (e) => {
        e.preventDefault()

        setInputs({ ...inputs, [e.target.name]: e.target.value })

        if (e.target.name == "issueType") {
            setSelectedIssueType(e.target.value)
        }

    }

    const editChange = async (e) => {
        e.preventDefault()

        setBuildingInputs({ ...buildingInputs, [e.target.name]: e.target.value })
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        console.log(editMode);
        if (editMode) {
            editBuildingTypee()
        } else {
            postBuildingType()
            setModalOpen(false)
        }

    }

    const editFSrType = async (e, _id) => {
        e.preventDefault()

        setEditModal(true)
        setEditMode(true)

        let data = fsrList?.filter(item => item?._id === _id)[0]

        setBuildingInputs(data)
        setBuildingInputs({ ...data, buildingTypes: data.buildingTypes._id, buildingName: data.buildingName._id, buildingFloor: data.buildingFloor._id, buildingSpaceType: data?.buildingSpaceType?._id, buildingSpaceId: data.buildingSpaceId?.areaId })

        console.log(data.buildingSpaceId?.areaId);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        borderRadius: "5px",
        boxShadow: 24,
        p: 4,
    };


    // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------




    const getBuildingType = async () => {
        setLoader(true)
        const response = await getAPI("/building/type")
        if (response) {
            setBuildingTypeList(response)
        }
        setLoader(false)
    }

    const getBuildingName = async () => {
        setLoader(true)
        const response = await getAPI("/building/name")
        if (response) {
            setBuildingNameList(response)
        }
        setLoader(false)
    }

    const getBuildingFloor = async () => {
        setLoader(true)
        const response = await getAPI("/building/floor")
        if (response) {
            setBuildingFloorList(response)
        }
        setLoader(false)
    }

    const getBuildingSpace = async () => {
        setLoader(true)
        const response = await getAPI("/building/SpaceType")
        if (response) {
            setBuildingSpaceList(response)
        }
        setLoader(false)
    }

    const getBuildingSpaceId = async () => {
        setLoader(true)
        const response = await getAPI("/building/SpaceId")
        if (response) {
            setBuildingSpaceIdList(response)
        }
        setLoader(false)
    }



    // ------------------------------------------------------ FSR CRUD Start ----------------------------------------------------------

    const postBuildingType = async () => {
        setLoader(true)
        const response = await postAPI("/building/facilitiesRequest", inputs)
        if (response) {
            getFacilityServices()
            setModalOpen(false)
            setEditMode(false)
        }
        setLoader(false)
    }

    const getFacilityServices = async (bType = null, bName = null, bFloor = null, bSpace = null, bSpaceId = null, bIssueType = null, bStatus = null) => {

        const queryParams = new URLSearchParams({
            buildingTypes: bType ? bType : filter.bType,
            buildingName: bName ? bName : filter.bName,
            buildingFloor: bFloor ? bFloor : filter.bFloor,
            buildingSpaceType: bSpace ? bSpace : filter.bSpace,
            buildingSpaceId: bSpaceId ? bSpaceId : filter.bSpaceId,
            buildingIssue: bIssueType ? bIssueType : filter.bIssueType,
            buildingStatus: bStatus ? bStatus : filter.bStatus
        });

        setLoader(true)
        const response = await getAPI(`/building/facilitiesRequest?${queryParams.toString()}`)
        if (response) {
            setFsrList(response)
        }
        setLoader(false)
    }

    // console.log(urlF);


    const editBuildingTypee = async () => {
        setLoader(true)
        const response = await putAPI(`/building/facilitiesRequest/${buildingInputs?._id}`, buildingInputs)
        if (response) {
            getFacilityServices()
            setEditModal(false)
        }
        setLoader(false)

    }

    const deleteBuildingType = async (_id) => {
        console.log(_id);
        setLoader(true)
        const response = await deleteAPI(`/building/facilitiesRequest/${_id}`)
        if (response) {
            getFacilityServices()
        }
        setLoader(false)
    }
    // console.log(fsrList);

    // ------------------------------------------------------ FSR CRUD End --------------------------------------------------------------

    const getExportToPdf = async () => {
        setLoader(true)
        let data = await getExportAPI(`${BASE_URL}/building/facilitiesRequest/export${urlF}`, `Facility services and requests`)
        setLoader(false)
    }


    // useEffect(() => {
    //     const findFaultType = DropdownJson?.data?.filter((item) => item?.issueType === selectedIssueType) || []
    //     console.log(findFaultType, selectedIssueType);
    // }, [selectedIssueType])

    console.log(buildingSpaceList);
    useEffect(() => {
        getFacilityServices()
        getBuildingSpaceId()
        getBuildingSpace()
        getBuildingFloor()
        getBuildingName()
        getBuildingType()
    }, [])


    return (
        <div>
            <PageTitle title={"Facilities Services and Requests"} />

            <p style={{ marginLeft: "12px", width: "70%", paddingBottom: "20px" }}><b style={{ fontWeight: 600 }}>Note: </b>{"Please use below filters to shortlist the existing issues before logging in new request. If a request already exist and is 'open', you need to log another request. Alternatively, please find proceed to log a new tenancy service request."}</p>

            <div style={{ width: "78%", paddingBottom: "20px", marginTop: "60px" }}>
                <Button variant="contained" style={{ backgroundColor: "#75859D", float: "right", width: "170px" }} sx={{ height: 52, mx: 2 }}
                    onClick={() => getExportToPdf()}
                >
                    {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                    Export
                </Button>
                <Button onClick={() => setModalOpen(true)} variant="contained" style={{ backgroundColor: "#75859D", float: "right", width: "170px" }} sx={{ height: 52, mx: 2 }}>
                    <AddCircleIcon /> &nbsp; &nbsp;
                    Add FSR
                </Button>
            </div>

            <Grid sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "10px", width: "70%", marginTop: "60px" }}>

                <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Select Building Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="bType"
                        name='bType'
                        value={filter?.bType}
                        label="Select Building Type"

                        onChange={(e) => {

                            setFilter({
                                ...filter,
                                bType: e.target.value
                            })
                            getFacilityServices(e.target.value)
                        }}

                    >
                        {
                            buildingTypeList?.map((item, index) => {
                                return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                            })
                        }

                    </Select>
                </FormControl>


                <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Select Building Name</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="bName"
                        name='bName'
                        value={filter?.bName}
                        label="Select Building Name"
                        onChange={(e) => {

                            setFilter({
                                ...filter,
                                bName: e.target.value
                            })
                            getFacilityServices(null, e.target.value)
                        }}

                    >
                        {
                            buildingNameList?.filter(name => name?.buildingTypes?._id === filter?.bType)?.map((item, index) => {
                                return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                            })
                        }
                        {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                </FormControl>


                <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Select Building Floor</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="bFloor"
                        name='bFloor'
                        value={filter?.bFloor}
                        label="Select Building Floor"
                        onChange={(e) => {

                            setFilter({
                                ...filter,
                                bFloor: e.target.value
                            })
                            getFacilityServices(null, null, e.target.value)
                        }}
                    >
                        {
                            buildingFloorList?.filter(floor => floor?.buildingName?._id === filter?.bName)?.map((item, index) => {
                                return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                            })
                        }
                        {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                </FormControl>

                <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Select Space Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="bSpace"
                        name='bSpace'
                        value={filter?.bSpace}
                        label="Select Space Type"
                        onChange={(e) => {

                            setFilter({
                                ...filter,
                                bSpace: e.target.value
                            })
                            getFacilityServices(null, null, null, e.target.value)
                        }}
                    >
                        {
                            buildingSpaceList?.filter(space => space?.buildingFloor?._id === filter?.bFloor)?.map((item, index) => {
                                return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                            })
                        }
                        {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                </FormControl>


                <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Select Space ID</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="bSpaceId"
                        name='areaId'
                        value={filter?.bSpaceId}
                        label="Select Space ID"
                        onChange={(e) => {

                            setFilter({
                                ...filter,
                                bSpaceId: e.target.value
                            })
                            getFacilityServices(null, null, null, null, e.target.value)
                        }}
                    >
                        {
                            buildingSpaceIdList?.filter(spaceId => spaceId?.buildingSpace?._id === filter?.bSpace)?.map((item, index) => {
                                return <MenuItem key={index} value={item?._id}>{item?.areaId}</MenuItem>
                            })
                        }
                        {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                </FormControl>


                <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Select Issue Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="bIssueType"
                        name='bIssueType'
                        value={filter?.bIssueType}
                        label="Select Issue Type"
                        onChange={(e) => {

                            setFilter({
                                ...filter,
                                bIssueType: e.target.value
                            })
                            getFacilityServices(null, null, null, null, null, e.target.value)
                        }}
                    >
                        {/* {
                    buildingTypeList?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  } */}
                        <MenuItem value={null}>coming soon</MenuItem>

                    </Select>
                </FormControl>


                <FormControl fullWidth style={{ width: "100%", marginLeft: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="bStatus"
                        name='bStatus'
                        value={filter?.bStatus}
                        label="Status"
                        onChange={(e) => {

                            setFilter({
                                ...filter,
                                bIssueType: e.target.value
                            })
                            getFacilityServices(null, null, null, null, null, null, null, e.target.value)
                        }}
                    >
                        {/* {
                    buildingTypeList?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  } */}
                        <MenuItem value={'Active'}>Active</MenuItem>
                        <MenuItem value={'Inactive'}>Inactive</MenuItem>
                    </Select>
                </FormControl>

            </Grid>

            <div style={{ marginTop: "50px" }}>

                <TableContainer component={Paper} sx={{ mx: "0.8rem", width: "100%", my: "6rem" }}>
                    {/* <div style={{width: 'auto', overflowX: 'scroll'}}> */}

                    <Table
                        sx={{ minWidth: "auto" }}
                        aria-label="custom pagination table"
                        className="responsive-table"
                    >
                        <TableHead>
                            <TableRow className="table-header">
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Request ID
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Date Logged
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Building Type
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Building Name
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Building Floor
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Space Type
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Space Id
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Priority Level
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Service Type
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Fault Type
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Issue Description
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Logged By
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Assignee
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Vendor
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    WO Number
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Status
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Action Taken
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Job Attachments
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Date Closed
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Action
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                [...fsrList]?.reverse().slice(page * perPage, page * perPage + perPage).map((item, index) => {

                                    return (
                                        (

                                            <TableRow key={index}>

                                                <TableCell align="left" sx={tableData}>
                                                    {item?.requestId}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.loggedby}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.buildingTypes?.title}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.buildingName?.title}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.buildingFloor?.title}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.buildingSpaceType?.title}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.buildingSpaceId?.areaId}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.priorityLevel}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.priorityLevel}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.faultType}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.issueDescription}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.loggedBy}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.buildingTypes?.title}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.buildingTypes?.title}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.woNumber}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.status}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.actionTaken}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.jobAttachment}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.dateClosed}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData} style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Button size="small" className="btn-div" variant="outlined" color="info"
                                                        onClick={(e) => editFSrType(e, item?._id)}
                                                    >
                                                        <EditIcon className="" />
                                                    </Button>
                                                    <Button size="small" className="btn-div" variant="outlined" color="error"
                                                        onClick={() => deleteBuildingType(item?._id)}
                                                    >
                                                        <DeleteIcon className="" />
                                                    </Button>
                                                </TableCell>

                                            </TableRow>
                                        )
                                    )
                                })}
                            {
                                !fsrList &&
                                <EmptyTable colSpan={9} />
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>

                                <TablePagination
                                    align="right"
                                    rowsPerPageOptions={perPages}
                                    colSpan={9}
                                    count={1}
                                    rowsPerPage={perPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}

                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                    {/* </div> */}
                </TableContainer>

            </div>

            <div>
                <Modal
                    open={modalOpen}

                >
                    <Box sx={style}>
                        <PageTitle title={"Add Building Space Id"} />



                        <Grid style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Select Building Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='buildingTypes'
                                    value={inputs?.buildingTypes}
                                    label="Select Building Type"
                                    onChange={onChange}
                                >
                                    {
                                        buildingTypeList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Select Building Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='buildingName'
                                    value={inputs?.buildingName}
                                    label="Select Building Name"
                                    onChange={onChange}
                                >
                                    {
                                        buildingNameList?.filter(name => name?.buildingTypes?._id === inputs?.buildingTypes)?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Select Building Floor</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='buildingFloor'
                                    value={inputs?.buildingFloor}
                                    label="Select Building Floor"
                                    onChange={onChange}
                                >
                                    {
                                        buildingFloorList?.filter(floor => floor?.buildingName?._id === inputs?.buildingName)?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Select Building Space</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='buildingSpaceType'
                                    value={inputs?.buildingSpaceType}
                                    label="Select Building Space"
                                    onChange={onChange}
                                >
                                    {
                                        buildingSpaceList?.filter(space => space?.buildingFloor?._id === inputs?.buildingFloor)?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>

                            {/* <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Priority Level</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='priorityLevel'
                                    value={inputs?.priorityLevel}
                                    label="Age"
                                    onChange={onChange}
                                >
                                    {
                                        buildingSpaceList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    
                                </Select>
                            </FormControl> */}

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Space ID</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='areaId'
                                    value={inputs?.areaId}
                                    label="Space ID"
                                    onChange={onChange}
                                >
                                    {
                                        buildingSpaceIdList?.filter(spaceId => spaceId?.buildingSpaceType?._id === inputs?.buildingSpaceType)?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.areaId}</MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Priority Level</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='priorityLevel'
                                    value={inputs?.priorityLevel}
                                    label="Priority Level"
                                    onChange={onChange}
                                >
                                    {/* {
                                        buildingSpaceList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    } */}

                                    <MenuItem value="Emergency">Emergency</MenuItem>
                                    <MenuItem value="Urgent">Urgent</MenuItem>
                                    <MenuItem value="Priority 1 (24 Hours)">Priority 1 (24 Hours)</MenuItem>
                                    <MenuItem value="Priority 2 (48 Hours)">Priority 2 (48 Hours)</MenuItem>
                                    <MenuItem value="Priority 3 (72 Hours)">Priority 3 (72 Hours)</MenuItem>
                                    <MenuItem value="Priority 4 (72+ Hours)">Priority 3 (72+ Hours)</MenuItem>

                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Issue Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='issueType'
                                    value={inputs?.issueType}
                                    label="Priority Level"
                                    onChange={onChange}
                                >
                                    {/* {
                                        buildingSpaceList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    } */}

                                    <MenuItem value="Building Structure">Building Structure</MenuItem>
                                    <MenuItem value="Cleaning General">Cleaning General</MenuItem>
                                    <MenuItem value="Communication and Signage">Communication and Signage</MenuItem>
                                    <MenuItem value="Disk allocations">Disk allocations</MenuItem>
                                    <MenuItem value="Door - Automatic /  Motorised">Door - Automatic /  Motorised</MenuItem>
                                    <MenuItem value="Furniture, Fixtures and Fittings">Furniture, Fixtures and Fittings</MenuItem>
                                    <MenuItem value="Fire">Fire</MenuItem>
                                    <MenuItem value="Heating, ventilation and Cooling">Heating, ventilation and Cooling</MenuItem>
                                    <MenuItem value="Landscape & Garden">Landscape & Garden</MenuItem>
                                    <MenuItem value="Lifts & Escalators ">Lifts & Escalators </MenuItem>
                                    <MenuItem value="Lockers & Storage">Lockers & Storage</MenuItem>
                                    <MenuItem value="Mailroom ">Mailroom  </MenuItem>
                                    <MenuItem value="Visitor & VIP Guests">Visitor & VIP Guests</MenuItem>
                                    <MenuItem value="Other workplace enquiries">Other workplace enquiries</MenuItem>
                                    <MenuItem value="Pest control">Pest control</MenuItem>
                                    <MenuItem value="Plumbing">Plumbing</MenuItem>
                                    <MenuItem value="Roller Door/ Grills/ Shutters">Roller Door/ Grills/ Shutters</MenuItem>
                                    <MenuItem value="Room Booking & Set Up ">Room Booking & Set Up </MenuItem>
                                    <MenuItem value="Other ">Other </MenuItem>
                                    <MenuItem value="Security">Security</MenuItem>
                                    {/* <MenuItem value="Priority 3 (72 Hours)">Electrical Services</MenuItem> */}

                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Fault Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='faultType'
                                    value={inputs?.faultType}
                                    label="Fault Type"
                                    onChange={onChange}
                                >
                                    {/* {
                                        findFaultType?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item}>{item}</MenuItem>
                                            )
                                        })
                                    } */}

                                    <MenuItem value="Emergency">Emergency</MenuItem>
                                    <MenuItem value="Urgent">Urgent</MenuItem>
                                    <MenuItem value="Priority 1 (24 Hours)">Priority 1 (24 Hours)</MenuItem>
                                    <MenuItem value="Priority 2 (48 Hours)">Priority 2 (48 Hours)</MenuItem>
                                    <MenuItem value="Priority 3 (72 Hours)">Priority 3 (72 Hours)</MenuItem>
                                    <MenuItem value="Priority 3 (72 Hours)">Priority 3 (72 Hours)</MenuItem>

                                </Select>
                            </FormControl>



                            {/* <TextField label="Priority Level" value={inputs?.priorityLevel} name='priorityLevel' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={onChange} /> */}
                            {/* <TextField label="Issue Type" value={inputs?.issueType} name='issueType' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={onChange} /> */}
                            {/* <TextField label="Fault Type" value={inputs?.faultType} name='faultType' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={onChange} /> */}
                            <TextField label="Issue Description" value={inputs?.issueDescription} name='issueDescription' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={onChange} />
                        </Grid>

                        <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
                            <Button variant="contained" onClick={onSubmit} style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Submit
                            </Button>

                            <Button onClick={() => {
                                setModalOpen(false)
                                // setEditMode(false)
                            }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Cancel
                            </Button>
                        </Grid>

                        <div></div>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={editModal}

                >
                    <Box sx={style}>
                        <PageTitle title={"Add Building Space Id"} />


                        <Grid style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>


                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Select Building Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='buildingTypes'
                                    value={buildingInputs?.buildingTypes}
                                    label="Age"
                                    onChange={(e) => setBuildingInputs({ ...buildingInputs, buildingTypes: e.target.value })}
                                >
                                    {
                                        buildingTypeList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Select Building Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='buildingName'
                                    value={buildingInputs?.buildingName}
                                    label="Age"
                                    onChange={(e) => setBuildingInputs({ ...buildingInputs, buildingName: e.target.value })}
                                >
                                    {
                                        buildingNameList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Select Building Floor</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='buildingFloor'
                                    value={buildingInputs?.buildingFloor}
                                    label="Age"
                                    onChange={(e) => setBuildingInputs({ ...buildingInputs, buildingFloor: e.target.value })}
                                >
                                    {
                                        buildingFloorList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Select Building Space</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='buildingSpaceType'
                                    value={buildingInputs?.buildingSpaceType}
                                    label="Age"
                                    onChange={(e) => setBuildingInputs({ ...buildingInputs, buildingSpaceType: e.target.value })}
                                >
                                    {
                                        buildingSpaceList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>

                            {/* <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Priority Level</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='priorityLevel'
                                    value={inputs?.priorityLevel}
                                    label="Age"
                                    onChange={onChange}
                                >
                                    {
                                        buildingSpaceList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                    
                                </Select>
                            </FormControl> */}

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Space ID</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='buildingSpaceId'
                                    value={buildingInputs?.buildingSpaceId}
                                    label="Age"
                                    onChange={(e) => setBuildingInputs({ ...buildingInputs, buildingSpaceId: e.target.value })}
                                >
                                    {
                                        buildingSpaceIdList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.areaId}</MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>

                            {/* <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Fault Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='faultType'
                                    value={inputs?.faultType}
                                    label="Age"
                                    onChange={onChange}
                                >
                                    {
                                        buildingSpaceList?.map((item, index) => {
                                            return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                                        })
                                    }
                                   
                                </Select>
                            </FormControl> */}



                            <TextField label="Priority Level" value={buildingInputs?.priorityLevel} name='priorityLevel' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={editChange} />
                            <TextField label="Issue Type" value={buildingInputs?.issueType} name='issueType' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={editChange} />
                            <TextField label="Fault Type" value={buildingInputs?.faultType} name='faultType' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={editChange} />
                            <TextField label="Issue Description" value={buildingInputs?.issueDescription} name='issueDescription' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={editChange} />
                        </Grid>

                        <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
                            <Button variant="contained" onClick={onSubmit} style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Update
                            </Button>

                            <Button onClick={() => {
                                setEditModal(false)
                                // setEditMode(false)
                            }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Cancel
                            </Button>
                        </Grid>

                        <div></div>
                    </Box>
                </Modal>
            </div>



        </div>
    )
}

export default FSR