import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Skeleton,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import PageTitle from "../../../common/PageTitle";
import Table from "@mui/material/Table";
import MenuItem from '@mui/material/MenuItem';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import { TextField, FormControl, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectSiteInspectionForm } from "../../../../features/sites/sitesSlice";
import {
  addQuestions,
  getSitesInspectionForm,
} from "../../../../features/sites/sitesAPI";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";

import "./style.css";
import Loader from "../../../common/Loader";
import { deleteAPI, getAPI, patchAPI, postAPI } from "../../../network";
import { tableHeader, tableData } from "../../../utils";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
export default function SiteFormPage() {
  // const dispatch = useDispatch();
  // const { loading, error, data } = useSelector(selectSiteInspectionForm);
  const { siteId, repId, inspId } = useParams();
  const [loader, setLoader] = useState(false)
  const [question, setQuestion] = useState('')
  const [id, setId] = useState('')
  const [forms, setForms] = useState([])
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [report, setReport] = useState([]);
  const toastObj = { position: toast.POSITION.TOP_RIGHT };
  // console.log("ReportTypeId",inspId)
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      question: "",
      reportType: repId,
      siteId: siteId,
      inspectionId: inspId
    },
  });


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };




  const onSubmit = async (form) => {
    if (form.question === '' || form.question.length < 15) {
      toast.warning('Question is required! and at least 15 character long', toastObj)
      return;
    }
    if (form.question.length > 200) {
      toast.warning('Question should be less than 200 character long', toastObj)
      return;
    }

    setLoader(true)
    let data = await postAPI(`/report-questions`, form)
    if (data) {
      handleClose();
      reset();
      getSitesInspectionForm()
    }
    // setLoader(false)
    // dispatch(addQuestions({ ...form, reportType: siteId }));
    // handleClose();
    // dispatch(getSitesInspectionForm({ reportType: siteId }));
    // reset();
  };

  const onEdit = async () => {
    if (question === '' || question.length < 15) {
      toast.warning('Question is required! and at least 15 character long', toastObj)
      return;
    }
    if (question.length > 200) {
      toast.warning('Question should be less than 200 character long', toastObj)
      return;
    }
    const payload={
      question,
      reportType: repId,
      siteId: siteId,
      inspectionId: inspId
    }

    setLoader(true)
    let data = await patchAPI(`/report-questions/${id}`, payload)
    if (data) {
      handleClose2();
      getSitesInspectionForm()
    }
    // setLoader(false)
    // dispatch(addQuestions({ ...form, reportType: siteId }));
    // handleClose();
    // dispatch(getSitesInspectionForm({ reportType: siteId }));
    // reset();
  };

  useEffect(() => {
    getSitesInspectionForm()
    getReport()
    // dispatch(getSitesInspectionForm({ reportType: siteId, id: id }));
  }, []);

  const getReport = async () => {
    setLoader(true)
    const data = await getAPI('/reports');
    if (data) {
      setReport(data)
    }

    setLoader(false)
  }

  const getSitesInspectionForm = async () => {
    setLoader(true)
    let data = await getAPI(`/report-questions?inspectionId=${inspId}`)
    if (data) {
      var arr = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        obj['choices'] = [
          { id: 1, name: "Yes", checked: false },
          { id: 2, name: "No", checked: false },
          { id: 3, name: "NA", checked: false },
        ];
        obj['note'] = '';
        arr.push(obj)
      }
      setForms(arr)
    }
    setLoader(false)
  }



  const changeChoice = (itemId, id) => {
    let output = forms.map((item) => {
      if (item.id === itemId) {
        var choices = item.choices;
        var newChoices = [];
        for (var i = 0; i < choices.length; i++) {
          if (choices[i].id === id) {
            newChoices.push({ id: choices[i].id, name: choices[i].name, checked: true })
          }
          else {
            newChoices.push({ id: choices[i].id, name: choices[i].name, checked: false })
          }

        }
        var newItem = item;
        newItem['choices'] = newChoices;
        return newItem;
        // return item;
      }
      else {
        return item;
      }
    })
    setForms(output)
  }


  const changeNote = (itemId, text) => {
    let output = forms.map((item) => {
      if (item.id === itemId) {
        var newItem = item;
        newItem['note'] = text;
        return newItem;
      }
      else {
        return item;
      }
    })
    setForms(output)
  }

  const editForm = (q,id) => {
    setOpen2(true)
    setQuestion(q)
    setId(id)
  }
  const deleteForm = async(id) => {
    await deleteAPI(`/report-questions/${id}`)
    getSitesInspectionForm()

  }



  return (
    <Box sx={{ height: "inherit" }}>
      <Loader loader={loader} />
      <PageTitle
        title="Site View"
        subTitle="Create Form"
        headerTitle="Fire Alarm Report"
      />
      <Box
        Box
        display="flex"
        ml={4}
        flexDirection="column"
        rowGap={10}
        mb="20%"
      >

        <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
          {/* <div style={{width: 'auto', overflowX: 'scroll'}}> */}
          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="center" component="th" sx={{ tableHeader, width: '50px !important' }}>
                  Que No.
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Enter Question
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Choices
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action
                </TableCell>
                {/* <TableCell align="left" component="th" sx={tableHeader}>
                  Notes
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>

              {forms.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ tableData, width: '50px !important' }}>
                    {index + 1}
                  </TableCell>
                  <TableCell align="left" sx={tableData}>
                    {item?.question}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={tableData}
                    style={{ width: "23%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      {item.choices.map((opt, ind) => (
                        <Button
                          variant="outlined"
                          onClick={() => { changeChoice(item.id, opt.id) }}
                          key={ind}
                          displayEmpty
                          className={
                            opt.checked ? "opt-button-active" : "opt-button"
                          }

                        >
                          {opt.name}
                        </Button>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell align="center" >

                    <Button variant="outlined" className="btn-div" color="info" sx={{ mx: 1 }} onClick={(e) => editForm(item?.question, item?.id)}>

                      <EditIcon className="" />
                    </Button>


                    <Button variant="outlined" className="btn-div" color="error" onClick={() => deleteForm(item?.id)}>
                      <DeleteIcon className="" />
                    </Button>

                  </TableCell>
                  {/* <TableCell align="left" sx={tableData}>
                    <TextField
                      id="outlined-basic"
                      label="Enter your Note"
                      variant="outlined"
                      size="small"
                      value={item.note}
                      onChange={(event) => {changeNote(item.id, event.target.value)}}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
              <TableRow key="last">
                <TableCell
                  align="center"
                  component={Button}
                  sx={{
                    fontWeight: "500 !important",
                    py: 1,
                    ml: 1,
                    color: "#75859D !important",
                    tableData
                  }}
                  onClick={handleClickOpen}
                >
                  <span> Add
                    <AddCircleIcon className="add-icon" fontSize="medium" /></span>
                </TableCell>

                <TableCell align="left" sx={tableData}></TableCell>
                <TableCell align="left" sx={tableData}></TableCell>
                {/* <TableCell align="left" sx={tableData}></TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
          {/* </div> */}
        </TableContainer>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add Question</DialogTitle>
          <DialogContent sx={{ width: '30rem' }}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              my={3}
              component="form"
            >
              <Grid item xs={7} justifyContent="center" display="flex">
                <Controller
                  name={"question"}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Question"
                      variant="outlined"
                      type="text"
                      required
                      sx={{ width: '100%' }}
                      onChange={onChange}
                      value={value}
                      error={!!errors.question}
                      helperText={
                        errors.question ? errors.question?.message : null
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              my={3}
              component="form"
            >
              <Grid item xs={7} justifyContent="space-around" display="flex">
                <Button
                  disabled={false}
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button disabled={false} onClick={handleSubmit(onSubmit)}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog open={open2} onClose={handleClose2}>
          <DialogTitle>Edit Question</DialogTitle>
          <DialogContent sx={{ width: '30rem' }}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              my={3}
              component="form"
            >
              <Grid item  justifyContent="center" display="flex">
                <TextField id="outlined-basic" label="Question" variant="outlined" value={question} onChange={(e) => setQuestion(e.target.value)} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              my={3}
              component="form"
            >
              <Grid item xs={7} justifyContent="space-around" display="flex">
                <Button
                  disabled={false}
                  color="secondary"
                  onClick={handleClose2}
                >
                  Cancel
                </Button>
                <Button disabled={false} onClick={()=>onEdit()}>
                  Edit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
