
/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from "react";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Paper,
    Grid,
    FormControl,
    Skeleton,
    Button,
    Alert,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormLabel
} from "@mui/material";

import { Link } from "react-router-dom";

import TableFooter from "@mui/material/TableFooter";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Loader from '../common/Loader';
import PageTitle from '../common/PageTitle';
import { tableData, tableHeader } from '../utils';
import EmptyTable from '../common/EmptyTable';
import { getAPI } from '../network';





export default function PanicAlarm() {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    // const [companyId] = useContext(CompanyContext)
    let companyId = localStorage.getItem('companyIdLS')
    if (companyId === null) {
        companyId = undefined
    }

    const [loader, setLoader] = useState(false)
    const [panic, setPanic] = useState([])
    const [page, setPage] = useState(0);
    const [perPages, setPerPages] = useState([5, 10, 25, 50]);
    const [perPage, setPerPage] = useState(10)

    const panicAlarm = async () => {
        setLoader(true)
        const data = await getAPI('/user/home/panicList')
        console.table(data)
        setPanic(data)
        setLoader(false)

    }

    useEffect(() => {
        panicAlarm()
    }, [])

    const handleMapNavigate = (latitude, longitude) => {
        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(googleMapsUrl, '_blank');
    };
    const handleChangeRowsPerPage = (event) => {
        // console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    return (
        <Box>
            <Loader loader={loader} />
            <PageTitle title="Panic Alarm Triggered List" />

            <Box ml={2}>
                <FormControl
                    sx={{
                        width: "100%",
                    }}
                >




                </FormControl>
                {

                    <Box display="flex" sx={{ my: "3rem" }}>
                        <TableContainer component={Paper}>

                            <Table
                                sx={{ minWidth: "auto" }}
                                aria-label="custom pagination table"
                                className="responsive-table"
                            >
                                <TableHead>
                                    <TableRow className="table-header">
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Date
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Time
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Site
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            User Name
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            User Email
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            Location
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>


                                    {panic?.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                        <TableRow key={index}>

                                            <TableCell align="left" sx={tableData}>
                                                {task?.date.split("T")[0]}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.time}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.site?.name}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {`${task?.userId?.firstname} ${task?.userId?.lastname}`}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.userId?.email}
                                            </TableCell>

                                            <TableCell align="left" sx={tableData}>
                                                Latitude: {task?.location?.lat} <br />
                                                Longitude {task?.location?.lng}  <br />
                                                <button onClick={() => handleMapNavigate(task?.location?.lat, task?.location?.lng)}>
                                                    Navigate to Location
                                                </button>
                                            </TableCell>




                                        </TableRow>

                                    ))}

                                    {
                                        panic.length === 0 &&
                                        <EmptyTable colSpan={6} />
                                    }
                                </TableBody>
                                <TableFooter>
                                    <TableRow >

                                        <TablePagination
                                            align="right"
                                            rowsPerPageOptions={perPages}
                                            colSpan={7}
                                            count={panic.length}
                                            rowsPerPage={perPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        // ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Box>
                }

            </Box>


        </Box>
    );
}
