/* eslint-disable no-unused-vars */

import { useEffect, useState } from "react";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Paper,
    Grid,
    FormControl,
    Skeleton,
    Button,
    Alert,
    InputLabel,
    Select
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation } from "react-router-dom";
import TableFooter from "@mui/material/TableFooter";
import LocalDateSelector from "../../common/LocalDateSelector";
import PageTitle from "../../common/PageTitle";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader"
import { deleteAPI, getAPI, getExportAPI, getExportPdfAPI, patchAPI } from "../../network";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

import EmptyTable from "../../common/EmptyTable";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthority, tableHeader, tableData, formatDate, timeFormat, formatDatePost } from "../../utils";
import "./style.css";
import { BASE_URL } from "../../../constant";




export default function VisitorTaskDetails({ route, navigation }) {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const [deleteId, setDeleteId] = useState('');
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)
    const [status2, setStatus2] = useState('')
    const [loader, setLoader] = useState(false)
    const [tasks, setTasks] = useState([])
    const [edit, setEdit] = useState('')
    const [expanded, setExpanded] = useState("panel_0");
    const location = useLocation();

    const [page, setPage] = useState(0);
    const [perPages, setPerPages] = useState([5, 10, 25, 50]);
    const [perPage, setPerPage] = useState(10)


    const [filter, setFilter] = useState({
        siteId: '',
        time: null,
        startDate: null,
        endDate: null,
    })
    const [status, setStatus] = useState({
        injurySuffered: '',
        taskCompleted: '',
        reportableIssue: '',
        taskStatus: '',
        notes: '',
        status: '',
    })

    var taskval = location.state


    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        getTasks()
        // getTasks()
    }, []);

    const getTasks = async (sId = null, sTime = null, sDate = null, eDate = null, stat) => {

        sId = sId !== null ? sId : filter.siteId;
        sTime = sTime !== null ? sTime : filter.time;
        sDate = sDate !== null ? sDate : filter.startDate;
        eDate = eDate !== null ? eDate : filter.endDate;

        var url = ''
        var start = true;
        if (sId !== '' && sId !== null) {
            url += (start ? '?' : '&') + `siteId=${sId}`
            start = false;
        }
        if (sTime !== '' && sTime !== null) {
            url += (start ? '?' : '&') + `timeDue=${timeFormat(sTime)}`
            start = false;
        }
        if (sDate !== '' && sDate !== null) {
            url += (start ? '?' : '&') + `startDate=${formatDatePost(sDate)}`
            start = false;
        }
        if (eDate !== '' && eDate !== null) {
            url += (start ? '?' : '&') + `endDate=${formatDatePost(eDate)}`
            start = false;
        }
        setLoader(true)
        if (stat) {
            let data = await getAPI(`/visitor-tasks/group-by-id/${taskval}?status=${stat}` + url)
            if (data) {
                setTasks(data)
            }
        } else {
            let data = await getAPI(`/visitor-tasks/group-by-id/${taskval}` + url)
            if (data) {
                setTasks(data)
            }

        }

        setLoader(false)
    }



    const deleteClick = (id) => {
        setDeleteId(id);
        setOpen(true)
    }

    const EditClick = (task) => {
        setShow(true)
        setEdit(task?._id)
        setStatus(prevState => ({
            ...prevState,
            injurySuffered: task.taskClockOutData?.injurySuffered,
            taskCompleted: task.taskClockOutData?.taskCompleted,
            reportableIssue: task.taskClockOutData?.reportableIssue,
            status: task.taskClockOutData?.taskStatus,
        }))
    }
    const handleChange = (event) => {
        setStatus(prevState => ({
            ...prevState,
            status: event.target.value,
        }))
    };
    const changeStatus = (e) => {
        setStatus2(e.target.value)
        getTasks(null, null, null, null, e.target.value)
    }

    const handleDelete = async (siteId = null) => {

        setLoader(true);
        let empty = await deleteAPI(`/visitor-tasks/${deleteId}`);
        if (empty) {
            setOpen(false);
            getTasks()

        }
        setLoader(false);
    }

    const handleEdit = async () => {
        const payload = {
            "status": status.status,
            "injurySuffered": status.injurySuffered,
            "taskCompleted": status.taskCompleted,
            "reportableIssue": status.reportableIssue,
            "pictureStatus": false,
            "notes": 'Task Updated by Admin',
        }
        console.log(payload, "********payload---")
        setLoader(true);
        let empty = await patchAPI(`/visitor-tasks/${edit}`, payload);
        if (empty) {
            setShow(false);
            getTasks()

        }
        setLoader(false);
    }

    const AssessExp2 = async (id) => {
        setLoader(true)

        let sDate = filter.startDate;
        let eDate = filter.endDate;

        var url = ''
        var start = true;

        if (sDate !== '' && sDate !== null) {
            url += (start ? '&' : '&') + `startDate=${formatDatePost(sDate)}`
            start = false;
        }
        if (eDate !== '' && eDate !== null) {
            url += (start ? '&' : '&') + `endDate=${formatDatePost(eDate)}`
            start = false;
        }
        let data = await getExportAPI(`${BASE_URL}/export/file/visitor-tasks?groupId=${taskval}` + url, `Visitor Task Details`)
        setLoader(false)
    }
    return (



        <Box>
            <Loader loader={loader} />
            <PageTitle title="Task Details" />
            <Box ml={1}>
                <FormControl
                    sx={{
                        width: "100%",
                    }}
                >
                    <Grid sx={{ my: "1rem" }} container spacing={3} width={"100%"}>
                        {
                            checkAuthority('VIEW_TASKS') &&
                            <>

                                {/* <Grid item xs={6} md={6} lg={2}>
                  <LocalTimeSelector
                    value={filter.time}
                    title="Select Time "
                    onChange={(time) => {

                      setFilter({
                        ...filter,
                        time: time
                      })
                      getTasks(null, time, null)
                    }}

                  />
                </Grid> */}
                                {/* <Grid item xs={6} md={6} lg={3}>
                                    <LocalDateSelector
                                        title="Start Date "
                                        value={filter.startDate}
                                        placeholder="Select Time"
                                        onChange={(date) => {
                                            setFilter({
                                                ...filter,
                                                startDate: date
                                            })
                                            getTasks(null, null, date)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6} lg={3}>
                                    <LocalDateSelector
                                        title="End Date "
                                        value={filter.endDate}
                                        placeholder="Select Time"
                                        onChange={(date) => {
                                            setFilter({
                                                ...filter,
                                                endDate: date
                                            })
                                            getTasks(null, null, null, date)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6} lg={3}>

                                    <Button variant="contained" style={{ backgroundColor: "#75859D" }} sx={{ height: 50, width: '17.5rem' }} onClick={() => AssessExp2()}>
                                        <GetAppRoundedIcon /> &nbsp; &nbsp;
                                        Export
                                    </Button>
                                </Grid> */}
                                {/* {
                  checkAuthority('ADD_TASK') &&
                  <Grid item xs={6} md={6} lg={4} sx={{ my: 2 }}>
                    <Link
                      to="/tasks/create"
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        padding: 15,
                        fontWeight: 600,
                        borderRadius: 5,
                        textDecoration: "none",
                      }}
                    >
                      Create Task
                    </Link>
                  </Grid>
                } */}
                            </>
                        }

                    </Grid>
                </FormControl>
                {
                    checkAuthority('VIEW_TASKS') &&
                    <Box display="flex" sx={{ my: "3rem" }}>
                        <TableContainer component={Paper}>

                            <Table
                                sx={{ minWidth: "auto" }}
                                aria-label="custom pagination table"
                                className="responsive-table"
                            >
                                <TableHead>
                                    <TableRow className="table-header">
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Title
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Task Date
                                        </TableCell>

                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Due Time
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Injury Suffered
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Completion Status
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Reportable Issue
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Notes                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Picture
                                        </TableCell>

                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Task Status
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tasks.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.title}
                                            </TableCell>

                                            <TableCell align="left" sx={tableData}>
                                                {new Date(task?.taskDate).toLocaleDateString("en-uk", {
                                                    year: "numeric",
                                                    month: "short",
                                                    day: "2-digit",
                                                })}
                                            </TableCell>

                                            <TableCell align="left" sx={tableData}>
                                                {task?.timeDue}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.taskClockOutData?.injurySuffered}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.taskClockOutData?.taskCompleted}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.taskClockOutData?.reportableIssue}
                                            </TableCell>

                                            <TableCell align="left" sx={tableData}>
                                                {task?.taskClockOutData?.notes}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                <img src={task?.taskClockOutData?.picture} style={{ width: '40px' }} alt="empty" />
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.status}
                                            </TableCell>

                                            <TableCell align="center" sx={{ textAlign: 'center', tableData }}>
                                                {
                                                    checkAuthority('CHANGE_TASK_STATUS') &&
                                                    <Button className="btn-div" variant="outlined" color="info" sx={{ mx: 1 }}
                                                        onClick={() => EditClick(task)}>
                                                        <EditIcon className="" />
                                                    </Button>
                                                }
                                                {
                                                    checkAuthority('VIEW_TASKS') &&
                                                    <Button className="btn-div" variant="outlined" color="error" sx={{ mx: 1 }}
                                                        onClick={() => deleteClick(task?._id)}>

                                                        <DeleteIcon className="" />
                                                    </Button>
                                                }

                                            </TableCell>
                                        </TableRow>

                                    ))}

                                    {
                                        tasks.length === 0 &&
                                        <EmptyTable colSpan={6} />
                                    }
                                </TableBody>
                                <TableFooter>
                                    <TableRow >
                                        <TablePagination
                                            align="right"
                                            rowsPerPageOptions={perPages}
                                            colSpan={7}
                                            count={tasks.length}
                                            rowsPerPage={perPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        // ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Box>
                }
                <Dialog open={show} onClose={() => setShow(false)} fullWidth>
                    <DialogTitle sx={{ textAlign: 'center', mb: 8 }}>Edit Task</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="site-label" >Injury Suffered</InputLabel>
                            <Select
                                labelId="site-label"
                                id="site-select"
                                value={status.injurySuffered}
                                label="Injury Suffered"
                                onChange={(e) => setStatus(prevState => ({
                                    ...prevState,
                                    injurySuffered: e.target.value,
                                }))}

                            >

                                <MenuItem value={'Yes'} >Yes</MenuItem>
                                <MenuItem value={'No'} >No</MenuItem>
                                <MenuItem value={'None'}> None</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="site-label" > Completion Status</InputLabel>
                            <Select
                                labelId="site-label"
                                id="site-select"
                                value={status.taskCompleted}
                                label="Completion Status"
                                onChange={(e) => setStatus(prevState => ({
                                    ...prevState,
                                    taskCompleted: e.target.value,
                                }))}
                            >

                                <MenuItem value={'Yes'} >Yes</MenuItem>
                                <MenuItem value={'No'} >No</MenuItem>
                                <MenuItem value={'None'}> None</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="site-label" >Reportable Issue</InputLabel>
                            <Select
                                labelId="site-label"
                                id="site-select"
                                value={status.reportableIssue}
                                label="Reportable Issue"
                                onChange={(e) => setStatus(prevState => ({
                                    ...prevState,
                                    reportableIssue: e.target.value,
                                }))}
                            >

                                <MenuItem value={'Defect'} >Defect</MenuItem>
                                <MenuItem value={'Critical Defect'} >Critical Defect</MenuItem>
                                <MenuItem value={'Non Critical Defect'} >Non Critical Defect</MenuItem>
                                <MenuItem value={'Non Conformance'} >Non Conformance</MenuItem>

                                <MenuItem value={'None'}> None</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="site-label" >Task Status</InputLabel>
                            <Select
                                labelId="site-label"
                                id="site-select"
                                value={status.status}
                                label="Task Status"
                                onChange={handleChange}

                            >
                          
                                <MenuItem value={'Completed'} >Completed</MenuItem>
                                <MenuItem value={'Partially Completed'} >Partially Completed</MenuItem>
                                <MenuItem value={'Reschedule'} >Reschedule</MenuItem>
                                <MenuItem value={'On Hold'} >On Hold</MenuItem>
                                <MenuItem value={'None'} >None</MenuItem>
                          
                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions sx={{ mx: 2, mb: 4 }}>
                        <Button onClick={handleEdit} variant="contained" color="primary">Update</Button>
                        <Button onClick={() => setShow(false)} variant="outlined">Cancel</Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
                    <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Task</DialogTitle>

                    <DialogContent>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { my: 2, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >

                            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this task</h3>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ mb: 2, mx: 4 }}>
                        <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
                        <Button onClick={() => setOpen(false)} variant="outlined">Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>

    );
}
/* eslint-disable no-unused-vars */
