/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Grid,
  FormControl,
  Skeleton,
  Button,
  Alert,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormLabel
} from "@mui/material";
import { red } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TableFooter from "@mui/material/TableFooter";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaceIcon from '@mui/icons-material/Place';
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { FilterList } from "@mui/icons-material";
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { checkAuthority, formatDatePost, getTimeFromTimestamp2, tableData, tableHeader, validation } from '../../utils';
import { deleteAPI, getAPI, getExportAPI, postAPI } from '../../network';
import { BASE_URL } from '../../../constant';
import { axiosInstance } from '../../../utils/axiosSetup';
import Loader from '../../common/Loader';
import PageTitle from '../../common/PageTitle';
import BasicSelector from '../../common/Selector';
import LocalDateSelector from '../../common/LocalDateSelector';
import EmptyTable from '../../common/EmptyTable';
import LocalTimeSelector from '../../common/LocalTimeSelector';



export default function TenancyChecks() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { siteId } = useParams();

  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [deleteId, setDeleteId] = useState('');
  const [open, setOpen] = useState(false)
  const [openV, setOpenV] = useState(false)
  const [loader, setLoader] = useState(false)
  const [tasks, setTasks] = useState([])
  const [sites, setSites] = useState([])
  const [companies, setCompanies] = useState([])
  const [emailList, setEmailList] = useState([])
  const [company, setCompany] = useState('')
  const [urlF, setUrlF] = useState('')

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([5, 10, 25, 50]);
  const [perPage, setPerPage] = useState(10)
  const [visitorDataList, setVisitorDataList] = useState(null)

  const [filter, setFilter] = useState({
    companyId: '',
    siteId: '',
    startDate: null,
    endDate: null,
    email: '',
  })

  const { handleSubmit, control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      company: "",
      site: "",
      date: "",
      checkIn: null,
      checkOut: null,
      lat: "",
      lng: "",
      purpose: "",

    },
  });

  const handleClose = () => {
    setOpenV(false);
  };

  const onSubmit = async (form) => {
    console.log('form++++++++++++++++++', form)
    form.companyId = company
    form.company = company
    if (validation(null, 'Name', form.firstname)) {

      return;
    }
    if (validation(null, 'Name', form.lastname)) {

      return;
    }
    if (validation('empty', 'email', form.email)) {
      return;
    }
    if (validation('empty', 'phone', form.phone)) {
      return;
    }
    else if (validation('empty', 'site', form.site)) {
      return;
    }
    else if (validation('empty', 'company', form.company)) {
      return;
    }
    else if (validation('empty', 'Date', form.date)) {
      return;
    }
    else if (validation('time', 'Check In', form.checkIn)) {
      return;
    }
    else if (validation('time', 'Check Out', form.checkOut)) {
      return;
    }

    setLoader(true)
    let data = await postAPI(`/visitor/auth/register`, form)
    console.log("data post++++", data)
    if (data) {
      // getSitesInspectionList()
      handleClose();
      reset();
      setCompany('')
      if (companyId) {
        getVisitorList(companyId)
      } else {
        getVisitorList()
      }
    }
    setLoader(false)

  };
  // const taskData = []
  // for (let j = 0; j < tasks.length; j++) {
  //   taskData.push(tasks[j].tasks)
  // }


  // const taskD = []
  // for (let i = 0; i < taskData.length; i++) {
  //   taskD.push(taskData[i][0])
  // }

  useEffect(() => {
    getVisitorList()

    getSites()
    // getCompanies()
    // visitorCount()
  }, []);


  const userType = localStorage.getItem('userType')


  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  // {console.log( checkAuthority('ADD_TASK') ? 'TRUE ---------' : "FALSE --------")}
  const getTasks = async (sId = null, sDate = null, eDate = null) => {

    sId = sId !== null ? sId : filter.siteId;
    eDate = eDate !== null ? eDate : filter.endDate;
    sDate = sDate !== null ? sDate : filter.startDate;

    var url = ''
    var start = true;
    if (sId !== '' && sId !== null) {
      url += (start ? '?' : '&') + `siteId=${sId?.id ? sId?.id : sId}`
      start = false;
    }
    if (eDate !== '' && eDate !== null) {
      url += (start ? '?' : '&') + `taskDate=${formatDatePost(eDate)}`
      start = false;
    }
    if (sDate !== '' && sDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(sDate)}`
      start = false;
    }
    setLoader(true)
    if (companyId) {
      let data = await getAPI(`/visitor-tasks?companyId=${companyId}` + url)
      if (data) {
        setTasks(data)
      }
    }
    else {
      let data = await getAPI(`/visitor-tasks` + url)
      if (data) {
        setTasks(data)
      }
    }

    setLoader(false)
  }
  const getVisitorList = async (cId = null, sId = null, sDate = null, eDate = null, email = null) => {

    sId = siteId;
    eDate = eDate !== null ? eDate : filter.endDate;
    sDate = sDate !== null ? sDate : filter.startDate;
    email = email !== null ? email : filter.email;

    var url = ''
    var start = true;

    if (sId !== '' && sId !== null) {
      url += (start ? '?' : '&') + `siteId=${sId?.id ? sId?.id : sId}`
      start = false;
    }
    if (eDate !== '' && eDate !== null) {
      url += (start ? '?' : '&') + `endDate=${formatDatePost(eDate)}`
      start = false;
    }
    if (sDate !== '' && sDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(sDate)}`
      start = false;
    }

    setLoader(true)
    setUrlF(url)
    let data = await getAPI(`/tenancy-check` + url)
    if (data) {
      setTasks(data)
      let emails = []
      for (let i = 0; i < data.length; i++) {
        let x = { label: data[i]?.user?.email, value: data[i]?.user?._id }
        emails.push(x)
      }
      setEmailList(emails)
    }

    setLoader(false)
  }

  const getSites = async (sId) => {
    setLoader(true)
    let data = await getAPI(`/sites?companyId=${sId ? sId : companyId}`)
    if (data) {
      var arr = []
      for (var i = 0; i < data.length; i++) {
        var obj = data[i]
        obj['label'] = obj.name
        obj['value'] = obj._id
        arr.push(obj)
      }
      setSites(arr)
    }
    setLoader(false)
  }

  // const getCompanies = async () => {
  //   setLoader(true)
  //   const data = await getAPI(`/companies?companyId=${companyId}`);
  //   if (data) {
  //     var arr = []
  //     for (var i = 0; i < data.length; i++) {
  //       var obj = data[i]
  //       obj['label'] = obj?.name
  //       obj['value'] = obj?.id
  //       arr.push(obj)
  //     }
  //     setCompanies(arr)
  //   }
  //   setLoader(false)
  // }

  const deleteClick = (id) => {
    setDeleteId(id);
    setOpen(true)
  }

  const handleDelete = async (siteId = null) => {

    setLoader(true);
    let empty = await deleteAPI(`/tasks/${deleteId}`);
    if (empty) {
      setOpen(false);
      if (companyId) {
        getVisitorList(companyId)
      } else {
        getVisitorList()
      }

    }
    setLoader(false);
  }
  const AssessExp = async (id) => {
    setLoader(true)
    let data = await getExportAPI(`${BASE_URL}/visitor_management?visitorId=${id}`, `Tenancy Checks`)
    setLoader(false)
  }
  const AssessExp2 = async (id) => {
    setLoader(true)
    let data = await getExportAPI(`${BASE_URL}/visitor/book/export${urlF}`, `Tenancy Checks`)
    setLoader(false)
  }

  // const visitorCount = async () => {
  //   setLoader(true)
  //   let data = await axiosInstance.get(`/visitor/book/count`);
  //   setVisitorDataList(data.data)
  // }

  return (
    <Box>
      <Loader loader={loader} />
      <PageTitle title="Tenancy Checks" />
      <Box ml={2}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%", mt: '1rem' }}
          >
            <div><b>Visitor Register</b></div>

            <div>
              <Button variant="contained" style={{ backgroundColor: "grey" }} sx={{ height: 50, m: 2 }}
                onClick={() => setOpenV(true)}>
                <AddCircleIcon /> &nbsp; &nbsp;
                Add Visitor
              </Button>
              <Button variant="contained" style={{ backgroundColor: "grey" }} sx={{ height: 50, m: 2 }} onClick={() => AssessExp2()}>
                <GetAppRoundedIcon /> &nbsp; &nbsp;
                Export
              </Button>
            </div>
          </Box> */}


          <Grid sx={{ my: "1rem" }} display={"flex"} gap="10px" spacing={3} width={"100%"}>


            {
              <>
                {/* <Grid item xs={6} md={6} lg={2}>

                  <BasicSelector
                    disableAll={true}
                    options={companies}
                    selectorHight={"53px"}
                    value={filter.companyId}
                    name={"Select Company"}
                    selectorWidth={"100%"}
                    onChange={(e) => {

                      setFilter({
                        ...filter,
                        companyId: e.target.value
                      })
                      getVisitorList(e.target.value)
                      getSites(e.target.value)
                    }}
                  />
                </Grid> */}

                {/* <Grid item xs={6} md={6} lg={2}>

                  <BasicSelector
                    disableAll={true}
                    options={emailList}
                    selectorHight={"53px"}
                    value={filter.email}
                    name={"Visitor Email"}
                    selectorWidth={"100%"}
                    onChange={(e) => {

                      setFilter({
                        ...filter,
                        email: e.target.value
                      })
                      getVisitorList(null, null, null, null, e.target.value)
                    }}
                  />
                </Grid> */}
                <Grid item xs={6} md={6} lg={3}>
                  <LocalDateSelector
                    title="Start Date "
                    value={filter.startDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        startDate: date
                      })
                      getVisitorList(null, null, date, null)
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                  <LocalDateSelector
                    title="End Date "
                    value={filter.endDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        endDate: date
                      })
                      getVisitorList(null, null, null, date)
                    }}
                  />
                </Grid>


              </>
            }

          </Grid>
        </FormControl>
        {
          checkAuthority('VIEW_TASKS') &&
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Date Submitted
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Site
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Shift Type
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Resources Name
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Time Submitted
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Export
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>


                  {tasks.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        {task?.createdAt?.split("T")[0]}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.shiftId?.siteId?.name}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.shiftId?.shiftType === 'Fixed' ? 'Rostered' : 'Casual'}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {`${task?.shiftId?.assignedUser?.firstname} ${task?.shiftId?.assignedUser?.lastname}`}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {getTimeFromTimestamp2(task?.updatedAt)}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        Download
                      </TableCell>

                    </TableRow>
                  ))}

                  {
                    tasks.length === 0 &&
                    <EmptyTable colSpan={6} />
                  }
                </TableBody>
                <TableFooter>
                  <TableRow >

                    <TablePagination
                      align="right"
                      rowsPerPageOptions={perPages}
                      colSpan={7}
                      count={tasks.length}
                      rowsPerPage={perPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        }

      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Task</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this task</h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
          <Button onClick={() => setOpen(false)} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openV} onClose={handleClose}>
        {/* <DialogTitle align="center">Add Visitor</DialogTitle> */}
        <Typography align="center" variant="h5">Add Visitor</Typography>
        <DialogContent height={"500px"} sx={{ zIndex: 10 }}>
          <Grid
            container
            spacing={2}
            mt={3}
            component="form">
            <Grid item xs={12} display="flex">

              <Controller
                name={"firstname"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Visitor First Name"
                    variant="outlined"
                    type="text"
                    required
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.name}
                    helperText={
                      errors.name ? errors.name?.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex">

              <Controller
                name={"lastname"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Visitor Last Name"
                    variant="outlined"
                    type="text"
                    required
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.name}
                    helperText={
                      errors.name ? errors.name?.message : null
                    }
                  />
                )}
              />
            </Grid>


            {/* <Grid item xs={12} display="flex" flexDirection="column" m={0}>

                <InputLabel id="gender-label">Report Category</InputLabel>
                <Controller
                  name={"reportTypeId"}
                  control={control}
                  rules={{
                    required: true,
                  }}

                  render={({ field: { onChange, value } }) => (

                    <Select
                      fullWidth
                      labelId="gender-label"
                      variant="outlined"
                      required
                      onChange={onChange}
                      value={value}
                      error={!!errors.reportTypeId}
                      helperText={errors.reportTypeId ? errors.reportTypeId?.message : null}
                    > */}
            {/* <MenuItem value={"HSW "}>HSW </MenuItem>
                        <MenuItem value={"Compliance"}>Compliance</MenuItem>
                        <MenuItem value={"Operational"}>Operational</MenuItem>
                        <MenuItem value={"General "}>General </MenuItem>
                        <MenuItem value={"Mandatory"}>Mandatory</MenuItem>
                        <MenuItem value={"Other "}>Other </MenuItem> */}
            {/* {allreports.map((item, index) => (
                        <MenuItem value={item?.id} key={index}>
                          {item?.name}
                        </MenuItem>
                      ))} */}
            {/* </Select>
                  )}
                />
              </Grid> */}



            <Grid item xs={12} display="flex">
              <Controller
                name={"email"}
                control={control}
                rules={{
                  required: true,
                  min: {
                    message: "Minimum Value Is Zero",
                    value: 0,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="email"
                    required
                    onChange={onChange}
                    value={value}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email?.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex">
              <Controller
                name={"phone"}
                control={control}
                rules={{
                  required: true,
                  min: {
                    message: "Minimum Value Is Zero",
                    value: 0,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    type="number"
                    required
                    onChange={onChange}
                    value={value}
                    error={!!errors.phone}
                    helperText={errors.phone ? errors.phone?.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} display="flex" flexDirection="column" m={0}>

              {/* <InputLabel id="gender-label">Company</InputLabel> */}
              {/* <Controller
                name={"company"}
                control={control}
                rules={{
                  required: true,
                }}

                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    error={!!errors.company}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      companies.map((item, index) => (
                        <MenuItem value={item?.id} key={index}>{item?.label}</MenuItem>
                      ))
                    }
                  </Select>
                )}
              /> */}
              <BasicSelector
                disableAll={true}
                options={companies}
                selectorHight={"53px"}
                value={company}
                name={"Select Company"}
                selectorWidth={"100%"}
                onChange={(e) => {

                  setCompany(e.target.value)
                  getVisitorList(e.target.value)
                  getSites(e.target.value)
                }}
              />
            </Grid>

            <Grid item xs={12} display="flex" flexDirection="column" m={0}>

              <InputLabel id="gender-label">Sites</InputLabel>
              <Controller
                name={"site"}
                control={control}
                rules={{
                  required: true,
                }}

                render={({ field: { onChange, value } }) => (
                  <Select

                    value={value}
                    onChange={onChange}
                    displayEmpty
                    // error={!!errors.sites}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      sites.map((item, index) => (
                        <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                      ))
                    }
                  </Select>
                )}
              />
            </Grid>

            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <InputLabel id="gender-label">Date</InputLabel>

            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <FormControl >
                <Controller
                  name="date"
                  control={control}

                  render={({ field: { onChange, value } }) => (
                    <LocalDateSelector
                      label="none"
                      onChange={onChange}
                      value={value}
                      inputFormat="YYYY-MM-DD"

                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <InputLabel id="gender-label">Check In</InputLabel>

            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <FormControl >
                <Controller
                  name="checkIn"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <LocalTimeSelector
                      label="none"
                      onChange={onChange}
                      value={value}

                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <InputLabel id="gender-label">Check Out</InputLabel>

            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <FormControl >
                <Controller
                  name="checkOut"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <LocalTimeSelector
                      label="none"
                      onChange={onChange}
                      value={value}

                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display="flex">

              <Controller
                name={"lat"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Latitude"
                    variant="outlined"
                    type="text"
                    required
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.lat}
                    helperText={
                      errors.lat ? errors.lat?.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex">

              <Controller
                name={"lng"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Longitude"
                    variant="outlined"
                    type="text"
                    required
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.lng}
                    helperText={
                      errors.lng ? errors.lng?.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex">

              <Controller
                name={"purpose"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Purpose"
                    variant="outlined"
                    type="text"
                    required
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.purpose}
                    helperText={
                      errors.purpose ? errors.purpose?.message : null
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            my={3}
            component="form"
          >
            <Grid item xs={7} justifyContent="space-around" display="flex">
              <Button
                disabled={false}
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button disabled={false} onClick={handleSubmit(onSubmit)}>
                Add
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
