import { Box, Button, Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import "./FieldStaff.css"

export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}

const FieldStaff = () => {
    return (
        <div>
            <div className='export-button' >
                <div style={{ display: "flex", gap: "10px" }}>
                    <p className='titles'>Client</p>
                    <p className='titles'>Supplier</p>
                    <p className='titles'>Field Staff Name</p>
                    <p className='titles'>Status</p>
                    <p className='titles'>Accreditation Name</p>
                </div>
                <Button variant="contained" style={{ backgroundColor: "grey" }} sx={{ height: 30, m: 2 }}>
                    <GetAppRoundedIcon /> &nbsp; &nbsp;
                    Export
                </Button>
            </div>
            <div >
                <Box display="flex" sx={{ my: "3rem" }}>
                    <TableContainer component={Paper}>

                        <Table
                            sx={{ minWidth: "auto" }}
                            aria-label="custom pagination table"
                            className="responsive-table"
                        >
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contract Or Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Field Staff Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Field Staff Email
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Field Staff Phone
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Field Staff Photo
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contact Name
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 1 Name
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 1 Number
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 1 Issue Date
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 1 Expiry Date
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        View Accreditation 1
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 2 Name
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 2 Number
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 2 Issue Date
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 2 Expiry Date
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        View Accreditation 2
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Status
                                    </TableCell>
                                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                        Task Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell> */}

                                </TableRow>
                            </TableHead>
                            {/* <TableBody>


                                {tasks.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.updatedAt?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.siteId?.name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {`${task?.user?.firstname} ${task?.user?.lastname}`}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.company}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.email}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.phone}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkIn?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkOut?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.purpose}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            No
                                        </TableCell>
                                        
                                        <TableCell align="left" sx={tableData}>
                                            Non Critical
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            Pending
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell>

                                    </TableRow>

                                ))}

                                {
                                    tasks.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody> */}
                            {/* <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={tasks.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter> */}
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default FieldStaff