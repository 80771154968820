import React, { useEffect, useState } from "react";
import {
  Box, Link, Button, Dialog, Skeleton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from "../../../common/PageTitle";
import Table from '@mui/material/Table';
import InputLabel from '@mui/material/InputLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import { useDispatch, useSelector } from "react-redux";
import { selectSiteInspection } from "../../../../features/sites/sitesSlice";
// import { blue } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import { Controller, useForm } from "react-hook-form";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TextField } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { red } from "@mui/material/colors";
import "./style.css";
import {
  siteInspection,
  getSitesInspection,
} from "../../../../features/sites/sitesAPI";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getAPI, postAPI, deleteAPI, patchAPI } from "../../../network";
import Loader from "../../../common/Loader";
import { checkAuthority, tableHeader, tableData, validation } from "../../../utils";
import { margin, padding } from "@mui/system";
import { CompanyContext } from "../../../../context";
import { af } from "date-fns/locale";

export default function SiteInspectionPage() {
  const currentSiteId = useLocation()
  const [open, setOpen] = React.useState(false);
  const [openE, setOpenE] = React.useState(false);
  // let cId = JSON.parse(localStorage.getItem('user'));
  // const [companyId, setCompanyId] = React.useState(cId.id);
  const [companyId] = React.useContext(CompanyContext)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const [loader, setLoader] = useState(false)
  const [inspections, setInspections] = useState([])
  const [sites, setSites] = useState([])
  const [allreports, setallReportsType] = useState([])
  const [show, setShow] = useState(false)
  const [id, setId] = useState('');
  // const { loading, error, data } = useSelector(selectSiteInspection);

  useEffect(() => {
    getSitesInspectionList()
    getSites();
    getReportTypeList()
    getSites()
    // dispatch(getSitesInspection({ siteId: siteId }));
  }, []);
  // console.log(allSites, "ALL Sitesss++++++++++++++++++++")

  const { handleSubmit, control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      category: "",
      reportTypeId: "",
      siteId: siteId,
      description: "",
      sites: []

    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseE = () => {
    setOpenE(false);
  };
  const [editData, setEditData] = useState({
    name: "",
    category: "",
    reportTypeId: "",
    // siteId: siteId,
    description: "",
    siteId: []
  })

  const handleEdit = (name, category, reportTypeId, description, sites, id) => {
    setId(id);
    setOpenE(true);
    let siteArray = []
    for (let i = 0; i < sites.length; i++) {
      siteArray.push(sites[i]?._id)
    }
    setEditData(prevState => ({
      ...prevState,
      name: name,
      category: category,
      reportTypeId: reportTypeId?._id,
      // siteId: siteId,
      description: description,
      siteId: siteArray
    }))
  }
  const handleEditApi = async () => {
    console.log('editData++++++++++++++++++', editData)
    if (validation(null, 'Name', editData.name)) {

      return;
    }
    else if (validation(null, 'Category', editData.category)) {

      return;
    }


    else if (validation('Desc', 'Description', editData.description)) {

      return;
    }
    setLoader(true)
    let data = await patchAPI(`/report-types/${id}`, editData)
    // console.log("data post-----------///////", data)
    if (data) {
      getSitesInspectionList()
      handleCloseE();
    }
    setOpenE(false);
    setLoader(false)

  }
  const onSubmit = async (form) => {
    console.log('form++++++++++++++++++', form)
    if (validation(null, 'Name', form.name)) {

      return;
    }
    else if (validation(null, 'Category', form.category)) {

      return;
    }


    else if (validation('Desc', 'Description', form.description)) {

      return;
    }
    setLoader(true)
    let data = await postAPI(`/report-types`, form)
    // console.log("data post-----------///////", data)
    if (data) {
      getSitesInspectionList()
      handleClose();
      reset();
    }
    setLoader(false)

  };


  const getSites = async () => {
    setLoader(true)
    let userType = localStorage.getItem('userType')
    console.log(currentSiteId.pathname, "currentSiteId")
    let currentSiteId2 = (userType === 'admin' ? currentSiteId.pathname.split("/")[2] : currentSiteId.pathname.split("/")[3])
    let data2 = await getAPI(`/company/sites/${currentSiteId2}`);
    let companyId = data2.companyId ? data2.companyId : "64c755d43420e4a2bf22f6bf"
    let data = await getAPI(userType === 'admin' ? `/sites?companyId=${companyId._id}` : '/company/sites');
    // let data = await getAPI(userType === 'admin' ? `/sites?companyId=${companyId}` : '/company/sites');
    if (data) {
      console.log(data)
      setSites(data)
    }
    setLoader(false)
  }






  const getSitesInspectionList = async () => {
    setLoader(true)

    let data = await getAPI(`/report-types?siteId=${siteId}`)

    if (data) {
      setInspections(data)
    }
    setLoader(false)
  }
  const getReportTypeList = async () => {
    setLoader(true)

    let data = await getAPI(`/all-report-types`)
    if (data) {
      setallReportsType(data)
    }
    setLoader(false)
  }

  const handleNavigateForm = (id, repId, inspId) => {
// console.log(id,1122);
    navigate(`${window.location.pathname}/form/${id}/${repId}/${inspId}`);
  }
  const handleNavigateSubmitForm = (id, repId, inspId) => {
    navigate(`${window.location.pathname}/submitform/${id}/${repId}/${inspId}`);
  }
  const getSiteName = () => {
    if (sites.length > 0) {
      let site = sites.filter((item) => item._id === siteId)[0];

      return site?.name + ', ' + site?.address + ', ' + site?.city?.name + ' / Site Inspection'
    }
    else {
      return '';
    }
  }
  const handleShowClose = () => {
    setShow(false)
  }
  const deleteClick = (id) => {
    setId(id);
    setShow(true)
  }
  const handleDelete = async () => {
    setLoader(true)

    let data = await deleteAPI(`/report-types/${id}`);
    if (data) {
      // getReportTypeList()
      getSitesInspectionList()
      setShow(false)

    }
    setLoader(false)

  }
  const editForm = () => {
  
  }
  const deleteForm = () => {
  
  }


  return (
    <Box sx={{ height: "inherit" }}>
      <Loader loader={loader} />
      <PageTitle title="Site View" subTitle={getSiteName()} />
      <Box display="flex" sx={{ my: "4rem" }}>

        {
          checkAuthority('VIEW_SITE_INSPECTIONS') &&
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }} >
            <Table sx={{ minWidth: 'auto' }} aria-label="custom pagination table" className="responsive-table">
              <TableHead >
                <TableRow className="table-header">
                  <TableCell align="left" component="th" sx={tableHeader}>Report Category</TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>Report Name</TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>Description</TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>Create Form</TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>Submit Form</TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>


                {
                  inspections.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        {item?.category}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.name}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.description}
                      </TableCell>
                      {
                        checkAuthority('ADD_INSPECTION_FORM') ?
                          <TableCell align="center" className="t-body-cell font-bold formBtn" onClick={() => handleNavigateForm(item.siteId[0]._id, item?._id, item?._id)}>
                            {'Create Form'}
                          </TableCell> 
                          :
                          <TableCell align="center" className="t-body-cell font-bold">
                            <span className="formBtn"></span>
                          </TableCell>
                      }

                      {
                        checkAuthority('SUBMIT_INSPECTION_FORM') ?
                          <TableCell align="center" className="t-body-cell font-bold" onClick={() => handleNavigateSubmitForm(item?.siteId[0]._id, item?._id, item?._id)}>
                            <span className="formBtn">{'Submit Form'}</span>
                          </TableCell>
                          :
                          <TableCell align="center" className="t-body-cell font-bold">
                            <span className="formBtn"></span>
                          </TableCell>
                      }

                      <TableCell align="center" sx={tableData}>
                        {
                          checkAuthority('EDIT_ALERT') &&
                          <Button className="btn-div" color="primary" variant="outlined" sx={{ mx: 2 }} onClick={() => handleEdit(item?.name,item?.category,item?.reportTypeId, item?.description, item?.siteId, item?._id)}>
                            <EditIcon className="" />
                          </Button>
                        }
                        {
                          checkAuthority('DELETE_ALERT') &&
                          <Button className="btn-div" color="error" variant="outlined" onClick={() => deleteClick(item?._id)}>
                            <DeleteIcon className="" />
                          </Button>
                        }
                      </TableCell>

                    </TableRow>
                  ))
                }
              </TableBody>
              {
                checkAuthority('ADD_SITE_INSPECTION') &&
                <TableFooter>
                  <TableRow>
                    <TableCell sx={tableData} direction="column" justifyContent="center" onClick={handleClickOpen}>
                      <Link href="#" underline="none" >
                        <div className="custom-table-cell">
                          <span className="add-title">Add Report</span>
                          <AddCircleIcon className="add-icon" fontSize="medium" />
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              }

            </Table>
          </TableContainer>
        }


        <Dialog open={open} onClose={handleClose}>
          <DialogTitle align="center">Add Report</DialogTitle>
          <DialogContent height={"500px"}>
            <Grid
              container
              spacing={2}
              mt={3}
              component="form">
              <Grid item xs={12} display="flex">

                <Controller
                  name={"name"}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Report Name"
                      variant="outlined"
                      type="text"
                      required
                      onChange={onChange}
                      value={value}
                      sx={{ borderColor: red[100] }}
                      error={!!errors.name}
                      helperText={
                        errors.name ? errors.name?.message : null
                      }
                    />
                  )}
                />
              </Grid>


              <Grid item xs={12} display="flex" flexDirection="column" m={0}>

                <InputLabel id="gender-label">Report Category</InputLabel>
                <Controller
                  name={"category"}
                  control={control}
                  rules={{
                    required: true,
                  }}

                  render={({ field: { onChange, value } }) => (

                    <Select
                      fullWidth
                      labelId="gender-label"
                      variant="outlined"
                      required
                      onChange={onChange}
                      value={value}
                      error={!!errors.reportTypeId}
                      helperText={errors.reportTypeId ? errors.reportTypeId?.message : null}
                    >
                      <MenuItem value={"HSW "}>HSW </MenuItem>
                      <MenuItem value={"Compliance"}>Compliance</MenuItem>
                      <MenuItem value={"Operational"}>Operational</MenuItem>
                      <MenuItem value={"General "}>General </MenuItem>
                      <MenuItem value={"Mandatory"}>Mandatory</MenuItem>
                      <MenuItem value={"Other "}>Other </MenuItem>
                      {/* {allreports.map((item, index) => (
                        <MenuItem value={item?.id} key={index}>
                          {item?.name}
                        </MenuItem>
                      ))} */}
                    </Select>
                  )}
                />
              </Grid>



              <Grid item xs={12} display="flex">
                <Controller
                  name={"description"}
                  control={control}
                  rules={{
                    required: true,
                    min: {
                      message: "Minimum Value Is Zero",
                      value: 0,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Description"
                      variant="outlined"
                      type="text"
                      required
                      onChange={onChange}
                      value={value}
                      error={!!errors.description}
                      helperText={errors.description ? errors.description?.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} display="flex" flexDirection="column" m={0}>

                <InputLabel id="gender-label">Sites</InputLabel>
                <Controller
                  name={"sites"}
                  control={control}
                  rules={{
                    required: true,
                  }}

                  render={({ field: { onChange, value } }) => (
                    <Select
                      multiple
                      value={value}
                      onChange={onChange}
                      displayEmpty
                      error={!!errors.sites}
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                    >

                      {
                        sites.map((item, index) => (
                          <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                        ))
                      }
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              my={3}
              component="form"
            >
              <Grid item xs={7} justifyContent="space-around" display="flex">
                <Button
                  disabled={false}
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button disabled={false} onClick={handleSubmit(onSubmit)}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog open={openE} onClose={handleCloseE}>
          <DialogTitle align="center">Edit Report</DialogTitle>
          <DialogContent height={"500px"}>
            <Grid
              container
              spacing={2}
              mt={3}
              component="form">
              <Grid item xs={12} display="flex">

                <Controller
                  name={"name"}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Report Name"
                      variant="outlined"
                      type="text"
                      required
                      value={editData?.name}
                      onChange={(data) => {
                        setEditData(prevState => ({
                          ...prevState,
                          name: data.target.value,
                        }))
                      }}
                      sx={{ borderColor: red[100] }}
                      error={!!errors.name}
                      helperText={
                        errors.name ? errors.name?.message : null
                      }
                    />
                  )}
                />
              </Grid>


              <Grid item xs={12} display="flex" flexDirection="column" m={0}>

                <InputLabel id="gender-label">Report Category</InputLabel>
                <Controller
                  name={"category"}
                  control={control}
                  rules={{
                    required: true,
                  }}

                  render={({ field: { onChange, value } }) => (

                    <Select
                      fullWidth
                      labelId="gender-label"
                      variant="outlined"
                      required
                      value={editData.category}
                      onChange={(data) => {
                        setEditData(prevState => ({
                          ...prevState,
                          category: data.target.value,
                        }))
                      }}
                      error={!!errors.reportTypeId}
                      helperText={errors.reportTypeId ? errors.reportTypeId?.message : null}
                    >
                      <MenuItem value={"HSW "}>HSW </MenuItem>
                        <MenuItem value={"Compliance"}>Compliance</MenuItem>
                        <MenuItem value={"Operational"}>Operational</MenuItem>
                        <MenuItem value={"General "}>General </MenuItem>
                        <MenuItem value={"Mandatory"}>Mandatory</MenuItem>
                        <MenuItem value={"Other "}>Other </MenuItem>
                      {/* {allreports.map((item, index) => (
                        <MenuItem value={item?.id} key={index}>
                          {item?.name}
                        </MenuItem>
                      ))} */}
                    </Select>
                  )}
                />
              </Grid>



              <Grid item xs={12} display="flex">
                <Controller
                  name={"description"}
                  control={control}
                  rules={{
                    required: true,
                    min: {
                      message: "Minimum Value Is Zero",
                      value: 0,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Description"
                      variant="outlined"
                      type="text"
                      required
                      value={editData.description}
                      onChange={(data) => {
                        setEditData(prevState => ({
                          ...prevState,
                          description: data.target.value,
                        }))
                      }}
                      error={!!errors.description}
                      helperText={errors.description ? errors.description?.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} display="flex" flexDirection="column" m={0}>

                <InputLabel id="gender-label">Sites</InputLabel>
                <Controller
                  name={"sites"}
                  control={control}
                  rules={{
                    required: true,
                  }}

                  render={({ field: { onChange, value } }) => (
                    <Select
                      multiple
                      value={editData.siteId}
                      onChange={(data) => {
                        setEditData(prevState => ({
                          ...prevState,
                          siteId: data.target.value,
                        }))
                      }}
                      displayEmpty
                      error={!!errors.sites}
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                    >

                      {
                        sites.map((item, index) => (
                          <MenuItem value={item?._id} key={index}>{item?.name}</MenuItem>
                        ))
                      }
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              my={3}
              component="form"
            >
              <Grid item xs={7} justifyContent="space-around" display="flex">
                <Button
                  disabled={false}
                  color="secondary"
                  onClick={handleCloseE}
                >
                  Cancel
                </Button>
                <Button disabled={false} onClick={() => handleEditApi()}>
                  Edit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>




        <Dialog open={show} onClose={handleShowClose} fullWidth={true}>
          <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Are You Sure Want to Delete?</DialogTitle>

          <DialogContent>
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { my: 2, width: '100%' }, }}
              noValidate
              autoComplete="off"
            >
              <h4 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this inspection</h4>
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mx: 4 }}>
            <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
            <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}


