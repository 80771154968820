import React from 'react'
import { Box, Grid } from "@mui/material";
import PageTitle from '../../common/PageTitle';
import SiteDetailCard from '../../components/sites/DetailCard';
const RiskAssessmentNav = () => {
    return (
        <Box sx={{ height: "inherit" }}>
            <PageTitle title="Risk Assessment " />
            <Box display="flex">
                <Grid
                    container
                    rowSpacing={7}
                    columnSpacing={{ xs: 1 }}
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    mt="1%"
                >

                    <SiteDetailCard large title={'Forms '} url={'/riskAssessment/forms'} />
                    <SiteDetailCard large title={'Options'} url={'/riskAssessment/options'} />
                    <SiteDetailCard large title={'Reports'} url={'/riskAssessment/reports'} />

                </Grid>
            </Box>
        </Box>
    )
}

export default RiskAssessmentNav