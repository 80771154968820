import React, { useEffect, useState } from "react";
import {
  Box, Link, Button, Dialog, Skeleton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  TextField,
  FormLabel
} from "@mui/material";

import PageTitle from "../../common/PageTitle";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TablePagination from "@mui/material/TablePagination";
import BasicSelector from "../../common/Selector";
import Table from '@mui/material/Table';
import InputLabel from '@mui/material/InputLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import { useDispatch, useSelector } from "react-redux";
// import { selectSiteInspection } from "../../../features/sites/sitesSlice";
// import Select from 'react-select';
// import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

import { Controller, useForm } from "react-hook-form";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { red } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import { getAPI, postAPI, patchAPI, deleteAPI } from "../../network";
import Loader from "../../common/Loader";
import { checkAuthority, tableHeader, tableData, formatDate, fullName, tablebtn } from "../../utils";
import "./index.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RiskAssessment() {
  const { ids } = useParams();
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [loader, setLoader] = useState(false)
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState("");
  const [assessment, setAssessment] = useState([])
  const [all, setAll] = useState([])
  const [category, setCategory] = useState([])
  // const [siteId, setSiteId] = useState('')
  const [page, setPage] = useState(0)
  const [perPages, setPerPages] = useState([5, 15, 25, 50]);
  const [perPage, setPerPage] = useState(5)

  const [question, setQuestion] = useState('');
  const [siteOptions, setSiteOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCatOptions, setSubCatOptions] = useState([])
  const [likelihoodOption, setLikelihoodOption] = useState([])
  const [impactOption, setImpactOption] = useState([])
  const [inherentIption, setInherentIption] = useState([])
  const [riskOption, setRiskOption] = useState([])
  const [questionError, setQuestionError] = useState(false)
  const [assessName, setAssessName] = useState('')

  const [rating, setRating] = useState([])
  const [impact, setImpact] = useState([])
  const [like, setLike] = useState([])
  const [inherent, setInherant] = useState([])
  const [cat, setCat] = useState([])
  const [site, setSite] = useState([])
  const [subcat, setsubcat] = useState([])
  const [note, setNote] = useState([])


  const [options, setOptions] = useState({
    questionEdit: '',
    // siteIdEdit: [],
    riskCategoryEdit: [],
    riskSubCategoryEdit: [],
    inherentRiskEdit: [],
    LikelihoodEdit: [],
    impactTypeEdit: [],
    ratingEdit: [],
    notesEdit: '',
    categoryError: false,
    subcategoryError: false,
    riskIdentifiedError: false,
    likelihoodError: false,
    impactError: false,
    riskRatingError: false
  })

  useEffect(() => {

    getAssessmentCategory()
    getCategoryQuestions()
    getCategoryOtion()
    // siteLists()
    getCatoptions()
    getSubCatoptions()
    getlikeoptions()
    getImpactoptions()
    getInherentoptions()
    getRatingOptions()
  }, []);



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearAll()
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  // const editCat = (e, id) => {
  //   e.preventDefault();
  //   clearAll();
  //   let data = assessment.filter(item => item.id === id)[0];
  //   // console.log("DATA----", data)
  //   setOptions(prevState => ({
  //     ...prevState,
  //     site: '',
  //     category: [],
  //     subcategory: [],
  //     riskInherent: [],
  //     likelihood: [],
  //     impact: [],
  //     riskRating: [],
  //     notes: '',
  //   }))
  // }


  const handleSubmit = async (e) => {
    e.preventDefault()
    setOptions(prevState => ({
      ...prevState,
      categoryError: false,
      subcategoryError: false,
      riskIdentifiedError: false,
      likelihoodError: false,
      impactError: false,
      riskRatingError: false,
      questionError: false,
      categoryIdError: false
    }))


    const payload = {
      'riskAssessmentCategoryId': ids,
      'question': question,
      'category': cat,
      // 'siteId': site,
      'subCategory': subcat,
      'riskIdentified': inherent,
      'likelihood': like,
      'impact': impact,
      'rating': rating,
      'note': 'Sample note for now'
    }
    // console.log("PAYLOAD_______", payload)
    if (payload.category.length === 0) {
      toast.error('Choose at Least one Risk Category')
      return
    }
    // if (!payload.siteId.length) {
    //   toast.error('Choose Site')
    //   return
    // }
    if (payload.subCategory.length === 0) {
      toast.error('Choose at Least one Risk Sub-Category')
      return
    }
    if (payload.riskIdentified.length === 0) {
      toast.error('Choose at Least one Inherent Risk')
      return
    }
    if (payload.likelihood.length === 0) {
      toast.error('Choose at Least one Likelihood')
      return
    }
    if (payload.impact.length === 0) {
      toast.error('Choose at Least one Impact Type')
      return
    }
    if (payload.rating.length === 0) {
      toast.error('Choose Rating')
      return
    }
    setLoader(true)
    let data = await postAPI(`/risk-assessment/category-question`, payload)
    // console.log("data post-----------///////", data)
    if (data) {
      handleClose();
      getCategoryQuestions()
      clearAll()
    }
    setLoader(false)

  };

  const handleEdit = async (e) => {
    e.preventDefault()

    const payload = {
      'question': options.questionEdit,
      // 'siteId': options.siteIdEdit,
      'riskAssessmentCategoryId': ids,
      'category': options.riskCategoryEdit,
      'subCategory': options.riskSubCategoryEdit,
      'riskIdentified': options.inherentRiskEdit,
      'likelihood': options.LikelihoodEdit,
      'impact': options.impactTypeEdit,
      'rating': options.ratingEdit,
      // 'note': options.notesEdit
    }
    console.log("PAYLOAD-EDIT_______", payload)
    if (payload.category.length === 0) {
      toast.error('Choose at Least one Risk Category')
      return
    }
    if (payload.subCategory.length === 0) {
      toast.error('Choose at Least one Risk Sub-Category')
      return
    }
    if (payload.riskIdentified.length === 0) {
      toast.error('Choose at Least one Inherent Risk')
      return
    }
    if (payload.likelihood.length === 0) {
      toast.error('Choose at Least one Likelihood')
      return
    }
    if (payload.impact.length === 0) {
      toast.error('Choose at Least one Impact Type')
      return
    }
    if (payload.rating.length === 0) {
      toast.error('Choose Rating')
      return
    }
    setLoader(true)
    let data = await patchAPI(`/risk-assessment/category-question/${editId}`, payload)
    // console.log("data post-----------///////", data)
    if (data) {
      handleCloseEdit();
      getCategoryQuestions()
      // clearAll()
    }
    setLoader(false)
  };

  const handleDuplicate = async (e, id, question, category, subcategory, riskIdentified, likelihood, impact, rating, note, siteId) => {


    const payload = {
      'question': question,
      // 'siteId': siteId,
      'riskAssessmentCategoryId': ids,
      'category': category?.map(obj => obj?._id),
      'subCategory': subcategory?.map(obj => obj?._id),
      'riskIdentified': riskIdentified?.map(obj => obj?._id),
      'likelihood': likelihood?.map(obj => obj?._id),
      'impact': impact?.map(obj => obj?._id),
      'rating': rating?.map(obj => obj?._id),
      'note': 'Sample note for now'
    }
    // const payload = {
    //   'question': options.questionEdit,
    //   'siteId': options.siteIdEdit,
    //   'riskAssessmentCategoryId': ids,
    //   'category': options.riskCategoryEdit,
    //   'subCategory': options.riskSubCategoryEdit,
    //   'riskIdentified': options.inherentRiskEdit,
    //   'likelihood': options.LikelihoodEdit,
    //   'impact': options.impactTypeEdit,
    //   'rating': options.ratingEdit,
    //   'note': options.notesEdit
    // }
    console.log("PAYLOAD-DUPLICATE", payload)
    console.log("options ", options)
    if (payload.category.length === 0) {
      toast.error('Choose at Least one Risk Category')
      return
    }
    if (payload.subCategory.length === 0) {
      toast.error('Choose at Least one Risk Sub-Category')
      return
    }
    if (payload.riskIdentified.length === 0) {
      toast.error('Choose at Least one Inherent Risk')
      return
    }
    if (payload.likelihood.length === 0) {
      toast.error('Choose at Least one Likelihood')
      return
    }
    if (payload.impact.length === 0) {
      toast.error('Choose at Least one Impact Type')
      return
    }
    if (payload.rating.length === 0) {
      toast.error('Choose Rating')
      return
    }
    setLoader(true)
    let data = await postAPI(`/risk-assessment/category-question`, payload)
    // console.log("data post-----------///////", data)
    if (data) {
      handleCloseEdit();
      getCategoryQuestions()
      // clearAll()
    }
    setLoader(false)
  };


  const clearAll = () => {
    setQuestion('')
    setRating([])
    setImpact([])
    setLike([])
    setInherant([])
    setCat([])
    setsubcat([])
    setNote([])
    setOptions({
      categoryError: false,
      subcategoryError: false,
      riskIdentifiedError: false,
      likelihoodError: false,
      impactError: false,
      riskRatingError: false
    })

    // console.log("options**************", options)
  }

  const getAssessmentCategory = async () => {
    setLoader(true)
    // debugger
    let process = await getAPI('/risk-assessment/category');

    if (process) {
      var categories = [];
      for (var i = 0; i < process.length; i++) {
        categories.push({ label: process[i].name, id: process[i].id })
      }

      setCategory(categories);
    }
  }
  // const getSiteName= async () => {
  //   if(!site){
  //     return 'NA'
  //   }
  //   let process = await getAPI(`/company/sites/${site}`);
  //   console.log(process)
  //   if (process) {
  //     return process.name
  //   }else{
  //     return 'NA'
  //   }
  // }
  const deleteAssessmentBox = (e, id) => {

    setEditId(id);
    // clearAll();
    setShow(true);
  }
  const editAssessmentBox = (e, id, question, category, subcategory, riskIdentified, likelihood, impact, rating, note, siteId) => {
    console.log(siteId, 'siteIdEdit')
    setOptions(prevState => ({
      ...prevState,
      questionEdit: question,
      siteIdEdit: siteId,
      riskCategoryEdit: category?.map(obj => obj?._id),
      riskSubCategoryEdit: subcategory?.map(obj => obj?._id),
      inherentRiskEdit: riskIdentified?.map(obj => obj?._id),
      LikelihoodEdit: likelihood?.map(obj => obj?._id),
      impactTypeEdit: impact?.map(obj => obj?._id),
      ratingEdit: rating?.map(obj => obj?._id),
      notesEdit: note,
    }))
    setEditId(id);
    setOpenEdit(true);
  }
  // const duplicateAssessmentBox = (e, id, question, category, subcategory, riskIdentified, likelihood, impact, rating, note,siteId) => {
  //   setLoader(true)
  //   console.log(siteId,11111111111)
  //   getCategoryQuestions()
  //   setOptions(prevState => ({
  //     ...prevState,
  //     questionEdit: question,
  //     siteIdEdit: siteId,
  //     riskCategoryEdit: category?.map(obj => obj?._id),
  //     riskSubCategoryEdit: subcategory?.map(obj => obj?._id),
  //     inherentRiskEdit: riskIdentified?.map(obj => obj?._id),
  //     LikelihoodEdit: likelihood?.map(obj => obj?._id),
  //     impactTypeEdit: impact?.map(obj => obj?._id),
  //     ratingEdit: rating?.map(obj => obj?._id),
  //     notesEdit: note,
  //   }))
  //   console.log(options,11111111111)
  //   setEditId(id);
  //   handleDuplicate()
  //   // toast.success('Duplicated, Please Edit')    
  //   // editAssessmentBox(e, id,question,category,subcategory,riskIdentified,likelihood,impact,rating,note)
  //   // handleClickOpenEdit()
  // }

  const deleteAssessment = async () => {
    setLoader(true);
    let process = await deleteAPI(`risk-assessment/category-question/${editId}`);
    setLoader(false);
    if (process) {
      getAssessmentCategory()
      getCategoryQuestions()
      getCategoryOtion()
      siteLists()
      getCatoptions()
      getSubCatoptions()
      getlikeoptions()
      getImpactoptions()
      getInherentoptions()
      getRatingOptions()
    }
    setShow(false)
    setLoader(false);

  }

  const handleShowClose = () => {
    setShow(false);
  }
  const getCategoryQuestions = async () => {

    setLoader(true)
    let data = await getAPI(`/risk-assessment/category-question/by-category/${ids}`);
    // console.log('URL LINK','/user/reports' + generateUrl(dSId, dSDate, dEDate))
    console.log(data);
    if (data.length) {
      setAssessment(data)
      setAssessName(data[0].riskAssessmentCategoryId.name)
    }
    setLoader(false)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };

  const siteLists = async () => {
    let process = await getAPI('/sites');
    if (process) {
      var sites = [];
      for (var i = 0; i < process.length; i++) {
        sites.push({ label: process[i].name, value: process[i]._id })
      }
      setSiteOptions(sites);
    }
  }

  const getCatoptions = async () => {
    setLoader(true)

    let data = await getAPI(`/risk-assessment-choice-option?category=Risk Category`)
    let out = []
    if (data) {
      let outputs = data.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id

      }))
      out = out.concat(outputs)
      setCategoryOptions(out)
    }
    setLoader(false)

  }
  const getSubCatoptions = async () => {
    setLoader(true)

    let data = await getAPI(`/risk-assessment-choice-option?category=Risk Sub-Category`)
    let out = []
    if (data) {
      let outputs = data.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id

      }))
      out = out.concat(outputs)
      setSubCatOptions(out)
    }
    setLoader(false)

  }
  const handleOptions = async (cat) => {
    setLoader(true)

    // let data = await getAPI(`/risk-assessment-question-choice`)
    let out = []
    if (category) {
      for (let i = 0; i < category.length; i++) {

        if (cat == category[i].id) {
          let outputs = category[i].options
          setCat(outputs)
        }

      }
    }

  }
  const getlikeoptions = async () => {
    setLoader(true)

    let data = await getAPI(`/risk-assessment-choice-option?category=Likelihood`)
    let out = []
    if (data) {
      let outputs = data.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id

      }))
      out = out.concat(outputs)
      setLikelihoodOption(out)
    }
    setLoader(false)
  }


  const getImpactoptions = async () => {
    setLoader(true)

    let data = await getAPI(`/risk-assessment-choice-option?category=Impact Type`)
    let out = []
    if (data) {
      let outputs = data.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id

      }))
      out = out.concat(outputs)
      setImpactOption(out)
    }
    setLoader(false)
  }


  const getInherentoptions = async () => {
    setLoader(true)
    let data = await getAPI(`/risk-assessment-choice-option?category=Inherent Risk`)
    let out = []
    if (data) {
      let outputs = data.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id

      }))
      out = out.concat(outputs)
      setInherentIption(out)
    }
    setLoader(false)
  }


  const getRatingOptions = async () => {
    setLoader(true)

    let data = await getAPI(`/risk-assessment-choice-option?category=Rating`)
    let out = []
    if (data) {
      let outputs = data.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id

      }))
      out = out.concat(outputs)
      setRiskOption(out)
    }
    setLoader(false)
  }





  const getCategoryOtion = async () => {
    setLoader(true)

    let data = await getAPI(`/risk-assessment/category-question/by-category/6409c1f94cd9a6340cab18a1`)

    if (data) {

    }
    setLoader(false)
  }


  return (


    <Box sx={{ height: "inherit" }}>
      <ToastContainer />

      <Loader loader={loader} />
      <PageTitle title="Risk Assessment Forms" subTitle={assessName} />
      {
        checkAuthority('SUBMIT_ASSESSMENT_FORM') &&
        <Box display="flex" sx={{ my: "4rem" }}>


          <TableContainer component={Paper} sx={{ mx: "0.8rem" }} >
            <Table sx={{ minWidth: 'auto' }} aria-label="custom pagination table" className="responsive-table">
              <TableHead >
                <TableRow className="table-header">
                  <TableCell align="left" component="th" sx={{ width: '30px' }}>Sr No.</TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>Question</TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>Risk Category</TableCell>
                  <TableCell align="left" component="th" sx={tableHeader}>Risk Sub-Category</TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>Inherent Risk</TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>Likelihood</TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>Impact Type</TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>Rating</TableCell>
                  {/* <TableCell align="center" component="th" sx={tableHeader}>Notes</TableCell> */}
                  <TableCell align="center" component="th" sx={tableHeader}>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {assessment.slice(page * perPage, page * perPage + perPage)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.question}
                      </TableCell>
                      {/* <TableCell align="left" sx={tableData}>
                        {getSiteName(item?.siteId)?getSiteName(item?.siteId):'NA'}
                      </TableCell> */}
                      <TableCell align="left" sx={tableData}>
                        {item?.category.map((data, i = 0) => (
                          <span style={{ border: "1px solid gray", padding: "2px", margin: "2px" }}>{data?.name}</span>
                        ))
                        }
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.subCategory.map((data, i = 0) => (
                          <span style={{ border: "1px solid gray", padding: "2px", margin: "2px" }}>{data?.name}</span>
                        ))
                        }
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.riskIdentified.map((data, i = 0) => (
                          <span style={{ border: "1px solid gray", padding: "2px", margin: "2px" }}>{data?.name}</span>
                        ))
                        }
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.likelihood.map((data, i = 0) => (
                          <span style={{ border: "1px solid gray", padding: "2px", margin: "2px" }}>{data?.name}</span>
                        ))
                        }
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.impact.map((data, i = 0) => (
                          <span style={{ border: "1px solid gray", padding: "2px", margin: "2px" }}>{data?.name}</span>
                        ))
                        }
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {item?.rating.map((data, i = 0) => (
                          <span style={{ border: "1px solid gray", padding: "2px", margin: "2px" }}>{data?.name}</span>
                        ))
                        }
                      </TableCell>
                      {/* <TableCell align="left" sx={tableData}>
                        {item?.note}
                      </TableCell> */}
                      <TableCell align="center"  sx={tablebtn}>

                        <Button variant="outlined" className="btn-div" color="info" sx={{ mx: 1 }} onClick={(e) => editAssessmentBox(e, item?.id, item?.question, item?.category, item?.subCategory, item?.riskIdentified, item?.likelihood, item?.impact, item?.rating, item?.note, item?.siteId)} >
                          <EditIcon className="" />
                        </Button>
                        <Button variant="outlined" className="btn-div" color="info" sx={{ mx: 1 }}
                          onClick={(e) => handleDuplicate(e, item?.id, item?.question, item?.category, item?.subCategory, item?.riskIdentified, item?.likelihood, item?.impact, item?.rating, item?.note, item?.siteId)} >

                          <ContentCopyIcon className="" />
                        </Button>


                        <Button variant="outlined" className="btn-div" color="error" onClick={(e) => deleteAssessmentBox(e, item?.id)} >
                          <DeleteIcon className="" />
                        </Button>

                      </TableCell>

                    </TableRow>
                  ))
                }
              </TableBody>


              <TableFooter>
                <TableRow>
                  <TableCell colspan={2} sx={tableData} direction="column" justifyContent="left" onClick={handleClickOpen}>
                    <Link href="#" underline="none" >
                      <div className="custom-table-cell">
                        <span className="add-title">Add Questions</span>
                        <AddCircleIcon className="add-icon" fontSize="medium" />
                      </div>
                    </Link>
                  </TableCell>
                </TableRow>
              </TableFooter>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={perPages}
                    colSpan={9}
                    count={assessment.length}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>

            </Table>
          </TableContainer>




          <Dialog open={open} onClose={handleClose}>
            <DialogTitle align="center">Add Risk Assessment Question</DialogTitle>
            <DialogContent height={"500px"}>
              <Grid
                container
                spacing={2}
                mt={3}
                component="form">
                <FormControl sx={{ minWidth: '90%', mt: 2, ml: 1.5 }}>

                  <TextField
                    fullWidth
                    label="Question"
                    variant="outlined"
                    type="text"
                    required
                    onChange={(e) => {
                      setQuestion(e.target.value)
                      // console.log(e.target.value,"value")
                    }}
                    value={question}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                    error={questionError}

                  />
                </FormControl>



                {/* site */}

                {/* <FormControl sx={{ m: 1, width: "90%" }}>

                <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Site</span>
                <Select
                    // value={props.selectedItem}
                    multiple
                    value={site}
                    onChange={event => {
                      const value = event.target.value

                      setSite(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value,
                        // value 

                      ); 
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      siteOptions.map((item, index) => (
                        <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl> */}

                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Risk Category</span>
                  <Select
                    // value={props.selectedItem}
                    multiple
                    value={cat}
                    onChange={event => {
                      const value = event.target.value

                      setCat(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value,

                      );
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      categoryOptions.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>





                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Risk Sub-Category</span>
                  <Select
                    multiple
                    value={subcat}
                    onChange={event => {
                      const value = event.target.value
                      setsubcat(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value,

                      );
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      subCatOptions.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>





                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Inherent Risk</span>
                  <Select
                    multiple
                    value={inherent}
                    onChange={event => {
                      const value = event.target.value

                      setInherant(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value,

                      );
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      inherentIption.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>




                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Likelihood</span>
                  <Select
                    multiple
                    value={like}
                    onChange={event => {
                      const value = event.target.value

                      setLike(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value,

                      );
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      likelihoodOption.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Impact Type</span>
                  <Select
                    multiple
                    value={impact}
                    onChange={event => {
                      const value = event.target.value

                      setImpact(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value,

                      );
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      impactOption.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Rating</span>
                  <Select
                    multiple
                    value={rating}
                    onChange={event => {
                      const value = event.target.value
                      setRating(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value,

                      );
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      riskOption.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {/* 
                <FormControl sx={{ minWidth: '90%', mt: 2, ml: 1.5 }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Notes</span>
                  <Grid item >
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      required
                      onChange={(e) => {
                        setNote(e.target.value)
                      }}
                      style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                      value={note}
                      error={options.notesError}
                    // helperText={errors.description ? errors.description?.message : null}
                    />

                  </Grid>
                </FormControl> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                my={3}
                component="form"
              >
                <Grid item xs={7} justifyContent="space-around" display="flex">
                  <Button
                    disabled={false}
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button disabled={false} onClick={handleSubmit}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>


          <Dialog open={openEdit} onClose={handleCloseEdit}>
            <DialogTitle align="center">Edit Risk Assessment Question</DialogTitle>
            <DialogContent height={"500px"}>
              <Grid
                container
                spacing={2}
                mt={3}
                component="form">
                <FormControl sx={{ minWidth: '90%', mt: 2, ml: 1.5 }}>

                  <TextField
                    fullWidth
                    label="Question"
                    variant="outlined"
                    type="text"
                    required
                    value={options.questionEdit}
                    onChange={(e) => {
                      setOptions(prevState => ({
                        ...prevState,
                        questionEdit: e.target.value,

                      }))
                    }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                    error={questionError}

                  />
                </FormControl>




                {/* <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Site</span>
                  <Select
                    // value={props.selectedItem}
                    multiple
                    value={options.siteIdEdit}
                    onChange={event => {
                      const value = event.target.value

                      setOptions(prevState => ({
                        ...prevState,
                        siteIdEdit: typeof value === 'string' ? value.split(',') : value,
                      }))
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      siteOptions.map((item, index) => (
                        <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl> */}



                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Risk Category</span>
                  <Select
                    // value={props.selectedItem}
                    multiple
                    value={options.riskCategoryEdit}
                    onChange={event => {
                      const value = event.target.value

                      setOptions(prevState => ({
                        ...prevState,
                        riskCategoryEdit: typeof value === 'string' ? value.split(',') : value,
                      }))
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      categoryOptions.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>





                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Risk Sub-Category</span>
                  <Select
                    multiple

                    value={options?.riskSubCategoryEdit}
                    onChange={event => {
                      const value = event.target.value

                      setOptions(prevState => ({
                        ...prevState,
                        riskSubCategoryEdit: typeof value === 'string' ? value.split(',') : value,
                      }))
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      subCatOptions.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>





                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Inherent Risk</span>
                  <Select
                    multiple
                    value={options?.inherentRiskEdit}
                    onChange={event => {
                      const value = event.target.value

                      setOptions(prevState => ({
                        ...prevState,
                        inherentRiskEdit: typeof value === 'string' ? value.split(',') : value,
                      }))
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      inherentIption.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>




                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Likelihood</span>
                  <Select
                    multiple
                    value={options?.LikelihoodEdit}
                    onChange={event => {
                      const value = event.target.value

                      setOptions(prevState => ({
                        ...prevState,
                        LikelihoodEdit: typeof value === 'string' ? value.split(',') : value,
                      }))
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      likelihoodOption.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Impact Type</span>
                  <Select
                    multiple
                    value={options?.impactTypeEdit}
                    onChange={event => {
                      const value = event.target.value

                      setOptions(prevState => ({
                        ...prevState,
                        impactTypeEdit: typeof value === 'string' ? value.split(',') : value,
                      }))
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      impactOption.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, width: "90%" }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Rating</span>
                  <Select
                    multiple
                    value={options?.ratingEdit}
                    onChange={event => {
                      const value = event.target.value

                      setOptions(prevState => ({
                        ...prevState,
                        ratingEdit: typeof value === 'string' ? value.split(',') : value,
                      }))
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      riskOption.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>

                {/* <FormControl sx={{ minWidth: '90%', mt: 2, ml: 1.5 }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5 }}>Notes</span>
                  <Grid item >
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      required
                      value={options?.notesEdit}
                      onChange={(e) => {
                        setOptions(prevState => ({
                          ...prevState,
                          notesEdit: e.target.value,
                        }))
                      }}
                      style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                      error={options.notesError}
                    // helperText={errors.description ? errors.description?.message : null}
                    />

                  </Grid>
                </FormControl> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                my={3}
                component="form"
              >
                <Grid item xs={7} justifyContent="space-around" display="flex">
                  <Button
                    disabled={false}
                    color="secondary"
                    onClick={handleCloseEdit}
                  >
                    Cancel
                  </Button>
                  <Button disabled={false} onClick={handleEdit}>
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </Box>

      }
      {/* delete Modal */}
      <Dialog open={show} onClose={handleShowClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Form</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this Risk Assessment Form </h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={deleteAssessment} variant="contained" color="error">Delete</Button>
          <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>

  );
}


