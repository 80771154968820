import { Box, Button, Paper, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'

import React from 'react'

export const tableHeader = {
  fontSize: '0.9rem !important',
  fontWeight: 500,
  color: '#45445F',
  px: 3,
  paddingLeft: '5px',
  paddingRight: '5px',
  padding: '12px'
}

const SubmitQuotation = () => {
  return (
    <div style={{ fontWeight: "bolder" }}>
      <div></div>
      <div>
        <div >
          <h1 style={{ fontSize: "20px" }}>MATERIAL</h1>
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Material Type
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      MaterialName
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Description
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Units
                    </TableCell>
                    {/* <TableCell align="left" component="th" sx={tableHeader}>
                      WO Status
                    </TableCell> */}
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Price
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      GST
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Price Incl.GST
                    </TableCell>
                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                      View
                    </TableCell> */}
                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                        Work Order Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Work Permit Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Service Record
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Check In time
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Check out time
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Incorrect location
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Time on site
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Force Check out
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>  
                                        Defect type identified
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Required
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Action
                                    </TableCell> */}

                  </TableRow>
                </TableHead>
                {/* <TableBody>


                                {tasks.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.updatedAt?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.siteId?.name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {`${task?.user?.firstname} ${task?.user?.lastname}`}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.company}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.email}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.phone}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkIn?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkOut?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.purpose}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            No
                                        </TableCell>
                                        
                                        <TableCell align="left" sx={tableData}>
                                            Non Critical
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            Pending
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell>

                                    </TableRow>

                                ))}

                                {
                                    tasks.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody> */}
                {/* <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={tasks.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter> */}
              </Table>
            </TableContainer>
          </Box>
        </div>

        <div >
        <h1 style={{ fontSize: "20px" }}>LABOUR</h1>
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Labour Type
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Labour Name
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Rate
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Units
                    </TableCell>
                    {/* <TableCell align="left" component="th" sx={tableHeader}>
                      WO Status
                    </TableCell> */}
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Price
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      GST
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Price Incl.GST
                    </TableCell>
                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                      View
                    </TableCell> */}
                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                        Work Order Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Work Permit Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Service Record
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Check In time
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Check out time
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Incorrect location
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Time on site
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Force Check out
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>  
                                        Defect type identified
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Required
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Action
                                    </TableCell> */}

                  </TableRow>
                </TableHead>
                {/* <TableBody>


                                {tasks.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.updatedAt?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.siteId?.name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {`${task?.user?.firstname} ${task?.user?.lastname}`}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.company}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.email}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.phone}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkIn?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkOut?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.purpose}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            No
                                        </TableCell>
                                        
                                        <TableCell align="left" sx={tableData}>
                                            Non Critical
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            Pending
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell>

                                    </TableRow>

                                ))}

                                {
                                    tasks.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody> */}
                {/* <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={tasks.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter> */}
              </Table>
            </TableContainer>
          </Box>
        </div>

        <div >
        <h1 style={{ fontSize: "20px" }}>PASS THOROUGH / THIRD PARTY COSTS</h1>
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Subcontractor Name
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Material/Labour
                    </TableCell>
                    {/* <TableCell align="left" component="th" sx={tableHeader}>
                      Description
                    </TableCell> */}
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Units
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      WO Status
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Price
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      GST
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Price Incl.GST
                    </TableCell>
                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                      View
                    </TableCell> */}
                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                        Work Order Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Work Permit Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Service Record
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Check In time
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Check out time
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Incorrect location
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Time on site
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Force Check out
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>  
                                        Defect type identified
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Required
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Quote Action
                                    </TableCell> */}

                  </TableRow>
                </TableHead>
                {/* <TableBody>


                                {tasks.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.updatedAt?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.siteId?.name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {`${task?.user?.firstname} ${task?.user?.lastname}`}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.company}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.email}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.phone}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkIn?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkOut?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.purpose}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            No
                                        </TableCell>
                                        
                                        <TableCell align="left" sx={tableData}>
                                            Non Critical
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            Pending
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell>

                                    </TableRow>

                                ))}

                                {
                                    tasks.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody> */}
                {/* <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={tasks.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter> */}
              </Table>
            </TableContainer>
          </Box>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
          <div style={{ width: "50%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button variant='contained'>Attachments</Button>
            <TextField variant='outlined' label="Notes" />
          </div>
          <div style={{ width: "50%" }}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>Sub Total ex GST</TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div>
        <Button variant='contained' style={{ float: "right" }}>Submit For Approval</Button>
      </div>
    </div>
  )
}

export default SubmitQuotation