import React, { useEffect, useState } from 'react'
import contractProfile from "./ContractProfile.json"
import SiteDetailCard from '../../components/sites/DetailCard'
import { Box, Button, Modal, Paper, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import "./ContractProfile.css"
import { checkAuthority } from '../../utils';

export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}



const ContractProfile = () => {

    const segments = window.location?.pathname;
    console.log(segments, "----------maintainsegment");

    const [contractDetail, setContractDetail] = useState([])

    useEffect(() => {
        getContractDetails()
    }, [segments])

    const getContractDetails = () => {
        var arr = []
        let url = segments?.split("/")[1] === 'company' ? '/company' : ''

        if (checkAuthority('CONTRACT')) {
            arr.push({
                id: 1,
                title: "Contract",
                url: `${url}/maintainance-report/contractors/contract`
            })
        }

        if (checkAuthority('ADMIN_CONTRACT')) {
            arr.push({
                id: 2,
                title: "Contract Admin",
                url: `${url}/maintainance-report/contractors/admin-contract`
            })
        }
        
        setContractDetail(arr)
    }

    return (
        <div>
            <div>
                <h1>ContractProfile</h1>
            </div>
            <div className='datacard'>
                {
                    contractDetail?.map((profile) => {
                        return <SiteDetailCard title={profile?.title} url={profile?.url}/>
                    })
                }
            </div>

            


        </div>
    )
}

export default ContractProfile