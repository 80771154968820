import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  FormControl,
  Grid,
  Paper,
  List,
  Select,
  Typography,
  ListItem,
  ListItemText,
  FormLabel,
  TextField,
  Button,
} from "@mui/material";
// import Select from 'react-select'
import { makeStyles } from "@mui/styles";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PageTitle from "../../common/PageTitle";
import LocalDateSelector from "../../common/LocalDateSelector";
import InputLabel from '@mui/material/InputLabel';
import { patchAPI, getAPI, postAPI } from "../../network";
import Loader from "../../common/Loader";
import { checkAuthority, formatDatePost, fullName, timeFormat, getSetTime, durationCalc, validation, calculateTimeDuration } from "../../utils";
import { duration } from "../../utils/data"
import BasicSelector from "../../common/Selector";
import { TextareaAutosize } from "@material-ui/core";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from "@mui/x-date-pickers";
import "./style.css"
import { useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  inputRoot: {
    borderRadius: "10px !important",
    backgroundColor: "white",
    padding: "0 5px",
    fontSize: "12px",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
    "& .MuiOutlinedInput-input": {
      padding: "14px !important",
    },
  },
  buttoRoot: {
    width: "75px !important",
    borderColor: "#707070 !important;",
    color: "#202E43 !important;",
    borderRadius: "8px !important;",
    fontSize: "16px  !important;",
    textTransform: "none !important;",
    padding: "0px 0px !important;",
    marginRight: "15px !important;",
    "&:hover": {
      backgroundColor: " #42505C !important;",
      color: "white !important",
    },
  },
  buttonRootAlt: {
    borderColor: "#707070 !important;",
    backgroundColor: "#42505C !important",
    color: "white !important;",
    borderRadius: "8px !important;",
    fontSize: "16px  !important;",
    textTransform: "none !important;",
    padding: "0px 30px !important;",
    marginRight: "15px !important;",
  },
}));

export default function CasualShiftsPage() {
  const [selectedStatus, setSelectedStatus] = React.useState({
    shiftStatus: "",
    alarmStatus: "",
  });
  const [client, setClient] = React.useState("");
  const [sites, setSites] = useState([])
  const [allsites, setAllSites] = useState([])
  const [loader, setLoader] = useState(false)
  const [users, setUsers] = useState([])
  const [prevKey, setPrevKey] = useState()
  const [companies, setCompanies] = useState([]);
  const [shiftTypes, setShiftTypes] = useState([])
  const [license, setLicense] = useState()
  const [licenseTypes, setLicenseTypes] = useState([])
  const [action, setAction] = useState('add');
  const [recurrences, setRecurrences] = useState([])
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [shiftId, setShiftId] = useState('')
  const [shiftKey, setShiftKey] = useState('')
  const location = useLocation();
  const navigate = useNavigate();

  if (location != null) {
    if (location?.state?.task) {
      var task = location.state.task
    }
  }

  // console.log("Task+++++++++++++++++++++", task)

  const [shift, setShift] = useState({
    companyNotify: task?.companyIdNotify,
    siteNotify: task?.siteIdNotify,
    userNotify: task?.userToNotify,
    assignedUser: task?.assignedUser?._id,
    company: task?.companyId?._id,
    siteId: task?.siteId?._id,
    shiftName: task?.shiftType?._id,
    shiftType: task?.shiftName,
    licenseType: task?.licenseType?.type,
    licenseName: task?.licenseType?._id,
    shiftRecurrence: task?.recurrenceType,
    shiftRecurrenceDay: task?.recurrenceDay ? task?.recurrenceDay : [],
    duration: task?.duration,
    startDate: task?.startDate ? task?.startDate : null,
    endDate: task?.endDate ? task?.endDate : null,
    startTime: task?.startTime ? getSetTime(task?.startTime) : null,
    endTime: task?.endTime ? getSetTime(task?.endTime) : null,
    key: shiftKey,
    price: task?.price,
    priceIn: task ? task.priceIn : 'AUD',
    parking: task?.parkingRequired,
    break: task?.allowedBreaks,
    reportAt: task?.reportAt?._id,
    woNumber: task?.woNumber,
    roleError: '',
    siteIdError: false,
    siteNotifyError: false,
    assignedUserError: false,
    shiftTypeError: false,
    userNotifyError: false,
    shiftNameError: false,
    licenseTypeError: false,
    licenseNameError: false,
    shiftRecurrenceError: false,
    durationError: false,
    startDateError: false,
    endDateError: false,
    startTimeError: false,
    endTimeError: false,
    keyError: false,
    priceError: false,
    parkingError: false,
    breakError: false,
    reportedAtError: false,
    woNumberError: false,
    companyError: false,
    companyNotifyError: false,
  })
  // console.log(shift, "SHIFT////////&&&&&&&&&&&&&&&&&&&&&&&&")

  const [checked, setChecked] = useState(shift?.shiftRecurrenceDay.length ? shift?.shiftRecurrenceDay : []);
  useEffect(() => {
    getShiftTypes();
    getLicenseTypes();
    companyLists()
    // getAllSite()
    getSite(shift?.company)
    getUser(shift?.siteNotify, shift?.company)
    if (task) {
      setAction('edit')
      setShiftId(task?._id)
      getShiftKeys(task?._id)
    }
    timeSetStart(shift?.startTime)
    timeSetEnd(shift?.endTime)
  }, [location])


  const checkListWeek = [{
    "name": "Mon",
    val: 0
  },
  {
    "name": "Tue",
    val: 1
  },
  {
    "name": "Wed",
    val: 2
  },
  {
    "name": "Thus",
    val: 3
  },
  {
    "name": "Fri",
    val: 4
  },
  {
    "name": "Sat",
    val: 5
  },
  {
    "name": "Sun",
    val: 6
  }];

  const currencies = [
    {
      label: "AUD",
      value: "aud"
    },
    {
      label: "USD",
      value: "usd"
    },
    {
      label: "EUR",
      value: "eur"
    },
    {
      label: "BTC",
      value: "btc"
    },
    {
      label: "JPY",
      value: "jpy"
    },
    {
      label: "Rs",
      value: "rs"
    }
  ];

  const classes = useStyles();




  var isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";

  const handleCheck = (event) => {
    var updatedList = [...checked];
    console.log(checked)
    // if(shift?.shiftRecurrenceDay.length) updatedList=[...shift?.shiftRecurrenceDay] 
    // console.log(event.target.checked, "event.target.checked")
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    console.log(updatedList, checked)
  };



  // const getDuration=()=>{
  //   return durationCalcu("Duration of time-----------------------",shift.startTime,shift.endTime) 

  // }


  // durationCalc(shift.startTime,shift.endTime)



  //   function durationCalcu(timestart, timeEnd) {
  //     var timeStart = timestart.getTime();
  //     var timeEnd = timeEnd.getTime();
  //     var hourDiff = timeEnd - timeStart;  
  //     var secDiff = hourDiff / 1000; 
  //     var minDiff = hourDiff / 60 / 1000;  
  //     var hDiff = hourDiff / 3600 / 1000;  
  //     var hours = Math.floor(hDiff);
  //     var mins = minDiff - 60 * hours;

  //     return {
  //         hours: hours,
  //         mins: mins
  //     }
  // }

  const getShiftTypes = async (type) => {
    setLoader(true)
    if (type === 'Security') {
      let data = await getAPI('/shift-types?type=Security');
      if (data) {
        setShiftTypes(data);
      }
    }
    if (type === 'Other') {
      let data = await getAPI('/shift-types?type=Other');
      if (data) {
        setShiftTypes(data);
      }
    }
    if (!type) {
      let data = await getAPI('/shift-types');
      if (data) {
        setShiftTypes(data);
      }
    }

    setLoader(false)
  }
  const getLicenseTypes = async (license) => {
    setLoader(true)
    if (license) {
      let data = await getAPI(`/getlicense?type=${license}`);
      if (data) {
        setLicenseTypes(data);
      }
    } else {
      let data = await getAPI(`/getlicense`);
      if (data) {
        setLicenseTypes(data);
      }
    }

    setLoader(false)
  }
  const companyLists = async () => {
    let process = await getAPI('/companies');
    if (process) {
      var companies = [];
      for (var i = 0; i < process.length; i++) {
        companies.push({ label: process[i].name, id: process[i].id })
      }
      setCompanies(companies);
    }
  }

  const getSite = async (idd) => {

    if (idd) {
      setLoader(true)
      let userType = localStorage.getItem('userType')
      let data = await getAPI(userType === 'admin' ? `/company-sites/${idd}` : '/company/sites');
      if (data) {
        setSites(data);
      }
      setLoader(false)
    }
  }

  const getAllSite = async (idd) => {
    setLoader()
    let data = await getAPI('/sites')
    if (data) {
      let outputs = data.map((item) => ({
        id: item._id,
        label: item.name,

      }))
      setAllSites(outputs)
    }
    setLoader()
  }
  const getUser = async (idd, cidd) => {
    if (cidd && !idd) {
      setLoader(true)
      let data = await getAPI(`/company/sites/team?companyId=${cidd}`);
      if (data) {
        let emails = data.map((item) => ({
          id: item._id,
          label: fullName(item) + `(${item.email})`,
          value: item.email
        }))
        setUsers(emails)
      }
      setLoader(false)
    }
    if (idd) {
      setLoader(true)
      let data = await getAPI(`/sites-user/${idd}`);
      if (data) {
        let emails = data.map((item) => ({
          id: item._id,
          label: fullName(item) + `(${item.email})`,
          value: item.email
        }))
        setUsers(emails)
      }
      setLoader(false)
    }

    if (!idd && !cidd) {
      setLoader(true);
      let data = await getAPI('/users/app');
      if (data) {
        let emails = data.map((item) => ({
          id: item._id,
          label: fullName(item) + `(${item.email})`,
          value: item.email
        }))
        setUsers(emails)
      }
      setLoader(false);
    }

  }
  const getShiftKeys = async (shiftId2) => {
    setLoader(true)

    let data = await getAPI(`key-shift-duties?shiftId=${shiftId2}`);
    if (data) {
      setShiftKey(data[0]?.key);
      setShift(prevState => ({
        ...prevState,
        key: data[0]?.key
      }))
    }


    setLoader(false)
  }



  const onChange = (event) => {
    if (event.target.name === "client") {
      setClient(event.target.value);
    } else
      setSelectedStatus({
        [event.target.name]: event.target.value,
        ...selectedStatus,
      });
  };

  const clientLists = [
    {
      id: 1,
      name: "John Doe",
    },
    {
      id: 2,
      name: "Alice Bob",
    },
  ];


  const onChangeSite = async (event) => {

    setShift(prevState => ({
      ...prevState,
      siteNotify: event.target.value,
    }))
    getUser(event.target.value, null)

  }

  const onUserChange = (event) => {
    setShift(prevState => ({
      ...prevState,
      userNotify: event.target.value,
    }))

  }
  // const onRoleChange = (event) => {

  //   setShift(prevState => ({
  //     ...prevState,
  //     role: event.target.value,
  //   }))
  // }
  const onCompanyChange = async (event) => {

    setShift(prevState => ({
      ...prevState,
      company: event.target.value,

    }))

    getSite(event.target.value)
    getUser(null, event.target.value)
  }
  const onCompanyChange2 = async (event) => {

    setShift(prevState => ({
      ...prevState,
      companyNotify: event.target.value,

    }))

    getSite(event.target.value)
    getUser(null, event.target.value)
  }



  const onChangeShiftName = (event) => {
    setShift(prevState => ({
      ...prevState,
      shiftName: event.target.value,
    }))
  }
  const onChangeShiftType = (event) => {
    setShift(prevState => ({
      ...prevState,
      shiftType: event.target.value,
    }))
    getShiftTypes(event.target.value)
  }
  const onChangeallsite = (event) => {
    setShift(prevState => ({
      ...prevState,
      siteId: event.target.value,
    }))
  }
  const onChangeLicenseName = (event) => {
    setShift(prevState => ({
      ...prevState,
      licenseName: event.target.value,
    }))
  }
  const onChangeLicense = (event) => {
    setShift(prevState => ({
      ...prevState,
      licenseType: event.target.value
    }))
    // setLicense(event.target.value)
    getLicenseTypes(event.target.value)
  }

  const onChangeRecurrenceType = (event) => {
    setShift(prevState => ({
      ...prevState,
      shiftRecurrence: event.target.value,
    }))
  }

  const onChangeDuration = (event) => {
    setShift(prevState => ({
      ...prevState,
      duration: event.target.value,
    }))
  }

  let checkedval = []
  if (checked.length > 0) {
    for (let i = 0; i < checked.length; i++) {
      checkedval.push(parseInt(checked[i]))
    }
  } else {
    for (let i = 0; i <= 6; i++) {
      checkedval.push(parseInt(i))
    }
  }

  const submitShift = async () => {
    setShift(prevState => ({
      ...prevState,
      siteIdError: false,
      assignedUserError: false,
      shiftTypeError: false,
      shiftRecurrenceError: false,
      durationError: false,
      startDateError: false,
      endDateError: false,
      startTimeError: false,
      licenseTypeError: false,
      endTimeError: false,
      keyError: false,
      priceError: false,
      parkingError: false,
      breakError: false,
      reportedAtError: false,
      woNumberError: false,
      companyError: false,
    }))

    if (validation('empty', 'Reported Site', shift.siteId)) {
      setShift(prevState => ({
        ...prevState,
        siteIdError: true
      }))
      return;
    }
    // if (validation('empty', 'User', shift.assignedUser)) {
    //   setShift(prevState => ({
    //     ...prevState,
    //     assignedUserError: true
    //   }))
    //   return;
    // }
    if (validation('empty', 'Company', shift.company)) {
      setShift(prevState => ({
        ...prevState,
        companyError: true
      }))
      return;
    }
    if (validation('empty', 'Work Order Number', shift.woNumber)) {
      setShift(prevState => ({
        ...prevState,
        woNumberError: true
      }))
      return;
    }
    else if (validation('empty', 'Allow Break', shift.break)) {
      setShift(prevState => ({
        ...prevState,
        breakError: true
      }))
      return;
    }
    else if (validation('empty', 'Key Shift Duties', shift.key)) {
      setShift(prevState => ({
        ...prevState,
        keyError: true
      }))
      return;
    }
    else if (validation('empty', 'Rate', shift.price)) {
      setShift(prevState => ({
        ...prevState,
        priceError: true
      }))
      return;
    }
    else if (validation('empty', 'Parking', shift.parking)) {
      setShift(prevState => ({
        ...prevState,
        parkingError: true
      }))
      return;
    }
    else if (validation('empty', 'License Type', shift.licenseType)) {
      setShift(prevState => ({
        ...prevState,
        licenseTypeError: true
      }))
      return;
    }
    else if (validation('empty', 'Shift Type', shift.shiftType)) {
      setShift(prevState => ({
        ...prevState,
        shiftTypeError: true
      }))
      return;
    }
    else if (validation('time', 'Start Time', startTime)) {
      setShift(prevState => ({
        ...prevState,
        startTimeError: true
      }))
      return;
    }
    else if (validation('time', 'End Time', endTime)) {
      setShift(prevState => ({
        ...prevState,
        endTimeError: true
      }))
      return;
    }
    // else if (validation('empty', 'Duration', shift.duration)) {
    //   setShift(prevState => ({
    //     ...prevState,
    //     durationError: true
    //   }))
    //   return;
    // }
    else if (validation('empty', 'Shift Recurrence', checked)) {
      setShift(prevState => ({
        ...prevState,
        shiftRecurrenceError: true
      }))
      return;
    }
    else if (validation('date', 'Start Date', shift.startDate)) {
      setShift(prevState => ({
        ...prevState,
        startDateError: true
      }))
      return;
    }
    else if (validation('date', 'End Date', shift.endDate)) {
      setShift(prevState => ({
        ...prevState,
        endDateError: true
      }))
      return;
    }
    if (action === 'add') {

      let payload = {
        // role:shift.role,
        companyIdNotify: shift.companyNotify,
        siteIdNotify: shift.siteNotify,
        userToNotify: shift.userNotify,
        assignedUser: shift.assignedUser,
        companyId: shift.company,
        siteId: shift.siteId,
        woNumber: parseInt(shift.woNumber),
        parkingRequired: shift.parking,
        shiftName: shift.shiftType,
        shiftType: shift.shiftName,
        licenseType: shift.licenseName,
        licenseName: shift.licenseName,
        shiftRecurrence: "Weekly",
        priceIn: shift.priceIn,
        duration: calculateTimeDuration(timeFormat(startTime), timeFormat(endTime)),
        startDate: formatDatePost(shift.startDate),
        endDate: formatDatePost(shift.endDate),
        startTime: timeFormat(startTime),
        endTime: timeFormat(endTime),
        key: shift.key,
        price: parseInt(shift.price),
        reportAt: shift.reportAt ? shift.reportAt : shift.siteId,
        allowedBreaks: shift.break !== '' ? parseInt(shift.break) : '',
        recurrenceDay: checked

      };

      setLoader(true)
      let data = await postAPI('/company/shifts/', payload);

      if (data) {
        navigate(`/scheduler/view-advertised-shifts`)
        clearAll()
      }
      setLoader(false)
    } else if (action == 'edit') {
      let payload = {
        // role:shift.role,
        companyIdNotify: shift.companyNotify,
        siteIdNotify: shift.siteNotify,
        userToNotify: shift.userNotify,
        assignedUser: shift.assignedUser,
        companyId: shift.company,
        siteId: shift.siteId,
        woNumber: parseInt(shift.woNumber),
        shiftName: shift.shiftType,
        shiftType: shift.shiftName,
        licenseType: shift.licenseName,
        licenseName: shift.licenseName,
        shiftRecurrence: "Weekly",
        priceIn: shift.priceIn,
        duration: calculateTimeDuration(timeFormat(startTime), timeFormat(endTime)),
        startDate: formatDatePost(shift.startDate),
        endDate: formatDatePost(shift.endDate),
        startTime: timeFormat(startTime),
        endTime: timeFormat(endTime),
        key: shift.key,
        price: parseInt(shift.price),
        reportAt: shift.reportAt ? shift.reportAt : shift.siteId,
        allowedBreaks: shift.break !== '' ? parseInt(shift.break) : '',
        recurrenceDay: checked

      };

      setLoader(true)
      let data = await patchAPI(`/company/shifts/${shiftId}`, payload);

      if (data) {
        navigate(`/scheduler/view-advertised-shifts`)
        clearAll()

      }
      setLoader(false)
    }
  }
  const clearAll = () => {
    setShift({
      siteId: '',
      assignedUser: '',
      shiftType: '',
      licenseType: '',
      shiftRecurrence: null,
      shiftRecurrenceDay: [],
      checkedval: [],
      duration: '',
      priceIn: '',
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      key: '',
      price: '',
      reportedAt: '',
      woNumber: '',
      break: '',
      siteIdError: false,
      assignedUserError: false,
      shiftTypeError: false,
      licenseTypeError: false,
      shiftRecurrence: false,
      durationError: false,
      startDateError: false,
      endDateError: false,
      startTimeError: false,
      endTimeError: false,
      keyError: false,
      priceError: false,
      woNumberError: false,
      breakError: false,
      companyError: false,
    })
  }
  const timeSetStart = (time) => {
    setStartTime(time)
  }
  const timeSetEnd = (time) => {
    setEndTime(time)
  }
  return (
    <Box sx={{ minHeight: "inherit" }}>
      <Loader loader={loader} />
      <PageTitle title="Scheduler" subTitle="Casual Shifts" />
      {
        checkAuthority('CREATE_CASUAL_SHIFT') &&
        <Box ml={2}>
          <Box>
            {/* <FormControl sx={{ my: 1, minWidth: "20%",marginLeft:"3rem" }}>
              <InputLabel  id="shiftType-label">Select Role</InputLabel>
              <Select
                onChange={onUserChange}
                displayEmpty
                labelId="client-label"
                id="client"
                label="role"
                error={shift.roleError}
                value={shift.role}
                // inputProps={{ "aria-label": "Without label" }}
                sx={{
                  borderRadius: "10px",
                  borderColor: "#707070",
                  pl: 2,
                }}
              >
               
                {roles.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            {/* <FormControl sx={{ my: 1, minWidth: "20%",marginLeft:"3rem" }}>
              <InputLabel id="role-label">Select Role</InputLabel>
              <Select
                error={shift.roleError}
                value={shift.role}
                onChange={onRoleChange}
                displayEmpty
                labelId="role-label"
                id="role"
                label="role"
                
                sx={{
                  borderRadius: "10px",
                  borderColor: "#707070",
                  pl: 2,
                }}
              >
               

               {roles.map((item, index) => (
                  <MenuItem className="select_item" value={item.id} key={index}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <FormControl sx={{ my: 1, minWidth: "20%", marginLeft: "0rem" }}>
              {/* <InputLabel id="company-label">Select Company</InputLabel> */}
              <TextField
                select
                value={shift.companyNotify}
                onChange={onCompanyChange2}
                displayEmpty
                labelId="company-label"
                id="company"
                label="Select Company"
                error={shift.companyNotifyError}
                InputProps={{
                  classes: {
                    root: classes.inputRoot,
                  },
                }}
              >

                {
                  companies.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      <div className="select_item" value={item.id}>{item?.label}</div>
                    </MenuItem>
                  ))
                }
              </TextField>
            </FormControl>

            <FormControl sx={{ my: 1, minWidth: "20%", marginLeft: "3rem" }}>

              <TextField
                select
                value={shift.siteNotify}
                onChange={onChangeSite}
                labelId="site-label"
                id="site"
                label="Site"
                error={shift.siteNotifyError}
                InputProps={{
                  classes: {
                    root: classes.inputRoot,
                  },
                }}
              >
                {
                  sites.map((item, index) => (
                    <MenuItem value={item._id} key={index}>
                      <div key={item._id}>{item.name}</div>
                    </MenuItem>
                  ))
                }

              </TextField>
            </FormControl>

            <FormControl sx={{ my: 1, minWidth: "20%", marginLeft: "3rem" }}>

              <TextField
                select
                value={shift?.userNotify}
                onChange={onUserChange}
                displayEmpty
                label="User"
                error={shift.userNotifyError}
                InputProps={{
                  classes: {
                    root: classes.inputRoot,
                  },
                }}
              >


                {
                  users.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      <div className="select_item" value={item.id}>{item.label}</div>
                    </MenuItem>
                  ))
                }
              </TextField>
              {/* <span style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 5 }}>Search Email</span>
              <Select
                value={shift?.assignedUser?._id}
                onChange={(data) => setShift(prevState => ({
                  ...prevState,
                  assignedUser: data,
                }))}
                options={users}

              /> */}
            </FormControl>


            {/* <FormControl sx={{ my: 1, minWidth: "20%" ,marginLeft:"3rem"}}>
              <InputLabel id="company-label">Select Company</InputLabel>
              <Select
                value={shift.company}
                onChange={onCompanyChange}
                displayEmpty
                labelId="company-label"
                id="company"
                label="company"
                error={shift.companyError}
                sx={{
                  borderRadius: "10px",
                  borderColor: "#707070",
                  pl: 2,
                }}
              >
               

                {
                  companies.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      <div className="select_item" value={item.value}>{item?.label}</div>
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl> */}
            {/* <FormControl sx={{ my: 1, minWidth: "20%" }}>
              <InputLabel id="shiftType-label">Select Site</InputLabel>
              <Select
                value={shift.site}
                onChange={onUserChange}
                displayEmpty
                labelId="shiftType-label"
                id="shiftType"
                label="Site"
                error={shift.siteError}
                sx={{
                  borderRadius: "10px",
                  borderColor: "#707070",
                  pl: 2,
                }}
              >

                {
                  users.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      <div className="select_item" value={item.id}>{fullName(item)}</div>
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl> */}

            <Box my={5} />
            <Grid container m={0} py={5}>
              <Grid item xs={11}>
                <Paper elevation={3}>
                  <Grid container p={4} spacing={2}>
                    <Grid item md={10} lg={8} className="box">
                      <Paper elevation={3} p={3}>
                        <Box p={2}>
                          <Typography
                            sx={{ mt: 1, mb: 2 }}
                            variant="h6"
                            component="div"
                          >
                            Key Shift Duties
                          </Typography>
                          <TextareaAutosize
                            maxRows={100}
                            aria-label="Key Shift Duties"
                            placeholder="Key Shift Duties"
                            minRows={15}
                            defaultValue={shift.key}
                            onChange={(event) => {
                              setShift(prevState => ({
                                ...prevState,
                                key: event.target.value
                              }))
                            }}
                            style={{ width: '100%', fontSize: 16, padding: 10, fontFamily: "Poppins,Helvetica,Arial" }}
                            error={shift.keyError}
                            fullWidth
                          />
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item md={10} lg={4} className="align">
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              Company :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <FormControl sx={{ my: 1, minWidth: "100%" }}>
                              {shift.company ? <InputLabel id="shiftType-label">Company</InputLabel> : null}
                              <Select
                                value={shift.company}
                                onChange={onCompanyChange}
                                displayEmpty
                                labelId="shiftType-label"
                                id="site"
                                label="Site"
                                error={shift.siteIdError}
                                sx={{
                                  borderRadius: "10px",
                                  borderColor: "#707070",
                                  pl: 2,
                                  width: "16rem"
                                }}
                              >
                                <MenuItem disabled> Select Company </MenuItem>
                                {
                                  companies.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>
                                      <div value={item.id}>{item?.label}</div>
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                                width: "16rem"
                              }}
                              component="h4"
                            >
                              Site :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <FormControl sx={{ my: 1, minWidth: "100%" }}>
                              {shift.company ? <InputLabel id="shiftType-label">Site</InputLabel> : null}
                              <Select
                                value={shift.siteId}
                                onChange={onChangeallsite}
                                displayEmpty
                                labelId="shiftType-label"
                                id="site"
                                label="Site"
                                error={shift.siteIdError}
                                sx={{
                                  borderRadius: "10px",
                                  borderColor: "#707070",
                                  
                                  pl: 2,
                                  width: "16rem"
                                }}
                              >
                                {shift.company ? null : <MenuItem disabled>  Select Company First</MenuItem>}
                                {
                                  sites.map((item, index) => (
                                    <MenuItem value={item._id} key={index}>
                                      <div value={item._id}>{item?.name}</div>
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormControl>

                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              WO. Number :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <TextField
                              type="text"
                              style={{  width: "16rem"}}
                              fullWidth
                              error={shift.woNumberError}
                              value={shift.woNumber}
                              onChange={(event) => {
                                setShift(prevState => ({
                                  ...prevState,
                                  woNumber: event.target.value
                                }))
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.inputRoot,
                                },
                              }}
                              placeholder="Wo Number"
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl sx={{ display: "flex", flexDirection: "row" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              Gross Hourly Rate :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={6} py={2}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <TextField
                                style={{ width: '50%', margin: "1px" }}
                                id="standard-select-currency"
                                select
                                label="Currency"
                                InputLabelProps={{ shrink: true }}
                                margin="normal"
                                value={shift.priceIn}
                                onChange={(event) => {
                                  setShift(prevState => ({
                                    ...prevState,
                                    priceIn: event.target.value
                                  }))
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                  },
                                }}
                              >
                                {currencies.map(option => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <TextField
                                style={{ width: "50%", marginLeft: '3px' }}
                                type="number"
                                label="Rate"
                                error={shift.priceError}
                                value={shift.price}
                                onChange={(event) => {
                                  setShift(prevState => ({
                                    ...prevState,
                                    price: event.target.value
                                  }))
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                  },
                                }}

                              />
                            </div>
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              Allow Break :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <TextField
                              type="number"
                              style={{  width: "16rem"}}
                              fullWidth
                              error={shift.breakError}
                              value={shift.break}
                              onChange={(event) => {
                                setShift(prevState => ({
                                  ...prevState,
                                  break: event.target.value
                                }))
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.inputRoot,
                                },
                              }}
                              placeholder=" Break in minutes"
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                      {/* <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              Site :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <TextField
                              type="text"
                              fullWidth
                              error={shift.reportedAtError}
                              value={shift.reportAt}
                              onChange={(event) => {
                                setShift(prevState => ({
                                  ...prevState,
                                  reportAt: event.target.value
                                }))
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.inputRoot,
                                },
                              }}
                              placeholder=" Reported At"
                            />
                          </Grid>
                        </Grid>
                      </FormControl> */}
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              Parking :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <FormControl sx={{ my: 1, minWidth: "100%" }}>
                              <InputLabel id="parking-label">Parking</InputLabel>
                              <Select

                                value={shift.parking}
                                onChange={(event) => {
                                  setShift(prevState => ({
                                    ...prevState,
                                    parking: event.target.value
                                  }))
                                }}

                                displayEmpty
                                labelId="parking-label"
                                id="parking"
                                label="Parking"
                                error={shift.parkingError}
                                sx={{
                                  borderRadius: "10px",
                                  borderColor: "#707070",
                                  pl: 2,
                                  width: "16rem"
                                }}
                              >
                                <MenuItem value={"Yes"}>
                                  <div className="select_item">Yes</div>
                                </MenuItem>
                                <MenuItem value={"No"}>
                                  <div className="select_item">No</div>
                                </MenuItem>
                                <MenuItem value={"NA"}>
                                  <div className="select_item">NA</div>
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              License Type :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <FormControl sx={{ my: 1, minWidth: "100%" }}>
                              <InputLabel id="shiftType-label">License Type</InputLabel>
                              <Select
                                value={shift.licenseType}
                                onChange={onChangeLicense}
                                displayEmpty
                                labelId="shiftType-label"
                                id="shiftType"
                                label="License Type"
                                error={shift.licenseTypeError}
                                sx={{
                                  borderRadius: "10px",
                                  borderColor: "#707070",
                                  pl: 2,
                                  width: "16rem"
                                }}
                              >

                                <MenuItem value="Security">Security</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              License Name :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <FormControl sx={{ my: 1, minWidth: "100%" }}>
                              <InputLabel id="shiftType-label">License Name</InputLabel>
                              <Select
                                value={shift.licenseName}
                                onChange={onChangeLicenseName}
                                displayEmpty
                                labelId="shiftType-label"
                                id="shiftType"
                                label="License Type"
                                error={shift.licenseNameError}
                                sx={{
                                  borderRadius: "10px",
                                  borderColor: "#707070",
                                  pl: 2,
                                  width: "16rem"
                                }}
                              >

                                {
                                  licenseTypes.map((item, index) => (
                                    <MenuItem value={item._id} key={index}>
                                      <div className="select_item" value={item._id}>{item?.name}</div>
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormControl>
                      {/* <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              Master License Number :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <TextField
                              type="text"
                              fullWidth
                              
                              value={shift.mlicense}
                              InputProps={{
                                classes: {
                                  root: classes.inputRoot,
                                },
                                
                              }}
                              onInput = {(e) =>{
                                e.target.value = e.target.value.toString().slice(0,20)
                              }}
                              placeholder=" Master License"
                            />
                          </Grid>
                        </Grid>
                      </FormControl> */}
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              Shift Type :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <FormControl sx={{ my: 1, minWidth: "100%" }}>
                              <InputLabel id="shiftType-label">Shift Type</InputLabel>
                              <Select
                                value={shift.shiftType}
                                onChange={onChangeShiftType}
                                displayEmpty
                                labelId="shiftType-label"
                                id="shiftType"
                                label="ShiftType"
                                error={shift.shiftTypeError}
                                sx={{
                                  borderRadius: "10px",
                                  borderColor: "#707070",
                                  pl: 2,
                                  width: "16rem"
                                }}
                              >
                                <MenuItem value="Security">Security</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h4"
                            >
                              Shift Name :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} py={2}>
                            <FormControl sx={{ my: 1, minWidth: "100%" }}>
                              <InputLabel id="shiftType-label">Shift Name</InputLabel>
                              <Select
                                value={shift.shiftName}
                                onChange={onChangeShiftName}
                                displayEmpty
                                labelId="shiftType-label"
                                id="shiftType"
                                label="ShiftType"
                                error={shift.shiftTypeError}
                                sx={{
                                  borderRadius: "10px",
                                  borderColor: "#707070",
                                  pl: 2,
                                  width: "16rem"
                                }}
                              >

                                {
                                  shiftTypes.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>
                                      <div className="select_item" value={item.id}>{item.name}</div>
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={10}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h3"
                            >
                              Appointment Recurrence :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} pb={2} style={{display: "flex", width: "100%", gap:"10px"}}>
                            {/* {shift.startTime} */}
                            <FormControl sx={{ my: 1, minWidth: "80%" }}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                  options={false}
                                  value={startTime}
                                  name={"Start Time"}
                                  error={shift.startTimeError}
                                  label="Start Time"
                                  isTimeSelector={true}
                                  onChange={(newValue) => {
                                    timeSetStart(newValue)
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                            </FormControl>
                            {/* {shift.endTime} */}
                            <FormControl sx={{ my: 1, minWidth: "80%" }}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                  options={false}
                                  value={endTime}
                                  name={"End Time"}
                                  error={shift.endTimeError}
                                  label="End Time"
                                  isTimeSelector={true}
                                  onChange={(newValue) => {
                                    timeSetEnd(newValue)
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                            </FormControl>

                            {/* <FormControl sx={{ my: 1, minWidth: "100%" }}>
                              <InputLabel id="duration-label">Duration</InputLabel>
                              <Select
                                value={shift.duration}
                                onChange={onChangeDuration}
                                displayEmpty
                                labelId="duration-label"
                                id="duration"
                                label="Duration"
                                error={shift.durationError}
                                sx={{
                                  borderRadius: "10px",
                                  borderColor: "#707070",
                                  pl: 2,
                                }}
                              >
                                {
                                  duration.map((item, index) => (
                                    <MenuItem value={item.value} key={index}>
                                      <div className="select_item">{item.name}</div>
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl> */}
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl sx={{ display: "flex" }}>
                        <Grid container alignItems="center">
                          <Grid item xs={10}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h3"
                            >
                              Recurrence Patterns :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={{ marginTop: "5rem" }} >

                            <Button variant="outlined" className={'row-btn-alt'}
                            >
                              Weekly
                            </Button>
                            {/* {isShow && */}
                            <Typography>
                              <h4>Please Select Recurrence Day</h4>
                              <Grid className="mt-2 mb-2">
                                <div className="h-10" >
                                  {checkListWeek.map((item, index) => (
                                    shift.shiftRecurrenceDay.length ?
                                      <div key={index} style={{ display: "inline" }}>
                                        <input sx={{ fontSize: "12px" }} value={item.val} type="checkbox" onChange={handleCheck}
                                          defaultChecked={shift?.shiftRecurrenceDay.includes(index)}
                                        />
                                        <span className={isChecked(item)} key={item} value={item.val}>{item.name}</span>
                                      </div> :
                                      <div key={index} style={{ display: "inline" }}>
                                        <input sx={{ fontSize: "12px" }} value={item.val} type="checkbox" onChange={handleCheck} />
                                        <span className={isChecked(item)} key={item} value={item.val}>{item.name}</span>
                                      </div>
                                  ))}
                                </div>
                              </Grid>
                            </Typography>

                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl sx={{ display: "flex", marginTop: '5rem' }}>
                        <Grid container alignItems="center">
                          <Grid item xs={10}>
                            <FormLabel
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "black",
                              }}
                              component="h3"
                            >
                              Range of Recurrence :
                            </FormLabel>
                          </Grid>
                          <Grid item xs={12} pb={2} style={{display: "flex", width: "100%", gap:"10px"}}>
                            <FormControl sx={{ mb: 2, width: "500px" }}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  label="Start Date"
                                  value={shift.startDate}
                                  error={shift.startDateError ? "error" : null}
                                  minDate={new Date()}
                                  // selected={StartValue}
                                  onChange={(date) => {
                                    setShift(prevState => ({
                                      ...prevState,
                                      startDate: date
                                    }))
                                  }}

                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>

                            </FormControl>



                            <FormControl sx={{ mb: 4, width: "500px" }}>

                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  label="End Date"
                                  defaultValue={null}
                                  value={shift.endDate}
                                  error={shift.endDateError ? 'error' : null}
                                  // selected={StartValue}
                                  onChange={(date) => {
                                    setShift(prevState => ({
                                      ...prevState,
                                      endDate: date
                                    }))
                                  }}

                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>

                            </FormControl>
                            {/* <Grid container>
                            <Grid item xs={4} >
                              <Button
                                variant="outlined"
                                className={classes.buttoRoot}
                                sx={{ mr: "0 !important" }}
                              >
                                End by
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                variant="outlined"
                                className={classes.buttoRoot}
                                sx={{ mr: "0 !important" }}
                              >
                                End after
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                variant="outlined"
                                className={classes.buttoRoot}
                                sx={{
                                  mr: "0 !important",
                                  width:"114px  !important",
                                }}
                              >
                                No end date
                              </Button>
                            </Grid>
                          </Grid> */}
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl
                        sx={{
                          my: 1,
                          minWidth: "100%",
                          display: "flex !important",
                          columnGap: "20px",
                          flexDirection: "row !important",
                          flexWrap: "wrap !important",
                          justifyContent: "center",
                        }}
                      >
                        <Button variant="outlined" className={classes.buttoRoot} onClick={submitShift}>
                          {task ? 'Update' : 'ok'}
                        </Button>

                        <Button variant="outlined" className={classes.buttoRoot} onClick={() => navigate(-1)}>

                          Cancel
                        </Button>
                      </FormControl>

                      {/* <FormControl sx={{ display: "flex" }}>
                      <Grid container alignItems="center">
                        <Grid item xs={5}>
                          <FormLabel
                            style={{
                              fontWeight: "bolder",
                              fontSize: "larger",
                              color: "black",
                            }}
                            component="h4"
                          >
                            Total Shifts :
                          </FormLabel>
                        </Grid>
                        <Grid item xs={3} py={2}>
                          <Paper
                            elevation={3}
                            sx={{ p: 1.4, textAlign: "center" }}
                          >
                            5
                          </Paper>
                        </Grid>
                      </Grid>
                    </FormControl> */}

                      {/* <FormControl sx={{ display: "flex" }}>
                      <Grid container alignItems="center">
                        <Grid item xs={5}>
                          <FormLabel
                            style={{
                              fontWeight: "bolder",
                              fontSize: "larger",
                              color: "black",
                            }}
                            component="h4"
                          >
                            Allow Breaks :
                          </FormLabel>
                        </Grid>
                        <Grid item xs={3} py={2}>
                          <TextField 
                            type="number"
                            value={shift.break}
                            error={shift.breakError}
                            onChange={(event) => {
                              setShift(prevState => ({
                                ...prevState,
                                break: event.target.value
                              }))
                            }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl> */}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* <Grid item xs={6} mt={5} alignSelf="center">
              <Button
                sx={{
                  float: "right",
                  mt: 2,
                  backgroundColor: "#42505C",
                  color: "white",
                  borderRadius: "10px",
                  px: 5,
                  py: 1,
                  "&:hover": {
                    backgroundColor: "#343636",
                  },
                }}
                type="submit"
                variant="contained"
              >
                Advertise Shift
              </Button>
            </Grid> */}
            </Grid>
          </Box>
        </Box>
      }
    </Box>
  );
}
