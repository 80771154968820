import React, { useEffect, useState } from 'react'
import { checkAuthority } from '../../../utils';
import PageTitle from '../../../common/PageTitle';
import SiteDetailCard from '../../../components/sites/DetailCard';
import { Grid } from '@material-ui/core';

const Settings = () => {
    const [fsrList, setFsrList] = useState([])
    const segments = window.location?.pathname;

    useEffect(() => {
        getManagementDetails()
    }, [segments])

    const getManagementDetails = () => {
        var arr = []
        let url = segments?.split("/")[1] === 'company' ? '/company' : ''

        if (checkAuthority('VIEW_ADD_BUILDING_TYPE')) {
            arr.push({
                id: 1,
                title: "Add Building Type",
                url: `${url}/fsr/settings/building-type/add`
            })
        }

        if (checkAuthority('VIEW_ADD_BUILDING_NAME')) {
            arr.push({
                id: 2,
                title: "Add Building Name",
                url: `${url}/fsr/settings/building-name/add`
            })
        }

        if (checkAuthority('VIEW_ADD_BUILDING_FLOOR')) {
            arr.push({
                id: 2,
                title: "Add Building Floor",
                url: `${url}/fsr/settings/building-floor/add`
            })
        }

        if (checkAuthority('VIEW_ADD_BUILDING_SPACE_TYPE')) {
            arr.push({
                id: 2,
                title: "Add Space Type",
                url: `${url}/fsr/settings/building-space/add`
            })
        }

        if (checkAuthority('VIEW_ADD_BUILDING_SPACE_ID')) {
            arr.push({
                id: 2,
                title: "Add Space Id",
                url: `${url}/fsr/settings/building-space-id/add`
            })
        }

        if (checkAuthority('VIEW_ADD_BUILDING_ASSET_ID')) {
            arr.push({
                id: 6,
                title: "Add Asset Id",
                url: `${url}/fsr/settings/building-asset-id/add`
            })
        }

        if (checkAuthority('VIEW_ADD_BUILDING_ASSIGNEE')) {
            arr.push({
                id: 2,
                title: "Add Assignee",
                url: `${url}/fsr/settings/building-assignee/add`
            })
        }
        if (checkAuthority('VIEW_ADD_BUILDING_VENDOR')) {
            arr.push({
                id: 2,
                title: "Add Vendor",
                url: `${url}/fsr/settings/building-vendor/add`
            })
        }

        setFsrList(arr)
    }
    return (
        <Grid>
            <PageTitle title={"Setting List"} />

            <Grid className='datacard' >
                {fsrList?.map((fsr) => {
                    return (
                        <SiteDetailCard title={fsr?.title} url={fsr?.url} />
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default Settings