import axios from "axios";
// import {BASE_URL} from "../constant";

// export const BASE_URL = "http://54.90.21.249:8000/v1";
// export const BASE_URL = "http://13.58.38.66:8000/v1";

// export const BASE_URL = "http://localhost:8000/v1";
export const BASE_URL = "https://secuber.myappsdevelopment.co.in/v1";

// export const BASE_URL = "http://localhost:8000/v1";
// export const BASE_URL = "http://secuber.myappsdevelopment.co.in/v1";

const token = localStorage.getItem("token");
  
export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: !!token ? { Authorization: `Bearer ${token}` } : null,
});
