import React from 'react'
import "./AddTask.css"
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'


const AddTask = () => {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <div className='task-container'>
            <div>
                <h1>Add Visitor Task</h1>
            </div>
            <div className='form-style'>
                <TextField label="Title" variant='outlined' style={{ width: "100%" }} />
                <Box sx={{ width: "100%", display: 'flex', flexDirection: "column", gap: "10px" }}>
                    <FormControl fullWidth style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label" style={{ width: "100%" }}>Work Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                            style={{ width: "100%" }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl >
                    <FormControl fullWidth style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Sites</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Location Id</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <TextField label="WO Number" variant='outlined' style={{ width: "100%" }} />
                <TextField label="Service Category Number 1" variant='outlined' style={{ width: "100%" }} />
                <TextField label="Service Category Number 2" variant='outlined' style={{ width: "100%" }} />
                <TextField label="Service Category Number 3" variant='outlined' style={{ width: "100%" }} />

                <TextField label="Description" variant='outlined' style={{ width: "100%" }} />
                <Button>Add Task</Button>
            </div>
        </div>
    )
}

export default AddTask