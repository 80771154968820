import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'


export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}

const ViewProperty = () => {
    return (
        <div>
            <Box display="flex" sx={{ my: "3rem" }}>
                <TableContainer component={Paper}>

                    <Table
                        sx={{ minWidth: "auto" }}
                        aria-label="custom pagination table"
                        className="responsive-table"
                    >
                        <TableHead>
                            <TableRow className="table-header">
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Property Type
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Property Category
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Property Id
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Property Name
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Address
                                </TableCell>
                                <TableCell align="left" component="th" sx={tableHeader}>
                                    Lat long
                                </TableCell>
                                <TableCell align="center" component="th" sx={tableHeader}>
                                    Contact
                                </TableCell>
                                <TableCell align="center" component="th" sx={tableHeader}>
                                    State
                                </TableCell>
                                <TableCell align="center" component="th" sx={tableHeader}>
                                    Suburb
                                </TableCell>
                                <TableCell align="center" component="th" sx={tableHeader}>
                                    QR Code
                                </TableCell>
                                <TableCell align="center" component="th" sx={tableHeader}>
                                    Status
                                </TableCell>

                                <TableCell align="center" component="th" sx={tableHeader}>
                                    Action
                                </TableCell>
                                {/* <TableCell align="center" component="th" sx={tableHeader}>
                                        Task Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell> */}

                            </TableRow>
                        </TableHead>
                        {/* <TableBody>


                                {tasks.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.updatedAt?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.siteId?.name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {`${task?.user?.firstname} ${task?.user?.lastname}`}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.company}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.email}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.phone}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkIn?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkOut?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.purpose}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            No
                                        </TableCell>
                                        
                                        <TableCell align="left" sx={tableData}>
                                            Non Critical
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            Pending
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell>

                                    </TableRow>

                                ))}

                                {
                                    tasks.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody> */}
                        {/* <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={tasks.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter> */}
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default ViewProperty