import React, { useEffect, useState } from 'react'
// import maintainanceManagement from "./MaintainanceManagement.json"
import SiteDetailCard from '../../../components/sites/DetailCard'
import "./Maintainancemanagement.css"
import { checkAuthority } from '../../../utils'

const MaintainanceManagement = () => {

    const segments = window.location?.pathname;
    console.log(segments, "----------maintainsegment");

    const [maintainanceDetail, setMaintainanceDetail] = useState([])

    useEffect(() => {
        getManagementDetails()
    }, [segments])

    const getManagementDetails = () => {
        var arr = []
        let url = segments?.split("/")[1] === 'company' ? '/company' : ''

        if (checkAuthority('ADD_MAINTAINANCE_TASK')) {
            arr.push({
                id: 1,
                title: "Add Maintainance Task",
                url: `${url}/maintainance-report/management/add-task`
            })
        }

        if (checkAuthority('VIEW_MAINTAINANCE_TASK')) {
            arr.push({
                id: 2,
                title: "View Maintainance Task",
                url: `${url}/maintainance-report/management/view-task`
            })
        }
        if (checkAuthority('VIEW_MAINTAIANANCE_SCHEDULE')) {
            arr.push({
                id: 3,
                title: "View Maintainance Schedule",
                url: `${url}/maintainance-report/management/view-schedule`
            })
        }
        
        setMaintainanceDetail(arr)
    }


  return (
    <div>
            <div>
                <h1>Facilities Settings</h1>
            </div>
            
            <div className='datacard'>
                {
                    maintainanceDetail?.map((it, i) => {
                        return (
                            <SiteDetailCard title={it?.title} url={it?.url} />
                        )
                    })
                }
            </div>
        </div>
  )
}

export default MaintainanceManagement