import React from 'react'
import { Box, Grid } from "@mui/material";
import PageTitle from '../../common/PageTitle';
import SiteDetailCard from '../../components/sites/DetailCard';
const Visitor = () => {
  return (
    <Box sx={{ height: "inherit" }}>
    <PageTitle title="Visitor " />
    <Box display="flex">
      <Grid
        container
        rowSpacing={7}
        columnSpacing={{ xs: 1 }}
        alignContent="center"
        justifyContent="center"
        alignItems="center" 
        mt="1%"
      >
       
          <SiteDetailCard large title={'Visitor Management '} url={'/visitor_management'} />
          <SiteDetailCard large title={'Visitor Task Management '} url={'/taskManagement'} />
       
      </Grid>
    </Box>
  </Box>
  )
}

export default Visitor