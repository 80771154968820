/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Grid,
  FormControl,
  Skeleton,
  Button,
  Alert,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import { Link } from "react-router-dom";


import BasicSelector from "../../common/Selector";
import TableFooter from "@mui/material/TableFooter";
import LocalDateSelector from "../../common/LocalDateSelector";
import PageTitle from "../../common/PageTitle";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader"
import { deleteAPI, getAPI, getExportAPI, getExportPdfAPI } from "../../network";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CompanyContext } from "../../../context";

import EmptyTable from "../../common/EmptyTable";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthority, tableHeader, tableData, formatDate, timeFormat, formatDatePost } from "../../utils";
import "./style.css";
import { FilterList } from "@mui/icons-material";
import { TextField } from "@material-ui/core";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { BASE_URL } from "../../../constant";



export default function TaskList() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [deleteId, setDeleteId] = useState('');
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [tasks, setTasks] = useState([])
  const [sites, setSites] = useState([])
  const [status, setStatus] = useState([])
  const [search, setSearch] = useState()

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([5, 10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  const [filter, setFilter] = useState({
    siteId: '',
    startDate: null,
    endDate: null,
    status: null
  })
  const taskData = []
  for (let j = 0; j < tasks.length; j++) {
    taskData.push(tasks[j].tasks)
  }


  const taskD = []
  for (let i = 0; i < taskData.length; i++) {
    taskD.push(taskData[i][0])
  }

  useEffect(() => {
    getTasks()
    getSites()
  }, []);

  const userType = localStorage.getItem('userType')


  const handleChangeRowsPerPage = (event) => {
    setPerPage(event.target.value)
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getTasks = async (sId = null, sDate = null, eDate = null, status = null, searchF = null) => {
    sId = sId !== null ? sId : filter.siteId;
    eDate = eDate !== null ? eDate : filter.endDate;
    sDate = sDate !== null ? sDate : filter.startDate;
    status = status !==null ? status : filter.status;
    searchF = searchF !==null ? searchF : search;

    var url = ''
    var start = true;
    if (sId !== '' && sId !== null) {
      url += (start ? '?' : '&') + `siteId=${sId}`
      start = false;
    }
    if (companyId) {
      url += (start ? '?' : '&') + `companyId=${companyId}`
      start = false;
    }
    if (eDate !== '' && eDate !== null) {
      url += (start ? '?' : '&') + `endDate=${formatDatePost(eDate)}`
      start = false;
    }
    if (sDate !== '' && sDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(sDate)}`
      start = false;
    }
    if (status) {
      url += (start ? '?' : '&') + `status=${status}`
      start = false;
    }
    if (searchF) {
      url += (start ? '?' : '&') + `search=${searchF}`
      start = false;
    }
    setLoader(true)
    if (companyId) {
      let data = await getAPI(`/tasks` + url)
      if (data) {
        setTasks(data)
      }
      setLoader(false)
    } else {
      let data = await getAPI(`/tasks` + url)
      if (data) {
        setTasks(data)
      }
      setLoader(false)

    }

  }

  const getSites = async () => {
    setLoader(true)
    let data = await getAPI(`/sites?companyId=${companyId}`)
    if (data) {
      var arr = []
      for (var i = 0; i < data.length; i++) {
        var obj = data[i]
        obj['label'] = obj.name
        obj['value'] = obj._id
        arr.push(obj)
      }
      setSites(arr)
    }
    setLoader(false)
  }

  const deleteClick = (id) => {
    setDeleteId(id);
    setOpen(true)
  }

  const handleDelete = async (siteId = null) => {

    setLoader(true);
    let empty = await deleteAPI(`/tasks/${deleteId}`);
    if (empty) {
      setOpen(false);
      getTasks()

    }
    setLoader(false);
  }
  const changeStatus = (e) => {
    setStatus(e.target.value)
    setFilter({
      ...filter,
      status: e.target.value
    })
    getTasks(null, null, null, e.target.value)
  }
  const changeSearch = (search) => {
    const numericValue = search.replace(/[^0-9]/g, '');
    setSearch(numericValue);
    // setSearch(search)
    // if (numericValue) {
      getTasks(null, null, null, null, numericValue)
    // }
  }

  const AssessExp2 = async () => {
    setLoader(true)

    var url = ''
    var start = true;
    if (filter.siteId !== '' && filter.siteId !== null) {
      url += (start ? '?' : '&') + `siteId=${filter.siteId}`
      start = false;
    }
    if (companyId) {
      url += (start ? '?' : '&') + `companyId=${companyId}`
      start = false;
    }
    if (filter.endDate !== '' && filter.endDate !== null) {
      url += (start ? '?' : '&') + `endDate=${formatDatePost(filter.endDate)}`
      start = false;
    }
    if (filter.startDate !== '' && filter.startDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(filter.startDate)}`
      start = false;
    }
    if (filter.status) {
      url += (start ? '?' : '&') + `status=${filter.status}`
      start = false;
    }
    if (search) {
      url += (start ? '?' : '&') + `search=${search}`
      start = false;
    }
    let data = await getExportAPI(`${BASE_URL}/export/file/alltasks`  + url,`Task Lists`)
    setLoader(false)
  }
  return (
    <Box>
      <Loader loader={loader} />
      <PageTitle title="Task List" />
      <Box ml={1}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Grid sx={{ my: "1rem" }} container spacing={2} width={"100%"}>
            {
              checkAuthority('VIEW_TASKS') &&
              <>
                <Grid item xs={6} md={6} lg={3}>

                  <BasicSelector
                    disableAll={true}
                    options={sites}
                    // selectorHight={"53px"}
                    value={filter.siteId}
                    name={"Site"}
                    selectorWidth={"100%"}
                    onChange={(e) => {

                      setFilter({
                        ...filter,
                        siteId: e.target.value
                      })
                      getTasks(e.target.value)
                    }}
                  />
                </Grid>
                {/* <Grid item xs={6} md={6} lg={2}>
                  <LocalTimeSelector 
                    value={filter.time}
                    title="Select Time " 
                    onChange={(time) => {
         
                      setFilter({
                        ...filter,
                        time :time
                      })
                      getTasks(null,time,null)
                    }}
                    
                  />
                </Grid> */}

                <Grid item xs={6} md={6} lg={3}>
                  <FormControl sx={{ background: "white", minWidth: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">Status</InputLabel>
                    <Select
                      sx={{ height: "50px", overflow: "hidden" }}
                      value={status}
                      onChange={(e) => changeStatus(e)}
                      displayEmpty
                      labelId="company-label"
                      id="company"
                      label="Select Status"
                    >
                      <MenuItem className="select_item" value={"opened"}> Opened </MenuItem>
                      <MenuItem className="select_item" value={"closed"}> Closed </MenuItem>
                      <MenuItem className="select_item" value={"deferred"}> Deferred </MenuItem>
                      <MenuItem className="select_item" value={"hold"}> Hold </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <TextField id="outlined-basic" label="Search via Work-Order-Number" variant="outlined" onChange={(e) => changeSearch(e.target.value)} value={search} style={{ marginLeft: "16px", background: "white", marginTop: "16px", width: '18vw', marginRight: "100px" }} InputProps={{ inputMode: 'numeric', }} />

                <Grid item xs={6} md={6} lg={3}>
                  <LocalDateSelector
                    title="Start Date "
                    value={filter.startDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        startDate: date
                      })
                      getTasks(null, date, null)
                    }}
                  />
                </Grid>

                <Grid item xs={6} md={6} lg={3}>
                  <LocalDateSelector
                    title="End Date"
                    value={filter.endDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        endDate: date
                      })
                      getTasks(null, null, date)
                    }}
                  />
                </Grid>
                <Button variant="contained" style={{ backgroundColor: "#75859D" }} sx={{  height: "57px", m: 2, width: '17.5rem' }} onClick={() => AssessExp2()}>
                  <GetAppRoundedIcon /> &nbsp; &nbsp;
                  Export
                </Button>
                {/* {
                  checkAuthority('ADD_TASK') && userType === 'company' ?
                    <Grid item xs={6} md={6} lg={3} > 
                      <Link
                        to="/company/tasks/create"
                        style={{
                          backgroundColor: "grey",
                          color: "white",
                          padding: 12,
                          fontWeight: 600,
                          borderRadius: 5,
                          textDecoration: "none",
                        }}
                      >
                        Create Task
                      </Link>
                    </Grid> :
                    <Grid item xs={6} md={6} lg={3} >
                      <Link
                        to="/tasks/create"
                        style={{
                          backgroundColor: "grey",
                          color: "white",
                          padding: 12,
                          fontWeight: 600,
                          borderRadius: 5,
                          textDecoration: "none",
                        }}
                      >
                        Create Task
                      </Link>
                    </Grid>
                } */}
              </>
            }

          </Grid>
        </FormControl>
        {
          checkAuthority('VIEW_TASKS') &&
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper}>

              <Table
                sx={{ minWidth: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Title
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Site
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Start Date
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      End Date
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Due Time
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Description
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Work order Number
                    </TableCell>

                    <TableCell align="center" component="th" sx={tableHeader}>
                      View Details
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>


                  {taskD.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        {task?.title}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task.siteId.length ?
                          task.siteId?.map((e) => (
                            <div style={{ margin: "3px" }}>{e?.name}</div>
                          ))
                          :
                          <div style={{ margin: "3px" }}>{task?.siteId?.name}</div>}
                      </TableCell>
                      {/* <TableCell align="left"sx={tableData}>
                        {(task?.user?.firstname !== undefined && task?.user?.firstname !== '') ? `${task?.user?.firstname} ${task?.user?.lastname}` : 'NA'}
                      </TableCell> */}
                      <TableCell align="left" sx={tableData}>
                        {new Date(task?.startDate).toLocaleDateString("en-uk", {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        })}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {new Date(task?.endDate).toLocaleDateString("en-uk", {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        })}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.timeDue}
                      </TableCell>
                      <TableCell align="left" sx={{ tableData, width: "40%" }}>
                        {task?.description.slice(0, 120)}...
                      </TableCell>
                      <TableCell align="left" sx={{ tableData, width: "40%" }}>
                        {task?.workOrderNo}
                      </TableCell>
                      <TableCell align="center" sx={{ textAlign: 'center', tableData }}>
                        {
                          checkAuthority('VIEW_TASKS') && userType == 'admin' ?
                            <Button className="btn-div" variant="outlined" color="info" sx={{ mx: 1 }}
                              onClick={(e) => {
                                navigateTo('/tasks/details', { state: task.groupId })
                              }}>
                              View
                            </Button> :
                            <Button className="btn-div" variant="outlined" color="info" sx={{ mx: 1 }}
                              onClick={(e) => {
                                navigateTo('/company/tasks/details', { state: task.groupId })
                              }}>
                              View
                            </Button>
                        }
                      </TableCell>
                      <TableCell>
                        {
                          checkAuthority('EDIT_TASK') &&
                          <Button className="btn-div" variant="outlined" color="info" sx={{ mx: 1 }} onClick={(e) => {
                            navigateTo('/tasks/create', { state: { action: 'edit', task: task } })
                          }}>
                            <EditIcon className="" />
                          </Button>
                        }
                      </TableCell>
                    </TableRow>

                  ))}

                  {
                    tasks.length === 0 &&
                    <EmptyTable colSpan={6} />
                  }
                </TableBody>
                <TableFooter>
                  <TableRow >

                    <TablePagination
                      align="right"
                      rowsPerPageOptions={perPages}
                      colSpan={7}
                      count={taskD.length}
                      rowsPerPage={perPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        }

      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Task</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this task</h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
          <Button onClick={() => setOpen(false)} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}


